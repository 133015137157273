import Dashboard from "./Dashboard";
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import { EditProfile } from "../modals/Profile/EditProfile";
import { useState } from "react";
import { NewPassword } from "../modals/Profile/NewPassword";
import React, { useEffect } from "react";
import {
  getUserProfile,
  changeUserImage,
} from "../redux/Profile/ProfileActions";
import { useDispatch } from "react-redux";
import { FireSwal } from "../helpers/swalNotification";
import Swal from "sweetalert2";

export function ProfileSettings() {
  const [open, handleClose] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserProfile(stopLoader));
  }, []);
  var stopLoader = (data) => {
    setUserProfile(data.user);
  };

  var stopImageUploadLoader = (resp) => {
    if (resp?.status) {
      FireSwal("success", "Profile Image Updated!", "", false, false);
      setTimeout(() => Swal.close(), 2000);
      dispatch(getUserProfile(stopLoader));
      setTimeout(() => {window.location.reload(true);}, 1000);
      
    } else {
      FireSwal("error", "Oops..", resp.message, false, true);
    }
  };
  var onUploadImage = (e) => {
    if (e.target.files[0]) {
      dispatch(changeUserImage(e.target.files[0], stopImageUploadLoader));
    }
  };
  return (
    <>
      <Dashboard>
        <Card sx={{ minHeight: "90vh", mt: 2 }}>
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            onClick={() => handleClose(true)}
          >
            <div
              className="d-flex"
              style={{
                background: "#FF9885",
                alignItems: "center",
                padding: "12px 20px",
                cursor: "pointer",
              }}
            >
              <div>
                <EditIcon sx={{ color: "white", height: "18px" }} />
              </div>
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  sx={{
                    font: "normal normal normal 15px/23px Poppins",
                    letterSpacing: "0px",
                    color: " white",
                    opacity: "1",
                    ml: 1,
                  }}
                >
                  Edit Profile
                </Typography>
              </div>
            </div>
          </Stack>
          <input
            style={{ display: "none" }}
            type="file"
            id="photo"
            onChange={onUploadImage}
          />
          <div style={{ marginTop: "10px" }}>
            <div style={{ textAlign: "center", position: "relative" }}>
              <img
                className="user-profile-img"
                src={userProfile?.profile?.image_location}
              />
            </div>
            <div className="text-center">
              <Link
                to="#"
                className="td-none"
                style={{ color: "#F9B6AA" }}
                onClick={() => document.getElementById("photo").click()}
              >
                Upload Photo
              </Link>
            </div>
            <Container maxWidth="sm" sx={{ mt: 4, display: "block" }}>
              <Grid
                container
                spacing={2}
                sx={{ border: "1px solid #EDEDED", m: 0, p: 0 }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: " normal bold normal 18px/17px Poppins",
                      letterSpacing: "0px",
                      color: " #242934",
                      opacity: "1",
                    }}
                  >
                    Full Name:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: " normal bold normal 18px/17px Poppins",
                      letterSpacing: "0px",
                      color: " #9F9F9F",
                      opacity: "1",
                    }}
                  >
                    {userProfile?.username}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ border: "1px solid #EDEDED", m: 0, p: 0 }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: " normal bold normal 18px/17px Poppins",
                      letterSpacing: "0px",
                      color: " #242934",
                      opacity: "1",
                    }}
                  >
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: " normal bold normal 18px/17px Poppins",
                      letterSpacing: "0px",
                      color: " #9F9F9F",
                      opacity: "1",
                    }}
                  >
                    {userProfile?.email}
                  </Typography>
                </Grid>
              </Grid>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Link
                  to="#"
                  style={{
                    font: "normal normal normal 18px/17px Poppins",
                    color: "#F9B6AA",
                  }}
                  onClick={() => setOpenPassword(true)}
                >
                  Change Password
                </Link>
              </div>
            </Container>
          </div>
        </Card>
        <EditProfile
          open={open}
          handleClose={handleClose}
          callBack={() => dispatch(getUserProfile(stopLoader))}
          selected={userProfile}
          actionType={"edit"}
        />
        <NewPassword
          open={openPassword}
          handleClose={setOpenPassword}
          callBack={() => dispatch(getUserProfile(stopLoader))}
          selected={null}
          actionType={"edit"}
        />
      </Dashboard>
    </>
  );
}
