import { Stack, Button, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
export function TaskList() {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ py: 1, px: 2 }}
      >
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{
            font: "normal normal normal 18px/27px PoppinsSemiBold",
            letterSpacing: "0px",
            color: " #4E3D39",
            opacity: "1",
          }}
        >
          Tasks List
        </Typography>
        <Button className="default-button" sx={{ color: "white" }}>
          <AddIcon /> Add Task
        </Button>
      </Stack>
      <Box sx={{ px: 1 }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
          <Grid
            container
            spacing={1}
            sx={{ alignItems: "center", mt: 1 }}
            key={index}
          >
            <Grid item xs={1}>
              <IconButton>
                <CancelIcon sx={{ color: "#FF9885", height: "15px" }} />
              </IconButton>
            </Grid>
            <Grid item xs={7}>
              Add Profile image with family
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal normal 12px/18px Poppins",
                  letterSpacing: "0px",
                  color: " #4E3D39",
                  opacity: "1",
                }}
              >
                Joined by 3 guests
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
}
