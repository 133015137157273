export const ADMIN_TASK_LIST = "ADMIN_TASK_LIST";
export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const CREATE_NEW_EVENT_TASK = "CREATE_NEW_EVENT_TASK";
export const CREATE_NEW_CATEGORY_TASK = "CREATE_NEW_CATEGORY_TASK";
export const CREATE_NEW_CUSTOM_TASK = "CREATE_NEW_CUSTOM_TASK";
export const USER_TASK_LIST = "USER_TASK_LIST";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const GET_APPROVED_TASK = "GET_APPROVED_TASK";
export const CREATE_NEW_EVENT = "CREATE_NEW_EVENT";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
