import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import routes from "./pages/index";
import "../src/assets/css/style.css";
import ProtectedRoutes from "./ProtectedRoutes";
function App() {
  // const [isLoggedIn, setIsLoggedIn] = useState(
  //   localStorage.getItem("eventRole")
  // );
  // useEffect(() => {
  //   setIsLoggedIn(localStorage.getItem("eventRole"));
  // }, [isLoggedIn]);
  return (
    <Routes>
      {routes.map((data, index) =>
        data.protect ? (
          <Route element={<ProtectedRoutes />} key={index}>
            <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={data.exact}
            path={data.path}
            element={data.component} />
          </Route>
        ) : (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            exact={data.exact}
            path={data.path}
            element={data.component}
            key={index}
          />
        )
        // route.protect ? (
        //   <Route element={<AdminRoutes />} key={nanoid()}>
        //     <Route path={route.path} element={<route.component />} />
        //   </Route>
        // ) : (
        //   <Route
        //     path={route.path}
        //     element={<route.component />}
        //     key={nanoid()}
        //   />
        // )
      )}
    </Routes>
  );
}

export default App;
