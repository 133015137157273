import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { Typography, CircularProgress } from "@mui/material";
import Dashboard from "./Dashboard";
import { DashCard } from "../components/Dash Cards/DashCards";
import { EventDetailCard } from "../components/Event Detail Card/EventDetailCard";
import { EventDetailList } from "../components/Event Detail Card/EventDetailList";
import React from "react";
import { getEvents, getEventsFilters } from "../redux/Tasks/TasksActions";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../redux/Profile/ProfileActions";
import { CurrentRole } from "../helpers/getRole";
export function Overview() {
  const dispatch = useDispatch();
  const [events, setEvents] = React.useState(0);
  const [userProfile, setUserProfile] = React.useState(null);
  const [userCurrentRole, setUserCurrentRole] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = React.useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`)).home
  );
  React.useEffect(() => {
    dispatch(getUserProfile(stopLoaders));
  }, []);
  var stopLoaders = (data) => {
    setUserProfile(data.user);
  };

  var stopLoader = (data) => {
    setEvents(data);
    setLoader(true);
  };
  React.useEffect(() => {
    dispatch(
      getEventsFilters(stopLoader, {
        limit: "10",
        offset: "0",
        search: "",
        type: 5,
      })
    );
  }, []);

  React.useEffect(() => {
    setUserCurrentRole(localStorage.getItem("eventRole"));
    getEvents();
  }, [userCurrentRole]);

  return (
    <Dashboard>
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <Grid container spacing={2} className="align-items-center">
          <Grid item xs={12} sm={3} md={3}>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              className="heading-bold"
            >
              {languageData?.welcome}, {userProfile?.username.split(" ")[0]}
            </Typography>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              className="heading-semi"
            >
              {languageData?.welcome_text} {userCurrentRole}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <DashCard
              bg="transparent linear-gradient(113deg, #ECAB55 0%, #87530F 100%) 0% 0% no-repeat padding-box"
              title={languageData?.happening_now}
              count={events?.happening}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <DashCard
              bg=" transparent linear-gradient(112deg, #8BEF67 0%, #467834 100%) 0% 0% no-repeat padding-box"
              title={languageData?.upcoming}
              count={events?.upcomingEvents}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <DashCard
              bg="transparent linear-gradient(112deg, #EC5555 0%, #762B2B 100%) 0% 0% no-repeat padding-box"
              title={languageData?.cancelled}
              count={events?.canceled}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EventDetailList
              events={events?.event}
              languageData={languageData}
              loader={loader}
            />
          </Grid>
        </Grid>
      </Box>
    </Dashboard>
  );
}
