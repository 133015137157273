import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { Modal } from "@mui/material";
import QRCode from "react-qr-code";
import { useState } from "react";
import { ProfileApi } from "../../redux/Profile/ProfileApi";
const profileApi = new ProfileApi();
export function EventDetailCard({ title, events }) {
  const [open, setOpen] = useState(false);
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };
  const handleOpen = async () => {
    const { data } = await profileApi.eventScanner({ event_id: events._id });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    bgcolor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Box sx={{ mt: 2 }}>
      <div className="d-flex justify-content-between">
        <div className="event-card-head">{title}</div>
        <div>
          <Link to="" className="event-detail-link">
            View details
          </Link>
        </div>
      </div>
      <Card sx={{ mt: 1, p: 2 }}>
        {events?.general_info ? (
          <CardContent>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Event Name
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="event-detail-lower-desc"
                >
                  {events?.general_info?.event_name}
                </Typography>
              </div>
              <div>
                {" "}
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Event Date
                </Typography>
                <div className="event-detail-date" style={{ fontSize: "12px" }}>
                  <span style={{ fontSize: "18px", color: "#4E3D39" }}>25</span>{" "}
                  July 2022 -{" "}
                  <span style={{ fontSize: "18px", color: "#4E3D39" }}>27</span>{" "}
                  July 2022
                </div>
              </div>
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Event Time
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="event-detail-lower-desc"
                >
                  {events?.general_info?.time_from}-
                  {events?.general_info?.time_to}
                </Typography>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Location
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="event-detail-lower-desc"
                >
                  {events?.general_info?.event_location}
                </Typography>
              </div>

              <div>
                {" "}
                <Button
                  variant="contained"
                  className="default-button"
                  sx={{ height: "45px" }}
                  onClick={handleOpen}
                >
                  Create QR Code
                </Button>
              </div>
            </div>
          </CardContent>
        ) : (
          <Typography>No Event Occured</Typography>
        )}
      </Card>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Card elevation={4}>
            <h2 id="child-modal-title">QR CODE GENERATED</h2>
            <p id="child-modal-description">
              <QRCode
                id="qrCodeEl"
                renderAs={"svg"}
                size={250}
                value={events?._id}
              />
            </p>
            <Button
              variant="contained"
              className="default-button2"
              onClick={handleClose}
            >
              Close
            </Button>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}
