import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
import { CircularProgress, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TaskTable } from "../components/Tasks/TaskTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAllHost,
  getAllTaskAdmin,
  getAllTaskUser,
} from "../redux/Tasks/TasksActions";
import { FireSwal } from "../helpers/swalNotification";
import { CurrentRole } from "../helpers/getRole";
import { useLocation, useParams } from "react-router-dom";
import { HostTable } from "../components/Hosts/HostTable";

export function HostList() {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [actionType, setActionType] = useState("add");
  const [createModal, setCreateModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffest] = useState(0);
  const [search, setSearch] = useState("");
  const [type, setDataType] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const check = state?.task?.length > 0 ? true : false;

  var setData = (data) => {
    if (data.status) {
      setTasks(
        check
          ? data.host.filter((val) => state.task.includes(val._id))
          : data.host
      );
      setTotalRecords(data.host.length);
      setLoading(false);
    } else {
      FireSwal("error", "Oops..", data.message, false, false);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    if (CurrentRole() === "admin") {
      dispatch(getAllHost({ limit, offset, search, type }, setData));
    } else {
      dispatch(getAllHost({ limit, offset, search, type }, setData));
    }
  }, [limit, offset, search]);
  var refreshList = () => {
    if (CurrentRole() === "admin") {
      dispatch(getAllHost({ limit, offset, search, type }, setData));
    } else {
      dispatch(getAllHost({ limit, offset, search, type }, setData));
    }
  };
  var setType = (status) => {
    setDataType(status);
    if (CurrentRole() === "admin") {
      dispatch(
        getAllHost(
          { limit: "10", offset: "0", search: "", type: status },
          setData
        )
      );
    } else {
      dispatch(
        getAllHost(
          { limit: "10", offset: "0", search: "", type: status },
          setData
        )
      );
    }
  };
  const buttons = [
    // <Button
    //   key="all"
    //   className={type === 0 ? "active" : ""}
    //   onClick={() => setType(0)}
    // >
    //   All Tasks
    // </Button>,
  ];

  return (
    <Dashboard>
      <Container>
        <Card className="events-card" sx={{ mt: 2 }}>
          <Box sx={{ display: "block", p: 3 }}>
            <div className="d-flex justify-content-between align-items-center">
              <ButtonGroup size="small" className="event-tab-buttons">
                {buttons}
              </ButtonGroup>
            </div>

            {loading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  sx={{ textAlign: "center", color: "#FF9885" }}
                />
              </div>
            ) : (
              <Box sx={{ mt: 2 }}>
                <HostTable
                  tasks={tasks}
                  refreshList={refreshList}
                  createModal={createModal}
                  setCreateModal={setCreateModal}
                  setSelectedItem={setSelected}
                  selectedItem={selected}
                  actionType={actionType}
                  setActionType={setActionType}
                  limit={limit}
                  setLimit={setLimit}
                  offset={offset}
                  setOffest={setOffest}
                  setSearch={setSearch}
                  totalRecords={totalRecords}
                />
              </Box>
            )}
          </Box>
        </Card>
      </Container>
    </Dashboard>
  );
}
