import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import LoadingPage from "./LoadingPage";
// import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
// import fbIcon from "../assets/svg/facebook-icon.svg";
// import googleIcon from "../assets/svg/google-icon.svg";
import { useDispatch } from "react-redux";
import { validateEmail } from "../helpers/emailValidation";
import { signIn } from "../redux/Login/LoginActions";
import { FireSwal } from "../helpers/swalNotification";
import { baseURL } from "../config/endpoint";
import axios from "axios";
import Swal from "sweetalert2";
export function Signin() {
  const [inputValues, setInputValues] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const language = localStorage.getItem("language");
    const enLang = localStorage.getItem("en");
    const deLang = localStorage.getItem("de");

    if (language === null) {
      localStorage.setItem("language", "de");
    }
    if (enLang === null) {
      localStorage.setItem("en", "NA");
    }
    if (deLang === null) {
      localStorage.setItem("de", "NA");
    }
  }, []);
  React.useEffect(async () => {
    const eng = await axios
      .post(`${baseURL}/strapi`, { language: "en" })
      .then((val) => {
        localStorage.setItem("en", JSON.stringify(val.data));
      });
    const ger = await axios
      .post(`${baseURL}/strapi`, { language: "gr" })
      .then((val) => {
        localStorage.setItem("de", JSON.stringify(val.data));
        setLoading(true);
      })
      .catch(() => {
        alert("There is something wrong. Please try again later.");
      });
  }, []);

  var onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      submitForm();
    }
  };
  var handleChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var submitForm = (e) => {
    // e.preventDefault();
    if (
      !inputValues.email ||
      inputValues.email.length === 0 ||
      !validateEmail(inputValues.email)
    ) {
      var _errors = { ...errors };
      _errors.email = "Provide a valid email address";
      setErrors(_errors);
      return;
    }
    if (!inputValues.password || inputValues.password.length < 8) {
      var _errors = { ...errors };
      _errors.password = "Please provide a password minimum 8 character long";
      setErrors(_errors);
      return;
    }
    setLoader(true);
    dispatch(signIn(inputValues, stopLoader));
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      FireSwal("success", "Loggin in...", "", false, false);
      setTimeout(() => {
        Swal.close();
        navigate("/overview", { replace: true });
      }, 2000);
      console.log(resp, "login values");
    } else {
      FireSwal("error", "Oops..", "Invalid credentials", false, true);
    }
    setLoader(false);
  };
  return loading ? (
    <Box sx={{ flexGrow: 1, position: "relative" }}>
      <Grid container spacing={2} className="home">
        <Grid
          item
          xl={6}
          lg={6}
          md={4}
          sm={4}
          display={{ xs: "none", lg: "block", xs: "none", md: "block" }}
          className="signin-img"
        ></Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={8}
          sm={8}
          display={{ xs: "block", lg: "block", sm: "block" }}
          className="signin-form"
        >
          <Container maxWidth="sm" className="form-container">
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              className="form-title"
            >
              JSTPRTY
            </Typography>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              className="welcome-title"
              sx={{
                pt: 2,
              }}
            >
              Welcome to JSTPRTY
            </Typography>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              className="welcome-title-desc"
            >
              Now you can celebrate your event with your family and share
              memories.
            </Typography>
            <Box className="form-fields" sx={{ pt: 2, pl: 8, pr: 8 }}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
                <InputLabel
                  htmlFor="filled-adornment-email"
                  className="signin-email-label"
                >
                  Email
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type="text"
                  className="signin-input"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  name="email"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon>
                        <EmailIcon className="signin-input-icon" />
                      </Icon>
                    </InputAdornment>
                  }
                />
                {errors && errors.email && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.email}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
                <InputLabel
                  htmlFor="filled-adornment-email"
                  className="signin-email-label"
                >
                  Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={!passwordVisible ? "password" : "text"}
                  className="signin-input"
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        className="cursor-password"
                      >
                        {!passwordVisible ? (
                          <VisibilityOff className="signin-input-icon" />
                        ) : (
                          <Visibility />
                        )}
                      </Icon>
                    </InputAdornment>
                  }
                />
                {errors && errors.password && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.password}
                  </Typography>
                )}
              </FormControl>

              <Box sx={{ pt: 2 }}>
                <div className="text-right">
                  <Link to="#" className="forget-link">
                    Forgot Password ?
                  </Link>
                </div>
              </Box>
              <Button
                variant="contained"
                className="button-signin"
                disabled={loader}
                sx={{ mt: 4, ml: 1, p: 2 }}
                // onClick={() => navigate("/overview")}
                onClick={submitForm}
              >
                login
              </Button>
              <Box className="form-bottom-link" sx={{ pt: 8 }}>
                <div>
                  Don't Have Account Yet ?{" "}
                  <span>
                    <Link to="/signup" className="signin-bottom-link">
                      Sign up
                    </Link>
                  </span>
                </div>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
      {/* <Box
        sx={{
          position: "absolute",
          left: "0",
          right: "0",
          marginLeft: "auto",
          marginRight: "auto",
          width: "90px",
          bottom: 0,
        }}
      >
        <IconButton>
          <img src={fbIcon} style={{ height: "60px" }} />
        </IconButton>
        <IconButton>
          <img src={googleIcon} style={{ height: "60px" }} />
        </IconButton>
      </Box> */}
    </Box>
  ) : (
    <LoadingPage />
  );
}
