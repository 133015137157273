import {
  Avatar,
  Button,
  Card,
  Grid,
  Link,
  Skeleton,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { useNavigate, useParams } from "react-router-dom";
import { INSTANCE } from "../../config/axiosInstance";
import { ProfileApi } from "../../redux/Profile/ProfileApi";

const profileApi = new ProfileApi();

export function EventGallery() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [image, setImage] = React.useState([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = React.useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`))
  );
  React.useEffect(() => {
    allImages(id);
  }, []);
  const allImages = async (id) => {
    const { data } = await profileApi.allImages({ event_id: id });
    setImage(data.gal);
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 2 }}
      >
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          className="event-detail-lower-desc"
        >
          {languageData?.eventGallery?.event_gallery}
        </Typography>

        <Box sx={{ pb: 1 }}>
          <Button
            variant="contained"
            className="default-button"
            sx={{ color: "white" }}
            onClick={() => navigate("/gallery/" + id)}
          >
            {languageData?.singleEvent?.view_gallery_btn}
          </Button>
        </Box>
      </Stack>
      <Card className="gallery-card">
        {!image.length ? (
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            className="event-detail-lower-desc"
            sx={{ textAlign: "center" }}
          >
            {languageData?.eventGallery?.no_data}
          </Typography>
        ) : (
          <Grid
            container
            spacing={2}
            maxHeight={"800px"}
            className="pinkscroll"
            sx={{ overflowY: "scroll" }}
          >
            {image.length > 0 &&
              image.map((item, index) => (
                <Grid item>
                  {item.type == "application/mp4" ? (
                    <video
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "9px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/gallery/" + id + "/" + item.isPrivate);
                      }}
                    >
                      {" "}
                      <source src={`${item?.image}`} type="video/mp4" />
                    </video>
                  ) : (
                    <ProgressiveImage src={item?.image} placeholder="">
                      {(src, loading) => {
                        return loading ? (
                          <Skeleton
                            variant="rectangular"
                            width={80}
                            height={80}
                          />
                        ) : (
                          <img
                            src={`${src}`}
                            style={{
                              height: "90px",
                              cursor: "pointer",
                              width: "90px",
                              borderRadius: "9px",
                              objectFit: "cover",
                            }}
                            onClick={() => {
                              navigate("/gallery/" + id + "/" + item.isPrivate);
                            }}
                            alt="sea beach"
                          />
                        );
                      }}
                    </ProgressiveImage>
                  )}{" "}
                </Grid>
              ))}
          </Grid>
        )}
      </Card>
    </>
  );
}
