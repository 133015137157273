import Swal from "sweetalert2";

export function FireSwal(
  type,
  title,
  text,
  showConfirmButton,
  showCancelButton,
  confirmButtonText = "Yes",
  cancelButtonText = "Cancel"
) {
  return Swal.fire({
    icon: type,
    title: title,
    text: text,
    color: "#ff9885",
    showCancelButton,
    showConfirmButton,
    confirmButtonText ,
    cancelButtonText ,
    cancelButtonColor: "#c5c2c2",
    confirmButtonColor: "#ff9885",
  });
}
