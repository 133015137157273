import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { getEvents } from "../../redux/Tasks/TasksActions";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Autocomplete,
  FormControl,
  Modal,
  OutlinedInput,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";
import Table from "@mui/material/Table";
import Swal from "sweetalert2";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import imgPlaceholder from "../../assets/images/imagePlaceholder.png";

import moment from "moment";
import { ProfileApi } from "../../redux/Profile/ProfileApi";
import { FireSwal } from "../../helpers/swalNotification";
import axios from "axios";
import { baseURL } from "../../config/endpoint";
import { useEffect } from "react";

const profileApi = new ProfileApi();
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// console.log(roles, "eventRole");

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export function EventTable({ events, setEvents }) {
  const [skip, setSkip] = useState();
  const cancelEvent = async (id) => {
    FireSwal(
      "Warning",
      "Warning",

      languageData?.eventTableScreen?.delete_text,

      true,
      true,
      languageData?.eventTableScreen?.delete_yes,
      languageData?.eventTableScreen?.delete_cancel
    ).then(async (val) => {
      if (val.isConfirmed) {
        const { data } = await profileApi.cancelEvent({ event_id: id });
        setEvents(events.filter((event) => event._id !== id));
        FireSwal("success", "", "Event deleted successfully", false, false);
        setTimeout(() => Swal.close(), 1000);
      }
    });
  };

  const selectedLanguage = localStorage.getItem("language");
  const languageData = JSON.parse(localStorage.getItem(`${selectedLanguage}`));
  const [errors, setErrors] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([{}, {}, {}, {}, {}, {}, {}]);
  const [roles, setRole] = useState(localStorage.getItem("eventRole"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!opens) dispatch(getEvents(stopLoader));
  }, [opens]);
  var stopLoader = (data) => {
    setEvents(data.events);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    const headCells = [
      {
        id: "lisence_no",
        numeric: false,
        disablePadding: false,
        label: languageData?.eventTableScreen?.license_number,
      },
      {
        id: "event_name",
        numeric: true,
        disablePadding: false,
        label: languageData?.eventTableScreen?.name,
      },

      {
        id: "date_time",
        numeric: true,
        disablePadding: false,
        label: languageData?.eventTableScreen?.date,
      },
      {
        id: "location",
        numeric: true,
        disablePadding: false,
        label: languageData?.eventTableScreen?.location,
      },
      {
        id: "task_assigned",
        numeric: true,
        disablePadding: false,
        label: languageData?.eventTableScreen?.tasks,
      },

      {
        id: "actions",
        numeric: true,
        disablePadding: false,
        label: languageData?.eventTableScreen?.actions,
      },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const eventpackage = () => {
    axios
      .post(`${baseURL}/events/editPackageEvent`, {
        eventData,
      })
      .then((val) => {
        FireSwal(
          "success",
          "Updated",

          "Event Package Updated Successfully",

          false,
          true
        );
        setOpens(false);
        setTimeout(() => Swal.close(), 1000);
      });
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    bgcolor: "white",
    boxShadow: 24,
    overflowY: "auto",
    height: "80vh",
    border: "none",
    p: 4,
    mr: 2,
    position: "relative",
  };
  const handleCloses = () => {
    setOpens(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [eventData, setEventData] = React.useState();
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const [createModal, setCreateModal] = React.useState(false);
  const [image, setImage] = React.useState();
  const editEvent = () => {
    axios
      .post(`${baseURL}/api/v1/events/edit`, {
        event_id: eventData._id,
        general_info: eventData.general_info,
      })
      .then((val) => {
        FireSwal(
          "Success",
          "Updated",

          "Event Updated Successfully",

          true
        );
        navigate(-1);
      });
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, overflowX: "auto" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            className="event-table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
              {events?.map((row, index) => {
                return (
                  <TableRow hover key={row.name}>
                    <TableCell align="left">{row.license_number}</TableCell>
                    <TableCell align="left">
                      {row.general_info.event_name}
                    </TableCell>

                    <TableCell align="left">
                      <div>
                        {moment(row.general_info.date_from).format("LL")}
                        {/* {moment(row.general_info.date_to).format("DD-MM-YY")} */}
                      </div>
                      {/* <div>
                        {row.general_info.time_from}-{row.general_info.time_to}
                      </div> */}
                    </TableCell>
                    <TableCell align="left">
                      {row.general_info.event_location}
                    </TableCell>
                    <TableCell align="left">{row.tasks.length}</TableCell>

                    <TableCell align="left">
                      <div className="d-flex justify-content-between action-buttons">
                        <button
                          className="view"
                          onClick={() => navigate("/event-details/" + row._id)}
                        >
                          <VisibilityIcon
                            sx={{ color: "white", height: "18px" }}
                          />
                        </button>

                        <button
                          className="edit"
                          onClick={() => navigate("/edit-event/" + row._id)}
                        >
                          <EditIcon sx={{ color: "white", height: "18px" }} />
                        </button>
                        <button className="delete">
                          <DeleteIcon
                            sx={{ color: "white", height: "18px" }}
                            onClick={() => cancelEvent(row._id)}
                          />
                        </button>
                        {roles === "admin" && (
                          <button className="filter">
                            <FilterAltIcon
                              sx={{ color: "white", height: "18px" }}
                              onClick={() => {
                                setOpens(!opens);
                                setEventData(row);
                              }}
                            />
                          </button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              sx={{ position: "absolute", right: 20 }}
              onClick={() => handleClose(!open)}
            >
              <CloseIcon sx={{ color: "#black", height: "20px" }} />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ color: "#752525" }}
            >
              Edit Event
            </Typography>

            <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
              <FilledInput
                id="filled-adornment-password"
                type="text"
                value={eventData?.general_info?.event_name}
                className="signin-input event-detail-top-head"
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      event_name: e.target.value,
                    },
                  });
                }}
                sx={{ marginBottom: "20px" }}
              />

              <FilledInput
                id="filled-adornment-a"
                type="text"
                value={eventData?.general_info?.description}
                sx={{ marginBottom: "20px" }}
                className="signin-input"
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      description: e.target.value,
                    },
                  });
                }}
                name="a"
              />

              <FilledInput
                value={eventData?.general_info?.event_location}
                id="filled-adornment-a"
                type="text"
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      event_location: e.target.value,
                    },
                  });
                }}
                sx={{ marginBottom: "20px" }}
                className="signin-input"
                name="a"
              />
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="event-detail-top-head"
              >
                Date From
              </Typography>
              <FilledInput
                className="signin-input"
                sx={{ marginBottom: "20px" }}
                type="date"
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      date_from: e.target.value,
                    },
                  });
                }}
                name="date_from"
                value={
                  eventData?.general_info?.date_from &&
                  moment(eventData?.general_info?.date_from).format(
                    "YYYY-MM-DD"
                  )
                }
              />
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="event-detail-top-head"
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      event_location: e.target.value,
                    },
                  });
                }}
              >
                Date To
              </Typography>
              <FilledInput
                type="date"
                className="signin-input"
                name="date_from"
                sx={{ marginBottom: "20px" }}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      date_to: e.target.value,
                    },
                  });
                }}
                value={
                  eventData?.general_info?.date_to &&
                  moment(eventData?.general_info?.date_to).format("YYYY-MM-DD")
                }
              />
              <Typography
                variant="h4"
                component="div"
                sx={{ marginBottom: "20px" }}
                gutterBottom
                className="event-detail-top-head"
              >
                Time From
              </Typography>
              <FilledInput
                sx={{ marginBottom: "20px" }}
                className="signin-input"
                type="time"
                name="time_from"
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      time_from: e.target.value,
                    },
                  });
                }}
                value={eventData?.general_info?.time_from}
              />
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="event-detail-top-head"
              >
                Time To
              </Typography>
              <FilledInput
                className="signin-input"
                type="time"
                name="time_from"
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    general_info: {
                      ...eventData.general_info,
                      time_to: e.target.value,
                    },
                  });
                }}
                value={eventData?.general_info?.time_to}
              />

              <Box component="form" noValidate autoComplete="off" sx={{ p: 3 }}>
                <Grid container spacing={2}></Grid>
              </Box>

              {errors && errors.name && (
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{
                    color: "red",
                    fontSize: "13px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {errors.name}
                </Typography>
              )}
            </FormControl>

            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                className="button-signin"
                sx={{ px: 2 }}
                onClick={(e) => editEvent()}
                // onClick={submitForm}
              >
                {"  Edit Event"}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={opens}
          onClose={handleCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="pinkscroll">
            <IconButton
              sx={{ position: "absolute", right: 20 }}
              onClick={() => handleCloses(!opens)}
            >
              <CloseIcon sx={{ color: "#black", height: "20px" }} />
            </IconButton>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ color: "#752525" }}
            >
              {languageData?.packages?.edit_package}
            </Typography>

            <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
              {/* <TextField
                fullWidth
                label="Days to show gallery"
                id="fullWidth"
                type="number"
                sx={{ marginTop: "10px" }}
                value={parseInt(
                  Math.ceil(
                    Math.abs(
                      new Date(eventData?.general_info?.date_from) -
                        new Date(eventData?.general_info?.date_to)
                    ) /
                      (1000 * 60 * 60 * 24)
                  )
                )}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    date_to: e.target.value,
                  });
                }}
                name="accesstodownloads"
              /> */}
              <TextField
                fullWidth
                label={languageData?.packages?.media_allowed}
                id="fullWidth"
                sx={{ marginTop: "10px" }}
                select
                value={eventData?.mediaTypeallowed}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    mediaTypeallowed: e.target.value,
                  });
                }}
                name="mediaTypeallowed"
              >
                <MenuItem key="Image" value="image">
                  image
                </MenuItem>
                <MenuItem key="Video" value="video">
                  video
                </MenuItem>
                <MenuItem key="Image and Video" value="image/video">
                  image/video
                </MenuItem>
              </TextField>
              <TextField
                name="maxMedia"
                value={eventData?.maxMedia}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    maxMedia: e.target.value,
                  });
                }}
                fullWidth
                label={languageData?.packages?.max_upload_media}
                id="fullWidth"
                sx={{ marginTop: "10px" }}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
              <TextField
                fullWidth
                label={languageData?.packages?.max_guests}
                name="maxGuest"
                value={eventData?.maxGuest}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    maxGuest: e.target.value,
                  });
                }}
                id="fullWidth"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                sx={{ marginTop: "10px" }}
              />
              <TextField
                fullWidth
                label={languageData?.packages?.duration}
                name="duration"
                value={eventData?.duration}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    duration: e.target.value,
                  });
                }}
                id="fullWidth"
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                sx={{ marginTop: "10px" }}
              />
              <TextField
                fullWidth
                label={languageData?.packages?.download_allowed}
                name="downloadAllowed"
                value={eventData?.downloadAllowed}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    downloadAllowed: e.target.value,
                  });
                }}
                id="fullWidth"
                sx={{ marginTop: "10px" }}
                select
              >
                <MenuItem key="Yes" value="yes">
                  yes
                </MenuItem>
                <MenuItem key="No" value="no">
                  no
                </MenuItem>
              </TextField>
              <TextField
                fullWidth
                label={languageData?.packages?.d_unreg_guests}
                id="fullWidth"
                value={eventData?.unregisteredGuest}
                onChange={(e) => {
                  setEventData({
                    ...eventData,
                    unregisteredGuest: e.target.value,
                  });
                }}
                name="unregisteredGuest"
                select
                sx={{ marginTop: "10px" }}
              >
                <MenuItem key="Yes" value="yes">
                  Yes
                </MenuItem>
                <MenuItem key="No" value="no">
                  No
                </MenuItem>
              </TextField>

              {errors && errors.name && (
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{
                    color: "red",
                    fontSize: "13px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {errors.name}
                </Typography>
              )}
            </FormControl>

            <Box sx={{ p: 2 }}>
              <Button
                variant="contained"
                className="button-signin"
                sx={{ px: 2 }}
                onClick={eventpackage}
              >
                {languageData?.packages?.edit_button}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Paper>
      {/* <div style={{ float: "right", marginBottom: "15px" }}>
        <Pagination />
      </div> */}
    </Box>
  );
}
