import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
import {
  CircularProgress,
  Typography,
  Grid,
  Stack,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TaskTable } from "../components/Tasks/TaskTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../config/endpoint";

const SetViewLiense = () => {
  const [activeLiencse, setActiveLiencse] = useState();
  // const [eventName,setEventName] =useState()

  const params = useParams();

  const viewLicesne = () => {
    axios
      .get(`${baseURL}/packages/licences-by-pkg-id/` + params.id)
      .then((val) => {
        setActiveLiencse(val?.data?.licenses);
      });
  };
  useEffect(() => {
    viewLicesne();
  }, []);


  return (
    <Dashboard>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xl={4} lg={4} md={4} sm={0} sx={0} />
        <Grid item xl={4} lg={4} md={4} sm={12} sx={12}>
          <Box>
            <Card className="events-card" sx={{ mt: 2, p: 2 }}>
              <Typography variant="h5" textAlign="center" sx={{ py: 2 }}>
                Package Licenses Information
              </Typography>
              {activeLiencse &&
                activeLiencse.map((item, index) => (
                  <>
                    <Box>
                      <Stack direction="row" spacing={2} alignItems="center">
                        {/* <Typography variant="h6" sx={{ color: "black" }}>
                          Event Name:
                          {item?.event_info?.general_info?.event_name}
                        </Typography> */}
                        {/* <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ p: 1, color: "#0000" }}
                        /> */}
                        <Typography variant="h6">
                          License Number: {item?.license_number}
                        </Typography>
                      </Stack>
                    </Box>
                  </>
                ))}
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Dashboard>
  );
};
export default SetViewLiense;
