import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  createNewTask,
  updateTaskStatus,
} from "../../redux/Tasks/TasksActions";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";
import { baseURL } from "../../config/endpoint";
import { auto } from "@popperjs/core";
export function CreatePackages({
  languageData,
  rows,
  setRows,
  open,
  handleClose,
  callBack,
  selected,
  actionType,
  data,
}) {
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (actionType === "add") {
      setInputValues({});
    } else setInputValues(data);
  }, [actionType, data]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    maxHeight: "90vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    mr: 1,
  };
  useEffect(() => {
    if (selected) {
      setInputValues(selected);
    }
  }, [selected]);
  var handleChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };

    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var handleChangeOption = (e) => {
    var _input = { ...inputValues };

    var _errors = { ...errors };
    _input[e.target.name] = e.target.value;
    _errors[e.target.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var submitForm = (e) => {
    e.preventDefault();

    if (actionType === "add") {
      //   if (!inputValues.name || inputValues.name.length < 1) {
      //     var _errors = { ...errors };
      //     _errors.name = "Please provide a category name!";
      //     setErrors(_errors);
      //     return;
      //   }
      setLoader(true);
      axios
        .post(`${baseURL}/packages/`, {
          inputValues,
        })
        .then((val) => {
          setInputValues([]);
          setRows([...rows, inputValues]);
          setTimeout(() => {
            Swal.close();
          }, 1000);
          setLoader(false);

          // window.location.reload()
          stopLoader(val.data);
        });
      // dispatch(createNewTask(inputValues, stopLoader));
    } else {
      setLoader(true);
      axios
        .post(`${baseURL}/packages/edit/${data._id}`, {
          inputValues,
        })
        .then((val) => {
          stopLoader(val.data);
          setTimeout(() => {
            Swal.close();
          }, 1000);
          setLoader(false);
        });
    }
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      handleClose(false);
      FireSwal(
        "success",
        `${actionType === "add" ? "Package Created" : "Package Updated"}`,
        false,
        false
      );
      callBack();
    } else {
      handleClose(false);
      FireSwal("error", "Oops..", resp.message, false, true);
    }
    setLoader(false);
  };
  console.log(inputValues, "checking ");
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 20 }}
          onClick={() => handleClose(!open)}
        >
          <CloseIcon sx={{ color: "#black", height: "20px" }} />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "#752525" }}
        >
          {actionType === "add"
            ? languageData?.package_add
            : languageData?.edit_package}
        </Typography>
        {actionType === "add" ? (
          <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
            <TextField
              fullWidth
              label={languageData?.title}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              value={inputValues.title}
              name="title"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              type="number"
              label={languageData?.duration}
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              value={inputValues.duration}
              name="duration"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label={languageData?.show_gallery}
              id="fullWidth"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              type="number"
              sx={{ marginTop: "10px" }}
              value={inputValues.accesstodownloads}
              name="accesstodownloads"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label={languageData?.media_allowed}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              select
              value={inputValues.media}
              name="mediaTypeallowed"
              onChange={handleChangeOption}
            >
              <MenuItem key="image" value="image">
                Image
              </MenuItem>
              <MenuItem key="Video" value="video">
                Video
              </MenuItem>
              <MenuItem key=" Image and Video" value="image/video">
                Image and Video
              </MenuItem>
            </TextField>
            <TextField
              name="maxMedia"
              onChange={handleChange}
              fullWidth
              value={inputValues.maxMedia}
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              label={languageData?.max_upload_media}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              type="number"
            />
            <TextField
              fullWidth
              label={languageData?.max_guests}
              name="maxGuest"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              value={inputValues.maxGuest}
              onChange={handleChange}
              id="fullWidth"
              type="number"
              sx={{ marginTop: "10px" }}
            />
            <TextField
              fullWidth
              label={languageData?.download_allowed}
              name="downloadAllowed"
              value={inputValues.downloadAllowed}
              onChange={handleChangeOption}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              select
            >
              <MenuItem key="Yes" value="yes">
                Yes
              </MenuItem>
              <MenuItem key="No" value="no">
                No
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              label={languageData?.d_unreg_guests}
              id="fullWidth"
              value={inputValues.unregisteredGuest}
              name="unregisteredGuest"
              onChange={handleChangeOption}
              select
              sx={{ marginTop: "10px" }}
            >
              <MenuItem key="Yes" value="yes">
                Yes
              </MenuItem>
              <MenuItem key="No" value="no">
                No
              </MenuItem>
            </TextField>

            {errors && errors.name && (
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {errors.name}
              </Typography>
            )}
          </FormControl>
        ) : (
          <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
            <TextField
              fullWidth
              label={languageData?.title}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              value={inputValues.title}
              name="title"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label={languageData?.show_gallery}
              id="fullWidth"
              type="number"
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              sx={{ marginTop: "10px" }}
              value={inputValues.accesstodownloads}
              name="accesstodownloads"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              type="number"
              label={languageData?.duration}
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              value={inputValues.duration}
              name="duration"
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label={languageData?.media_allowed}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              select
              value={inputValues.mediaTypeallowed}
              name="mediaTypeallowed"
              onChange={handleChangeOption}
            >
              <MenuItem key="Image" value="image">
                Image
              </MenuItem>
              <MenuItem key="Video" value="video">
                Video
              </MenuItem>
              <MenuItem key="Image and Video" value="image/video">
                Image and Video
              </MenuItem>
            </TextField>
            <TextField
              name="maxMedia"
              onChange={handleChange}
              fullWidth
              value={inputValues.maxMedia}
              InputProps={{
                inputProps: {
                  min: 1,
                },
              }}
              label={languageData?.max_upload_media}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              type="number"
            />
            <TextField
              fullWidth
              label={languageData?.max_guests}
              name="maxGuest"
              value={inputValues.maxGuest}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              onChange={handleChange}
              id="fullWidth"
              type="number"
              sx={{ marginTop: "10px" }}
            />
            <TextField
              fullWidth
              label={languageData?.download_allowed}
              name="downloadAllowed"
              value={inputValues.downloadAllowed}
              onChange={handleChangeOption}
              id="fullWidth"
              sx={{ marginTop: "10px" }}
              select
            >
              <MenuItem key="Yes" value="yes">
                Yes
              </MenuItem>
              <MenuItem key="No" value="no">
                No
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              label={languageData?.d_unreg_guests}
              id="fullWidth"
              value={inputValues.unregisteredGuest}
              name="unregisteredGuest"
              onChange={handleChangeOption}
              select
              sx={{ marginTop: "10px" }}
            >
              <MenuItem key="Yes" value="yes">
                Yes
              </MenuItem>
              <MenuItem key="No" value="no">
                No
              </MenuItem>
            </TextField>

            {errors && errors.name && (
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {errors.name}
              </Typography>
            )}
          </FormControl>
        )}

        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            className="button-signin"
            disabled={loader}
            sx={{ px: 2 }}
            onClick={submitForm}
          >
            {actionType === "add"
              ? languageData?.add_button
              : languageData?.edit_button}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
