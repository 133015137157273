import { Navigate, Outlet } from "react-router-dom";
import React from "react";
const useAuth = () => {
  const role = localStorage.getItem("eventRole");
  if (role) {
    return true;
  } else {
    return false;
  }
};
const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/" />;
};
export default ProtectedRoutes;