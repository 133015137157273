import { Stack, Button, Typography, IconButton, Avatar } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
export function TaskPerformers() {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ py: 1, px: 2 }}
      >
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{
            font: "normal normal normal 18px/27px PoppinsSemiBold",
            letterSpacing: "0px",
            color: " #4E3D39",
            opacity: "1",
          }}
        >
          Tasks Performed By Guests
        </Typography>
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{
            font: "normal normal normal 18px/27px PoppinsSemiBold",
            letterSpacing: "0px",
            color: " #4E3D39",
            opacity: "1",
          }}
        >
          232
        </Typography>
      </Stack>
      <Box sx={{ px: 1 }}>
        {[1, 2, 3, 4, 5, 6].map((item, index) => (
          <Grid
            container
            spacing={1}
            sx={{
              alignItems: "center",
              mt: 1,
              background: " #F6F6F6 0% 0% no-repeat padding-box",
            }}
            key={index}
          >
            <Grid item xs={2} sx={{ m: "auto" }}>
              <Avatar
                alt="Remy Sharp"
                src="https://randomuser.me/api/portraits/lego/1.jpg"
                sx={{ width: 50, height: 50 }}
              />
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: " normal normal bold 15px/23px Poppins",
                  letterSpacing: "0px",
                  color: " #4E3D39",
                  opacity: "1",
                }}
              >
                Guest {index}
              </Typography>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal normal 13px/20px Poppins",
                  letterSpacing: "0px",
                  color: " #4E3D39",
                  opacity: "1",
                }}
              >
                Added 2 images with Task
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal normal 10px/16px Poppins",
                  letterSpacing: "0px",
                  color: " #828081",
                  opacity: "1",
                  textAlign: "center",
                }}
              >
                3 minutes Ago
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
                sx={{ mt: 1 }}
              >
                <div>
                  <img
                    src="https://randomuser.me/api/portraits/lego/3.jpg"
                    className="task-participants-img"
                  />
                </div>
                <div>
                  <img
                    src="https://randomuser.me/api/portraits/lego/3.jpg"
                    className="task-participants-img"
                  />
                </div>
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
}
