import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { baseURL } from "../config/endpoint";


const Verify = () => {
  const { token } = useParams();

  const [active, setActive] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(false);
    axios.post(`${baseURL}/user/confirm-email`,{token}).then(() => {
      setActive(true);
      setLoading(true);
    }).catch(()=>{setLoading(true);});
  }, []);

//   return <h1>{active && "Your account has been verified!"}</h1>;

  return <h1 style={{textAlign:"center"}}>{loading?active ? "Your account is verify":"Something Goes Wrong":"Please wait"}</h1>;

};

export default Verify;