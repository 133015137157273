import { INSTANCE } from "../../config/axiosInstance";

export class LoginApi {
  signIn = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/login",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  signUp = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/register",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
