import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { baseURL } from "../../config/endpoint";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "../Pagination/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import { CreateTask } from "../../modals/Tasks/createTask";
import { CurrentRole } from "../../helpers/getRole";
import axios from "axios";
import { FireSwal } from "../../helpers/swalNotification";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export function TaskTable({
  event_id,
  category_id,
  type,
  tasks,
  refreshList,
  createModal,
  id,
  setCreateModal,
  selectedItem,
  setSelectedItem,
  actionType,
  setActionType,
  category,
  limit,
  setLimit,
  offset,
  setOffest,
  search,

  setSearch,
  totalRecords,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [name, setName] = React.useState("");
  const navigate = useNavigate();
  console.log(type, "type");
  console.log(event_id, "event_id");
  console.log(category_id, "category_id");
  useEffect(() => {
    if (tasks) {
      setRows(tasks);
    }
  }, [tasks]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const deleted = (id) => {
    FireSwal(
      "Error",
      "Warning",
      languageData?.delete_check,
      true,
      true,
      languageData?.delete_yes,
      languageData?.delete_cancel
    ).then((val) => {
      if (val.isConfirmed) {
        console.log(event_id);
        axios
          .post(`${baseURL}/tasks/taskdeleted`, {
            event_id,
            task_id: id,
          })
          .then((val) => {
            console.log(val, "res");
            FireSwal("success", "", "Task is Deleted", false, false);
            setTimeout(() => Swal.close(), 1000);
            setRows(rows.filter((row) => row._id !== id));
          })
          .catch((error) => {
            FireSwal("Error", "Oops..", "Something went wrong...", false, true);
          });
      }
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const selectedLanguage = localStorage.getItem("language");

  const languageData = JSON.parse(
    localStorage.getItem(`${selectedLanguage}`)
  )?.categories;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,

      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    const headCells = [
      {
        id: "name",
        numeric: true,
        disablePadding: false,
        label: languageData?.task_name,
      },
      {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: languageData?.actions,
      },
    ];
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h5" sx={{ color: "#ff9885", textAlign: "center" }}>
        {category?.name}
      </Typography>
      <Paper sx={{ width: "100%", mb: 2, overflowX: "auto" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            className="task-table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={index}>
                      <TableCell align="left">{row.name}</TableCell>

                      <TableCell align="left">
                        <div className="d-flex justify-content-center action-buttons">
                          {/* <button className="view">
                            <VisibilityIcon
                              sx={{ color: "white", height: "18px" }}
                              onClick={() => navigate("/event-details")}
                            />
                          </button> */}
                          <button
                            className="edit"
                            onClick={() => {
                              setSelectedItem(row);
                              setActionType("edit");
                              setCreateModal(true);
                              setName(row.name);
                            }}
                          >
                            <EditIcon sx={{ color: "white", height: "18px" }} />
                          </button>
                          <button
                            className="delete"
                            style={{ marginLeft: 10 }}
                            onClick={() => deleted(row._id)}
                          >
                            <DeleteIcon
                              sx={{ color: "white", height: "18px" }}
                            />
                          </button>

                          <Box sx={{ px: 2 }}></Box>
                          {/* <button className="delete">
                            <DeleteIcon
                              sx={{ color: "white", height: "18px" }}
                            />
                          </button> */}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <div style={{ float: "right", marginBottom: "15px" }}>
        <Pagination
          numOfPages={
            Math.ceil(totalRecords / limit) > 1
              ? Math.ceil(totalRecords / limit)
              : 1
          }
          offset={offset}
          setOffest={setOffest}
        />
      </div> */}
      <CreateTask
        languageData={languageData}
        category={category}
        open={createModal}
        type={type}
        event_id={event_id}
        category_id={category_id}
        name={name}
        handleClose={setCreateModal}
        callBack={refreshList}
        selected={selectedItem}
        actionType={actionType}
      />
    </Box>
  );
}
