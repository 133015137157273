import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
import { CircularProgress, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { baseURL } from "../config/endpoint";
import { ProfileApi } from "../redux/Profile/ProfileApi";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TaskTable } from "../components/Tasks/TaskTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllTaskAdmin, getAllTaskUser } from "../redux/Tasks/TasksActions";
import { FireSwal } from "../helpers/swalNotification";
import { CurrentRole } from "../helpers/getRole";
import { useLocation, useParams } from "react-router-dom";
export function TaskList({ isAddTaskAlowed }) {
  const dispatch = useDispatch();
  const [searchingCheck, setSearchingCheck] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [actionType, setActionType] = useState("add");
  const [createModal, setCreateModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffest] = useState(0);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [type, setDataType] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const { state } = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");
  const profileApi = new ProfileApi();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`)).categories
  );
  const check = state?.task?.length > 0 ? true : false;
  useEffect(() => {
    axios.get(`${baseURL}/category`).then((val) => {
      setCategory(val.data.category.filter((cat) => cat._id === id)[0]);
    });
  }, []);
  useEffect(() => {
    getAllTasks();
  }, [createModal]);
  const getAllTasks = async () => {
    console.log("------- ************** ------------------", state);
    if (state.type && state.type == 1) {
      const { data } = await profileApi.getTasksByCategory({
        category_id: state.category_id,
      });
      console.log(data, "tasks");
      setTasks(data.tasks);
      setLoading(false);
    } else if (state.type && state.type == 2) {
      const { data } = await profileApi.getTasksByCategory({
        category_id: state.category_id,
      });
      console.log(data, "tasks");
      setTasks(data.tasks);
      setLoading(false);
    } else {
      const { data } = await profileApi.getTasks({ event_id: state.event_id });
      console.log(data, "tasks");
      setTasks(data.tasks);
      setLoading(false);
    }
  };
  var setData = (data, _id) => {
    // if (id) {
    //   setTasks(data?.tasks.filter((val) => val.categoryId == id));
    //   setLoading(false);
    // } else {
    //   if (data.status) {
    //     const ids = check && state.task?.map((val) => val._id);
    //     let dataArr = [];
    //     // let dataArr = data.tasks.filter((val) => ids.includes(val._id))
    //     for (const [key, value] of Object.entries(ids)) {
    //       for (let i = 0; i < data.tasks.length; i++) {
    //         if (data.tasks[i]._id == value) {
    //           dataArr.push(data.tasks[i]);
    //         }
    //       }
    //     }
    //     setTasks(
    //       check
    //         ? dataArr
    //         : data.tasks.filter((val) => val.unCategorized == true)
    //     );
    //     setTotalRecords(data.tasks.length);
    //     setLoading(false);
    //   } else {
    //     FireSwal("error", "Oops..", data.message, false, false);
    //     setLoading(false);
    //   }
    // }
  };
  useEffect(() => {
    setSearchingCheck(false);
    setFilteredTasks(
      tasks.filter((task) =>
        task.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
    setSearchingCheck(true);
  }, [searchInput]);
  useEffect(() => {
    setLoading(true);
    if (CurrentRole() === "admin") {
      dispatch(getAllTaskAdmin({ limit, offset, search, type }));
    } else {
      dispatch(getAllTaskUser({ limit, offset, search, type }));
    }
  }, [limit, offset, search]);
  var refreshList = () => {
    if (CurrentRole() === "admin") {
      dispatch(getAllTaskAdmin({ limit, offset, search, type }));
    } else {
      dispatch(getAllTaskUser({ limit, offset, search, type }));
    }
  };
  var setType = (status) => {
    setDataType(status);
    if (CurrentRole() === "admin") {
      dispatch(
        getAllTaskAdmin(
          { limit: "10", offset: "0", search: "", type: status },
          setData
        )
      );
    } else {
      dispatch(
        getAllTaskUser(
          { limit: "10", offset: "0", search: "", type: status },
          setData
        )
      );
    }
  };
  const searchItems = () => {};

  // let a = b > 3 && b < 2 ? b : a;
  // const buttons = [
  //   // <Button
  //   //   key="all"
  //   //   className={type === 0 ? "active" : ""}
  //   //   onClick={() => setType(0)}
  //   // >
  //   //   All Tasks
  //   // </Button>,
  //   <Button
  //     key="upcoming"
  //     className={type === 1 ? "active" : ""}
  //     onClick={() => setType(1)}
  //   >
  //     Approved
  //   </Button>,
  //   <Button
  //     key="happening"
  //     className={type === 0 ? "active" : ""}
  //     onClick={() => setType(0)}
  //   >
  //     Pending
  //   </Button>,
  //   <Button
  //     key="completed"
  //     className={type === 2 ? "active" : ""}
  //     onClick={() => setType(2)}

  //   >
  //     Rejected
  //   </Button>,
  // ];

  return (
    <Dashboard>
      <Container>
        <Card className="events-card" sx={{ mt: 2 }}>
          <Box sx={{ borderBottom: "2px solid #EBEBEB ", display: "block" }}>
            <div
              className="d-flex align-items-center events-add-link justify-content-center"
              style={{ maxWidth: "250px", padding: "10px 0px" }}
              onClick={() => {
                setCreateModal(!createModal);
                setActionType("add");
              }}
            >
              <AddIcon sx={{ color: "white" }} />
              <Typography
                component="div"
                variant="h5"
                sx={{
                  font: "normal normal normal 15px/23px Poppins",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: "1",
                }}
              >
                {languageData?.add_task}
              </Typography>
            </div>
          </Box>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                sx={{ textAlign: "center", color: "#FF9885" }}
              />
            </div>
          ) : (
            <Box sx={{ display: "block", p: 3 }}>
              <div className="d-flex justify-content-between align-items-center">
                {/* {CurrentRole() === "admin" &&
              <ButtonGroup size="small" className="event-tab-buttons">
                {buttons}
              </ButtonGroup>
} */}

                <div>
                  <FormControl sx={{ maxWidth: "600px" }}>
                    <OutlinedInput
                      placeholder={languageData?.search}
                      className="event-search"
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>

              <Box sx={{ mt: 2 }}>
                {searchingCheck &&
                  (searchInput === "" ? (
                    <TaskTable
                      event_id={state.event_id}
                      category_id={state.category_id}
                      type={state.type}
                      tasks={tasks}
                      refreshList={refreshList}
                      id={id}
                      createModal={createModal}
                      setCreateModal={setCreateModal}
                      setSelectedItem={setSelected}
                      selectedItem={selected}
                      actionType={actionType}
                      setActionType={setActionType}
                      limit={limit}
                      setLimit={setLimit}
                      offset={offset}
                      setOffest={setOffest}
                      setSearch={setSearch}
                      totalRecords={totalRecords}
                      category={category}
                    />
                  ) : filteredTasks.length !== 0 ? (
                    <TaskTable
                      tasks={filteredTasks}
                      refreshList={refreshList}
                      id={id}
                      createModal={createModal}
                      setCreateModal={setCreateModal}
                      setSelectedItem={setSelected}
                      selectedItem={selected}
                      actionType={actionType}
                      setActionType={setActionType}
                      limit={limit}
                      setLimit={setLimit}
                      offset={offset}
                      setOffest={setOffest}
                      setSearch={setSearch}
                      totalRecords={totalRecords}
                      category={category}
                    />
                  ) : (
                    <div>No Task Found</div>
                  ))}
              </Box>
            </Box>
          )}
        </Card>
      </Container>
    </Dashboard>
  );
}
