import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { TaskList } from "../components/Tasks/TaskList";
import { TaskPerformers } from "../components/Tasks/TaskPerformers";
import Dashboard from "./Dashboard";

export function Tasks() {
  return (
    <Dashboard>
      <Card sx={{ p: 2, mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TaskList />
          </Grid>
          <Grid item xs={6} className="task-performers">
            <TaskPerformers />
          </Grid>
        </Grid>
      </Card>
    </Dashboard>
  );
}
