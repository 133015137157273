import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Card,
  Grid,
  IconButton,
  Typography,
  Stack,
  Switch,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GalleryImageSlider } from "./GalleryImageSlider";
import cross from "../../assets/images/cross.png";
import axios from "axios";
import { FireSwal } from "../../helpers/swalNotification";
export function GalleryModal({ modal, setModal, data, status, details }) {
  const handleChange = () => {
    axios
      .post("baseURL /gallery/makePublic", { id: data._id, status: !status })
      .then(() => {});
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #0000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    maxWidth: "600px",
    overflowY: "auto",
    height: "90vh",
    borderRadius: 2,
  };
  return (
    <Modal
      open={modal}
      onClose={() => setModal(!modal)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }} className="pinkscroll">
        <div style={{ textAlign: "right" }}>
          {/* <IconButton>
            <img
              src={cross}
              sx={{ color: "#FF3E3E", height: "25px" }}
              onClick={() => setModal(!modal)}
            />
          </IconButton> */}
          <div style={{ display: "flex", justifyContent: "right", height: 50 }}>
            <Box
              sx={{
                padding: 0,
                color: "white",
                right: 0,
                width: 30,
                height: 30,
                background: "#ff9885",
                borderRadius: 1,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => setModal(!modal)}
            >
              <Typography sx={{ marginTop: 0.5 }}>X</Typography>
            </Box>
          </div>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              sx={{ textAlign: "center", p: "0 !important" }}
            >
              {/* <GalleryImageSlider data={data}/> */}
              {data.type !== "application/mp4" ? (
                <img
                  src={data.image}
                  style={{
                    // height: "450px",
                    maxWidth: "100%",
                    objectFit: "contain",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "13px",
                    height: "450px",
                    marginTop: "0px",
                  }}
                  // onClick={()=>{
                  //   let source = ""
                  //   var el = document. createElement("a");
                  //   el. setAttribute("href", source);
                  //   el. setAttribute("download", `hahaha.png`);
                  //   document. body. appendChild(el);
                  //   el. click();
                  //   el. remove();
                  // }}
                />
              ) : (
                <div>
                  <video
                    autoPlay
                    controls
                    style={{
                      maxWidth: "300px",
                      borderRadius: "9px",
                      cursor: "pointer",
                    }}
                  >
                    <source src={`${data.image}`} type="video/mp4" />
                  </video>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} lg={12} sx={{ p: "0 !important" }}>
              <Card
                sx={{
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "13px",
                  // height: "450px",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      sx={{
                        font: "normal bold normal 15px/23px Poppins",
                        letterSpacing: "0px",
                        color: " #828081",
                        opacity: "1",
                      }}
                    >
                      Event Name
                    </Typography>
                    {/* <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      sx={{
                        font: "normal normal normal 10px/16px Poppins",
                        letterSpacing: "0px",
                        color: " #828081",
                        opacity: "1",
                      }}
                    >
                      3 minutes ago
                    </Typography> */}
                  </Stack>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal bold normal 18px/27px Poppins",
                      letterSpacing: "0px",
                      color: " #4E3D39",
                      opacity: "1",
                      textAlign: "left",
                    }}
                  >
                    {details?.event?.general_info?.event_name}
                  </Typography>
                </Box>
                <div
                  style={{
                    height: "4px",
                    background: "#EFEFEF 0% 0% no-repeat padding-box",
                    opacity: "1",
                  }}
                ></div>
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      sx={{
                        font: "normal bold normal 15px/23px Poppins",
                        letterSpacing: "0px",
                        color: " #828081",
                        opacity: "1",
                      }}
                    >
                      Task Name
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal bold normal 18px/27px Poppins",
                      letterSpacing: "0px",
                      color: " #4E3D39",
                      opacity: "1",
                      textAlign: "left",
                    }}
                  >
                    {data?.task?.name}
                  </Typography>
                </Box>
                {/* <div
                  style={{
                    height: "4px",
                    background: "#EFEFEF 0% 0% no-repeat padding-box",
                    opacity: "1",
                  }}
                ></div>
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      sx={{
                        font: "normal bold normal 15px/23px Poppins",
                        letterSpacing: "0px",
                        color: " #828081",
                        opacity: "1",
                      }}
                    >
                      Guest Name
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal bold normal 18px/27px Poppins",
                      letterSpacing: "0px",
                      color: " #4E3D39",
                      opacity: "1",
                      textAlign: "left",
                    }}
                  >
                    Guest1
                  </Typography>
                </Box> */}
                {/* <div
                  style={{
                    height: "4px",
                    background: "#EFEFEF 0% 0% no-repeat padding-box",
                    opacity: "1",
                  }}
                ></div>
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      sx={{
                        font: "normal bold normal 18px/27px Poppins",
                        letterSpacing: "0px",
                        color: " #4E3D39",
                        opacity: "1",
                        textAlign: "left",
                      }}
                    >
                      {status ? "Public" : "Private"}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        sx={{
                          font: "normal normal medium 18px/27px Poppins",
                          letterSpacing: "0px",
                          color: " #828081",
                        }}
                      >
                        No
                      </Typography>
                      <Switch onChange={handleChange} />
                      <Typography
                        sx={{
                          font: "normal normal medium 18px/27px Poppins",
                          letterSpacing: "0px",
                          color: " #828081",
                        }}
                      >
                        Yes
                      </Typography>
                    </Stack>
                  </Stack>
                </Box> */}
              </Card>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}
