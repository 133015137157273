import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  createNewTask,
  updateHostStatus,
  updateTaskStatus,
} from "../../redux/Tasks/TasksActions";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
export function EditHost({
  open,
  handleClose,
  callBack,
  selected,
  actionType,
}) {
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    bgcolor: "#fff",
    boxShadow: 24,
    border: "none",
    p: 4,
    mr: 1,
    position: "relative",
  };
  useEffect(() => {
    if (selected) {
      setInputValues(selected);
    }
  }, [selected]);
  var handleChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var submitForm = (e) => {
    e.preventDefault();

    if (actionType === "add") {
      if (!inputValues.name || inputValues.name.length < 1) {
        var _errors = { ...errors };
        _errors.name = "Please provide a task name!";
        setErrors(_errors);
        return;
      }
      setLoader(true);
      dispatch(createNewTask(inputValues, stopLoader));
    } else {
      if (!inputValues.status || inputValues.status.length < 1) {
        var _errors = { ...errors };
        _errors.status = "Please select a status to update!";
        setErrors(_errors);
        return;
      }
      setLoader(true);
      dispatch(
        updateHostStatus(
          { host_id: inputValues._id, status: inputValues.status },
          stopLoader
        )
      );
    }
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      handleClose(false);
      FireSwal(
        "success",
        actionType === "add"
          ? "Task Created Successfully!"
          : "Host status updated!",
        "",
        false,
        false
      );
      callBack();
      setTimeout(() => {
        Swal.close();
      }, 2000);
    } else {
      handleClose(false);
      FireSwal("error", "Oops..", resp.message, false, true);
    }
    setLoader(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 20 }}
          onClick={() => handleClose(!open)}
        >
          <CloseIcon sx={{ color: "#black", height: "20px" }} />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "#752525" }}
        >
          {actionType === "add" ? "Add Task" : "Edit Host"}
        </Typography>

        <FormControl>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{
                "&.MuiFormLabel-root.Mui-focused": {
                  color: "black",
                },
            }}
          >
            Status
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            onChange={handleChange}
            name="status"
          >
            <FormControlLabel value={1} control={<Radio />} label="Approve" />

            <FormControlLabel value={2} control={<Radio />} label="Reject" />
            <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
              {errors && errors.name && (
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{
                    color: "red",
                    fontSize: "13px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {errors.name}
                </Typography>
              )}
            </FormControl>
            {/* <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              /> */}
          </RadioGroup>
          {errors && errors.status && (
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                color: "red",
                fontSize: "13px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {errors.status}
            </Typography>
          )}
        </FormControl>

        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            className="button-signin"
            disabled={loader}
            sx={{ px: 2 }}
            onClick={submitForm}
          >
            {actionType === "add" ? "Create" : "Edit"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
