import Dashboard from "./Dashboard";
import { exportComponentAsJPEG } from "react-component-export-image";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Skeleton,
  Stack,
  Switch,
  Typography,
  Modal,
  Box,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveAs } from "file-saver";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReactComponent as VoidPink } from "../assets/svg/void-pink.svg";
import Grid from "@mui/material/Grid";
import { Link, useParams } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SaveIcon from "@mui/icons-material/Save";
import { GalleryModal } from "../components/Gallery Modal/GalleryModal";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import React from "react";
import { ImageOutlined } from "@mui/icons-material";
import { FireSwal } from "../helpers/swalNotification";
import Swal from "sweetalert2";
import { ProfileApi } from "../redux/Profile/ProfileApi";
import { baseURL, webUrl } from "../config/endpoint";
import ProgressiveImage from "react-progressive-graceful-image";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import eventCover from "../assets/images/event-cover.png";
import { width } from "@mui/system";
// import { set } from "immutable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  bgcolor: "background.paper",
  borderRadius: 1.5,
  boxShadow: 24,
  pt: 1,
  pb: 4,
  position: "relative",
};

export function Gallery() {
  const { id, isPrivate = true } = useParams();
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [image, setImage] = React.useState([]);
  const [totalVideo, setTotalVideo] = useState(0);
  const [details, setDetails] = useState(0);
  const [data, setdata] = React.useState([]);
  const [status, setStatus] = React.useState(JSON.parse(isPrivate));
  const [imageId, setImageId] = React.useState([]);
  const imagesToDownloadId = [];
  const [imagesToDownload, setImagesToDownload] = useState([]);
  const [loader, setLoader] = React.useState(true);
  const [bufferData, setBufferData] = React.useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const [totalImage, setTotalImage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const muiTheme = useTheme();
  const mobilescreen = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [allGuests, setAllGuests] = useState([]);
  const [downloadingLoader, setDownloadingLoader] = useState(false);
  const componentRef = useRef();
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = React.useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`))
  );
  const [guests, setGuests] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState({
    label: "All",
    id: "all",
    name: "All",
  });
  // const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const refs = useRef([]);
  let tempRef = useRef();
  console.log(status, "statusssssssss");
  const profileApi = new ProfileApi();

  const token = localStorage.getItem("eventAuth");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let bufferToImage = (item) => {
    return btoa(String.fromCharCode(...new Uint8Array(item.buffer.data)));
  };

  useEffect(() => {
    axios
      .post(
        `${baseURL}/events/event-guests/`,
        {
          event_id: id,
        },
        config
      )
      .then((val) => {
        var temp = [{ label: "All", id: "all", name: "All" }];
        val?.data?.guests.map((item) => {
          temp.push({
            ...item,
            label: item?.uid?.username,
            id: item?.uid?._id,
          });
        });
        setGuests(temp);
      });
  }, []);

  useEffect(async () => {
    setLoader(true);
    await axios
      .post(
        `${baseURL}/gallery/public-gallery`,
        {
          user_id: selectedGuest.id,
          event_id: id,
          status: !status,
        },
        config
      )
      .then((val) => {
        setImage(val?.data?.gal);
        setTotalVideo(val?.data?.totalVideos);
        setTotalImage(val?.data?.totalImages);
        setDetails(val?.data);
        setLoader(false);
      })
      .catch(() => {
        setImage([]);
      });
  }, [selectedGuest, status]);
  const submit = () => {
    axios
      .post(`${baseURL}/gallery/update-status`, {
        image_ids: imageId,
        status: status,
      })
      .then((val) => {
        FireSwal("success", "Status Change", "", false, false);
        setStatus(!status);
        setImageId([]);
        setdata([]);
        setSelectAllCheck(false);
        setImagesToDownload([]);
      });
  };
  const openNewWindowHandler = () => {
    window.open(`/gallery-view/${id}`, "_blank");
    setOpenModal(false);
  };
  const copyLinkHandler = () => {
    navigator.clipboard.writeText(`${webUrl}/gallery-view/${id}`);
    setOpenModal(false);
    setShowAlert(true);
  };
  const addToRefs = (el, item) => {
    setImagesToDownload([
      ...imagesToDownload,
      {
        element: el,
        image_id: item,
      },
    ]);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };
  const downloadImages = () => {
    if (isVideoSelected) {
      FireSwal(
        "Warning",
        "Warning",
        "Videos cannot be downloaded. Please unselect videos.",
        false,
        true
      );
      return;
    }
    setDownloadingLoader(true);
    // const arrayOfimageIDs = Object.entries(imagesToDownload).map(
    //   ([a, b]) => b.image_id
    // );
    // const arrayOfimageDivs = Object.entries(imagesToDownload).map(
    //   ([a, b]) => b.element
    // );

    //const array = arrayOfimageIDs.map((item) => imageId.includes(item));
    imageId.map(async (item, i) => {
      //if (array[i] === true) {
      //componentRef.current = arrayOfimageDivs[i];
      console.log(item, "imgIDs");
      let html = document.getElementById(item);
      html.style.width = "100%";

      const dataUrl = await htmlToImage.toPng(html);
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      link.href = dataUrl;
      link.click();
      html.style.width = "100%";
      //}
    });
    // setImageId([]);
    // setSelectAllCheck(false);
    setTimeout(() => {
      setDownloadingLoader(false);
    }, 3000);
  };
  const deleteImages = async (id) => {
    FireSwal(
      "Warning",
      "Warning",

      "Are you sure, you want to delete selected Images?",

      true,
      true
    )
      .then(async (val) => {
        if (val.isConfirmed) {
          const { data } = await profileApi.deleteImage({ images: imageId });
          FireSwal("success", "", "Images deleted successfully", false, false);
          setImage(image.filter((img) => !imageId.includes(img._id)));
          setImageId([]);
          setSelectAllCheck(false);
          setdata([]);
          setTimeout(() => Swal.close(), 1000);
        }
      })
      .catch((error) => {
        FireSwal("Error", "Oops..", "Something went wrong...", false, true);
      });
  };

  return (
    <Dashboard>
      <Card sx={{ p: 2, mt: 2 }}>
        <Stack
          direction={mobilescreen ? "column" : "row"}
          justifyContent="space-between"
          spacing={0}
        >
          <Stack direction="row" alignItems="center" spacing={0} sx={{ pt: 0 }}>
            <Button
              variant="contained"
              className={status ? `default-button` : " default-button2"}
              sx={{ color: "white", mr: 1 }}
              onClick={() => {
                if (!status) {
                  setStatus(true);
                  setImageId([]);
                  setSelectAllCheck(false);
                  setImagesToDownload([]);
                }
              }}
            >
              {languageData?.eventGallery?.public}
            </Button>
            <Button
              variant="contained"
              className={!status ? `default-button` : "default-button2 "}
              sx={{ color: "white", mr: 1 }}
              onClick={() => {
                if (status) {
                  setStatus(false);
                  setImageId([]);
                  setSelectAllCheck(false);
                  setImagesToDownload([]);
                }
              }}
            >
              {languageData?.eventGallery?.private}
            </Button>
          </Stack>
          <Stack
            direction="row"
            // justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ pt: 1 }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={guests}
              sx={{
                maxWidth: 300,
                minWidth: 240,
                // "& .MuiAutocomplete-input": { color: "#aaaaaa" },
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Select Guest"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      zIndex: 0,
                    },
                  }}
                />
              )}
              value={selectedGuest}
              onChange={(e, value) => {
                setSelectedGuest(value);
              }}
            />
          </Stack>
        </Stack>
        {imageId.length > 0 && (
          <>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ pt: 1 }}
            >
              <Typography
                sx={{
                  font: "normal normal medium 18px/27px Poppins",
                  letterSpacing: "0px",
                  color: " #828081",
                }}
              >
                {!status ? "Make Public" : "Make Private"} No
              </Typography>
              <Switch onClick={(e) => submit()} color="warning" />
              <Typography
                sx={{
                  font: "normal normal medium 18px/27px Poppins",
                  letterSpacing: "0px",
                  color: " #828081",
                }}
              >
                Yes
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ py: 1 }}
              >
                <Button
                  variant="contained"
                  className={`default-button`}
                  sx={{ color: "white", minWidth: "120px" }}
                  onClick={deleteImages}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  className={`default-button`}
                  sx={{ color: "white" }}
                  onClick={downloadImages}
                >
                  Download
                </Button>
              </Stack>
              <Stack>
                <Typography>
                  Select All
                  <Checkbox
                    sx={{
                      color: "#FF9885",
                      "&.Mui-checked": {
                        color: "#FF9885",
                      },
                    }}
                    onChange={(e) => {
                      console.log("ok");
                      if (e.target.checked) {
                        let arr = [];
                        image.map((item) => {
                          if (item.type !== "application/mp4") {
                            arr.push(item._id);
                          }
                        });
                        setImageId(arr);
                        setSelectAllCheck(true);
                      } else {
                        setImageId([]);
                        setSelectAllCheck(false);
                      }
                    }}
                  />
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
        <Card
          className="gallery-card pinkscroll"
          sx={{
            mt: 2,
            minHeight: "60vh",
            maxHeight: "90vh",
            overflowY: "scroll",
            with: "100%",
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{ width: "100%", alignItems: "baseline", position: "relative" }}
          >
            {!loader ? (
              image.length > 0 ? (
                image.map((item, index) => (
                  <Grid
                    id="gallery"
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={2.4}
                    sx={{
                      position: "relative",
                      lineHeight: "0",
                      opacity: downloadingLoader ? 0 : 1,
                    }}
                  >
                    <div>
                      {showTasks && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                            }}
                          >
                            {selectAllCheck &&
                              item.type != "application/mp4" && (
                                <Checkbox
                                  defaultChecked
                                  sx={{
                                    color: "#FF9885",
                                    "&.Mui-checked": {
                                      color: "#FF9885",
                                    },
                                  }}
                                  onChange={(val) => {
                                    console.log("ok1");
                                    if (val.target.checked) {
                                      setImageId([...imageId, item._id]);
                                    } else {
                                      setImageId(
                                        imageId.filter(
                                          (image) => image != item._id
                                        )
                                      );
                                    }
                                  }}
                                />
                              )}
                            {selectAllCheck &&
                              item.type == "application/mp4" && (
                                <Checkbox
                                  sx={{
                                    color: "#FF9885",
                                    "&.Mui-checked": {
                                      color: "#FF9885",
                                    },
                                  }}
                                  onChange={(val) => {
                                    console.log("ok2");
                                    if (val.target.checked) {
                                      setIsVideoSelected(true);
                                    } else {
                                      setIsVideoSelected(false);
                                    }
                                  }}
                                />
                              )}

                            {!selectAllCheck && (
                              <Checkbox
                                sx={{
                                  color: "#FF9885",
                                  "&.Mui-checked": {
                                    color: "#FF9885",
                                  },
                                }}
                                onChange={(val) => {
                                  console.log("ok3");
                                  if (val.target.checked) {
                                    setImageId([...imageId, item._id]);
                                    if (item.type == "application/mp4") {
                                      setIsVideoSelected(true);
                                    }
                                  } else {
                                    var _temp = imageId.filter(
                                      (image) => image != item._id
                                    );
                                    setImageId(
                                      imageId.filter(
                                        (image) => image != item._id
                                      )
                                    );
                                    if (item.type == "application/mp4") {
                                      setIsVideoSelected(false);
                                    }
                                  }
                                }}
                              />
                            )}
                            {console.log(imageId, "ids")}
                          </div>
                        </>
                      )}

                      {item.type == "application/mp4" ? (
                        <div>
                          <video
                            controls
                            style={{
                              height: "300px",
                              width: "100%",
                              borderRadius: "9px 9px 0px 0px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setModal(!modal);
                              setdata(item);
                            }}
                          >
                            <source src={`${item.image}`} type="video/mp4" />
                          </video>
                          <Card
                            sx={{
                              borderRadius: "0",
                              backgroundColor: "#FEF3F1",
                              color: "black",
                              p: "10px",
                              minWidth: "100px",
                              maxWidth: "100%",
                              textAlign: "center",
                              lineHeight: "1.5",
                              borderRadius: " 0px 0px 9px 9px",
                              minHeight: "70px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {item?.task?.name
                              ? item?.task?.name
                              : item?.event_name}
                          </Card>
                        </div>
                      ) : (
                        <div>
                          <ProgressiveImage src={item.image}>
                            {(src, loading) => {
                              !loading
                                ? setShowTasks(true)
                                : setShowTasks(false);
                              return loading ? (
                                <Skeleton
                                  variant="rectangular"
                                  // width={180}
                                  height={180}
                                />
                              ) : (
                                <div
                                  ref={(el) => addToRefs(el, item._id)}
                                  id={item._id}
                                >
                                  <img
                                    src={`data:image/png;base64,${item.buffer}`}
                                    style={{
                                      height: "300px",
                                      width: "100%",
                                      borderRadius: "9px 9px 0px 0px",
                                      cursor: "pointer",
                                      objectFit: "contain",
                                    }}
                                    onClick={() => {
                                      setModal(!modal);
                                      setdata(item);
                                    }}
                                    alt="sea beach"
                                  />
                                  <Card
                                    sx={{
                                      borderRadius: "0",
                                      backgroundColor: "#FEF3F1",
                                      color: "black",
                                      p: "10px",
                                      minWidth: "100px",
                                      maxWidth: "100%",
                                      textAlign: "center",
                                      lineHeight: "1.5",
                                      borderRadius: " 0px 0px 9px 9px",
                                      minHeight: "70px",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item?.task?.name
                                      ? item?.task?.name
                                      : item?.event_name}
                                  </Card>
                                </div>
                              );
                            }}
                          </ProgressiveImage>
                        </div>
                      )}
                    </div>
                  </Grid>
                ))
              ) : (
                <Stack
                  direction="column"
                  sx={{ alignItems: "center", mt: 5, width: "100%" }}
                >
                  <div>
                    <VoidPink style={{ height: "200px" }} />
                  </div>
                  <div>
                    <Typography
                      sx={{
                        font: "normal normal medium 18px PoppinsSemiBold",
                        letterSpacing: "0px",
                        color: " #FF9885",
                        textAlign: "center",
                        fontSize: "23px",
                      }}
                    >
                      {languageData?.eventGallery?.no_data}
                    </Typography>
                  </div>
                </Stack>
              )
            ) : (
              <div
                style={{
                  margin: "auto",
                  marginTop: 5,
                }}
              >
                <CircularProgress top={10} sx={{ color: "#FF9885" }} />
              </div>
            )}
            {downloadingLoader ? (
              <div
                style={{
                  // margin: "auto",
                  position: "absolute",
                  marginTop: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CircularProgress top={10} sx={{ color: "#FF9885" }} />
              </div>
            ) : (
              <></>
            )}
          </Grid>
        </Card>
        <div className="text-center" style={{ paddingTop: "10px" }}>
          <Stack
            direction={mobilescreen ? "column" : "row"}
            justifyContent="space-between"
            // alignItems="center"
            spacing={1}
          >
            <Stack
              direction="row"
              // justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Button
                className="default-button"
                variant="outlined"
                sx={{ color: "white" }}
                onClick={() => setOpenModal(true)}
              >
                {languageData?.eventGallery?.create_link}
              </Button>
            </Stack>

            <Stack
              direction="row"
              // justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <div className="profile-counts">
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  sx={{
                    font: "normal normal normal 11px/15px Poppins",
                    letterSpacing: "0px",
                    color: " #FFFFFF",
                    opacity: "1",
                  }}
                >
                  {languageData?.eventGallery?.total_videos}
                </Typography>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  sx={{
                    font: "normal normal bold 18px/15px Poppins",
                    letterSpacing: "0px",
                    color: " #FFFFFF",
                    opacity: "1",
                    mb: 0,
                  }}
                >
                  {totalVideo}
                </Typography>
              </div>
              <div className="profile-counts">
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  sx={{
                    font: "normal normal normal 11px/15px Poppins",
                    letterSpacing: "0px",
                    color: " #FFFFFF",
                    opacity: "1",
                  }}
                >
                  {languageData?.eventGallery?.total_images}
                </Typography>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  sx={{
                    font: "normal normal bold 18px/15px Poppins",
                    letterSpacing: "0px",
                    color: " #FFFFFF",
                    opacity: "1",
                    mb: 0,
                  }}
                >
                  {totalImage}
                </Typography>
              </div>
            </Stack>
          </Stack>
        </div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} textAlign="center">
            <Grid container>
              <Grid item xs={8} textAlign="left">
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  sx={{ pb: 1, pl: 1.1, fontFamily: "poppinsSemiBold" }}
                >
                  {languageData?.eventGallery?.gallery_link}
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} textAlign="right">
                <IconButton
                  sx={{ pt: "4px" }}
                  onClick={() => setOpenModal(false)}
                >
                  <CloseIcon sx={{ color: "#ff9885" }} />
                </IconButton>
              </Grid>
            </Grid>
            <Typography id="modal-modal-description" sx={{ mt: 1, mb: 2 }}>
              {languageData?.eventGallery?.create_link_msg}
            </Typography>
            <Button
              sx={{ mt: 1, mr: 2 }}
              variant="contained"
              className="default-button"
              onClick={openNewWindowHandler}
            >
              {languageData?.eventGallery?.open_link}{" "}
              <OpenInNewIcon sx={{ color: "white", ml: 1 }} />
            </Button>
            <Button
              variant="contained"
              className="default-button"
              sx={{ mt: 1 }}
              onClick={copyLinkHandler}
            >
              {languageData?.eventGallery?.copy_link}{" "}
              <ContentCopyIcon sx={{ color: "white", ml: 1 }} />
            </Button>
          </Box>
        </Modal>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showAlert}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            // sx={{  }}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%", background: "#ff9885", mt: 8 }}
          >
            Link Copied!
          </Alert>
        </Snackbar>
        <GalleryModal
          modal={modal}
          setModal={setModal}
          data={data}
          details={details}
          status={status}
        />
      </Card>
    </Dashboard>
  );
}
