import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import imgPlaceholder from "../../assets/images/imagePlaceholder.png";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { useContext } from "react";
import Switch from "@mui/material/Switch";
import { CREATE_EVENT_CONTEXT } from "../../Contexts/CreateEventContext";
import moment from "moment";
import { useEffect, useState } from "react";
import { getApprovedTasks } from "../../redux/Tasks/TasksActions";
import { useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import axios from "axios";
import { useRef } from "react";
import Tags from "./Tags";
import { baseURL } from "../../config/endpoint";
import { Required } from "../Required Field/RequiredField";
import CreatableSelect from "react-select/creatable";
export function CreateEventFields({ step }) {
  const {
    errors,
    inputValues,
    handleImageChange,
    handleFieldChange,
    setInputValues,
    putNewError,
    handleTaskChange,
    handleTaskChange2,
    handleTaskDelete,
    handleMakeGalleryChange,
    tags,
    tagRef,
    handleDelete,
    handleOnSubmit,
  } = useContext(CREATE_EVENT_CONTEXT);
  const [tasks, setTasks] = useState([]);
  const [original, setOriginal] = useState([]);
  const [category, setCategory] = useState([]);
  const [filters, setFilter] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({
    label: "All",
    id: "all",
    name: "All",
  });
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`)).eventForm
  );
  const dispatch = useDispatch();

  var stopLoader = (data) => {
    setTasks(data.tasks);
    setOriginal(data.tasks);
  };
  const selectAll = () => {
    if (filters == "All" || filters == "") {
      handleTaskChange(original);
    } else {
      const data = original.filter((val) => val.categoryId == filters);
      handleTaskChange(data);
    }
  };
  let ids = inputValues.tasks ? inputValues.tasks?.map((val) => val._id) : [];
  useEffect(() => {
    dispatch(getApprovedTasks(stopLoader));
  }, []);
  useEffect(() => {
    axios.get(`${baseURL}/category`).then((val) => {
      var temp = [{ label: "All", id: "all", name: "All" }];
      val.data.category.map((item) => temp.push({ ...item, label: item.name }));
      setCategory(temp);
    });
  }, []);
  const filterTask = (filters) => {
    setTasks(original.filter((val) => val.categoryId == filters));
    setFilter(filters);
  };
  const AllTask = (filters) => {
    setTasks(original);
    setFilter("All");
  };

  // const handleSwitch =(e)={
  //   inputValues.public =
  // }
  let currentdate = new Date().toISOString().slice(0, 10);

  return (
    <>
      <Box sx={{ display: "block", minHeight: "50vh" }}>
        <Box sx={{ backgroundColor: "red" }}>
          <Grid sx={{ backgroundColor: "#ff9885" }} textAlign="center">
            {" "}
            <Typography
              sx={{ color: "white", padding: 2, fontFamily: "PoppinsSemiBold" }}
              variant="h4"
            >
              {" "}
              {languageData?.event_form}
            </Typography>
          </Grid>
        </Box>
        {/* {step === 1 && ( */}
        <Box component="form" noValidate autoComplete="off" sx={{ p: 3 }}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="event-detail-top-head"
              >
                Add Lisence No <Required /> 
              </Typography>*/}
              <TextField
                sx={{ width: "100%" }}
                label={languageData?.license}
                value={inputValues?.license_number}
                onChange={handleFieldChange}
                size="small"
                name="license_number"
              >
                {/* <OutlinedInput
                /> */}
              </TextField>
              {errors && errors.license_number && (
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{
                    color: "red",
                    fontSize: "13px",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {errors.license_number}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={12} sm={6}>
              <Box component="form" noValidate autoComplete="off">
                {/* <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Event Name
                </Typography> */}
                <TextField
                  sx={{ width: "100%" }}
                  onChange={handleFieldChange}
                  value={inputValues?.event_name}
                  label={languageData?.name}
                  size="small"
                  name="event_name"
                >
                  <OutlinedInput
                  // className="create-event-field"
                  // name="event_name"
                  />
                </TextField>
                {errors && errors.event_name && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.event_name}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box component="form" noValidate autoComplete="off">
                {/* <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Event Location
                </Typography> */}
                <TextField
                  sx={{ width: "100%" }}
                  value={inputValues?.event_location}
                  onChange={handleFieldChange}
                  label={languageData?.location}
                  size="small"
                  name="event_location"
                >
                  <OutlinedInput
                  // className="create-event-field"
                  // name="event_location"
                  />
                </TextField>
                {errors && errors.event_location && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.event_location}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={12} lg={6}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ pt: 1 }}
              >
                {/* <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Description
                </Typography> */}
                <TextField
                  sx={{ width: "100%" }}
                  label={languageData?.description}
                  onChange={handleFieldChange}
                  value={inputValues?.description}
                  size="large"
                  // variant="filled"
                  multiline
                  name="description"
                >
                  <TextareaAutosize
                  // maxRows={5}
                  // aria-label="maximum height"
                  // className="create-event-field"
                  // name="description"
                  // onChange={handleFieldChange}
                  // value={inputValues?.description}
                  />
                  {/* <OutlinedInput
                      inputProps={{ inputMode: "textbox" }}
                      multiline
                    /> */}
                </TextField>
                {errors && errors.description && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.description}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={12} sm={6}>
              <Box component="form" noValidate autoComplete="off">
                {/* <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Start Date
                </Typography> */}
                <TextField
                  sx={{ width: "100%", color: "#aaaaaa" }}
                  type="date"
                  // className="create-event-field"
                  name="date_from"
                  placeholder={languageData?.start_date}
                  value={
                    inputValues?.date_from &&
                    moment(inputValues?.date_from).format("YYYY-MM-DD")
                  }
                  inputProps={{ min: currentdate }}
                  onChange={handleFieldChange}
                  size="small"
                />
                {errors && errors.date_from && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.date_from}
                  </Typography>
                )}
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Box component="form" noValidate autoComplete="off">
                
                <TextField
                  sx={{ width: "100%" }}
                  type="number"
                  name="date_to"
                  label="Duration"
                  inputProps={{ min: 1 }}
                  onChange={handleFieldChange}
                  value={inputValues?.date_to && parseInt(inputValues.date_to)}
                  size="small"
                >
                  
                </TextField>
                {errors && errors.date_to && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.date_to}
                  </Typography>
                )}
              </Box>
            </Grid> */}
          </Grid>
          <Grid container spacing={1} sx={{ py: 2, px: 1 }}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="event-detail-top-head"
                sx={{ textAlign: "left" }}
              >
                {languageData?.profile_image}
              </Typography>
              <Grid container spacing={2} sx={{ alignItems: "center", mt: 1 }}>
                <Grid item xs={8} sm={6}>
                  {" "}
                  <div>
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={
                        inputValues?.profile_image
                          ? URL.createObjectURL(inputValues?.profile_image)
                          : imgPlaceholder
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {" "}
                  <div>
                    <input
                      style={{ display: "none" }}
                      id="p-img"
                      type="file"
                      onChange={handleImageChange}
                      name="profile_image"
                    />
                    <Button
                      variant="outlined"
                      className="img-upload"
                      onClick={() => {
                        document.getElementById("p-img").click();
                      }}
                    >
                      {languageData?.choose_btn}
                    </Button>
                    <div style={{ position: "relative", maxWidth: "184px" }}>
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        sx={{
                          font: "normal normal bold 13px/18px Poppins",
                          letterSpacing: "0px",
                          color: "#ACACAC",
                          opacity: "1",
                          mt: 2,
                        }}
                      >
                        {inputValues?.profile_image?.name.length > 19
                          ? inputValues?.profile_image?.name.substring(0, 19) +
                            "..."
                          : inputValues?.profile_image?.name}
                      </Typography>
                      <div className="close-button">
                        {inputValues?.profile_image && (
                          <CloseIcon
                            sx={{ color: "#FF7474", height: "13px" }}
                            onClick={() => {
                              var currentInputs = { ...inputValues };
                              currentInputs["profile_image"] = null;
                              setInputValues(currentInputs);
                              putNewError("profile_image", null);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>

                {errors && errors.profile_image && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.profile_image}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="event-detail-top-head"
                sx={{ textAlign: "left" }}
              >
                {languageData?.cover_image}
              </Typography>
              <Grid container spacing={2} sx={{ alignItems: "center", mt: 2 }}>
                <Grid item xs={8} sm={6}>
                  {" "}
                  <div>
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={
                        inputValues?.cover_image
                          ? URL.createObjectURL(inputValues?.cover_image)
                          : imgPlaceholder
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {" "}
                  <div>
                    <input
                      style={{ display: "none" }}
                      id="w-img"
                      type="file"
                      onChange={handleImageChange}
                      name="cover_image"
                    />
                    <Button
                      variant="outlined"
                      className="img-upload"
                      onClick={() => {
                        document.getElementById("w-img").click();
                      }}
                    >
                      {languageData?.choose_btn}
                    </Button>
                    <div style={{ position: "relative", maxWidth: "174px" }}>
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        sx={{
                          font: "normal normal bold 13px/18px Poppins",
                          letterSpacing: "0px",
                          color: "#ACACAC",
                          opacity: "1",
                          mt: 2,
                        }}
                      >
                        {inputValues?.cover_image?.name.length > 19
                          ? inputValues?.cover_image?.name.substring(0, 19) +
                            "..."
                          : inputValues?.cover_image?.name}
                      </Typography>
                      <div className="close-button">
                        {inputValues?.cover_image && (
                          <CloseIcon
                            sx={{ color: "#FF7474", height: "13px" }}
                            onClick={() => {
                              var currentInputs = { ...inputValues };
                              currentInputs["cover_image"] = null;
                              setInputValues(currentInputs);
                              putNewError("cover_image", null);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                {errors && errors.cover_image && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.cover_image}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ py: 2, px: 1 }}>
            {/* <Grid item xs={12} sm={6}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                className="event-detail-top-head"
                sx={{ textAlign: "left" }}
              >
                Cover Image
              </Typography>
              <Grid container spacing={2} sx={{ alignItems: "center", mt: 1 }}>
                <Grid item xs={8} sm={6}>
                  {" "}
                  <div>
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={
                        inputValues?.welcome_image
                          ? URL.createObjectURL(inputValues?.welcome_image)
                          : imgPlaceholder
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {" "}
                  <div>
                    <input
                      style={{ display: "none" }}
                      id="c-img"
                      type="file"
                      onChange={handleImageChange}
                      name="welcome_image"
                    />
                    <Button
                      variant="outlined"
                      className="img-upload"
                      onClick={() => {
                        document.getElementById("c-img").click();
                      }}
                    >
                      Choose File
                    </Button>
                    <div style={{ position: "relative" }}>
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        sx={{
                          font: "normal normal bold 13px/18px Poppins",
                          letterSpacing: "0px",
                          color: "#ACACAC",
                          opacity: "1",
                          mt: 2,
                        }}
                      >
                        {inputValues?.welcome_image?.name}
                      </Typography>
                      <div className="close-button">
                        {inputValues?.welcome_image && (
                          <CloseIcon
                            sx={{ color: "#FF7474", height: "13px" }}
                            onClick={() => {
                              var currentInputs = { ...inputValues };
                              currentInputs["welcome_image"] = null;
                              setInputValues(currentInputs);
                              putNewError("welcome_image", null);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ mt: 8 }} textAlign="left">
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                  sx={{
                    textAlign: "left",
                  }}
                >
                  {languageData?.toggle_public}
                </Typography>
              </Box>
              <Box>
                <Switch
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  label="Private"
                  checked={inputValues?.public}
                  onChange={(e)=>{handleMakeGalleryChange(e)
                  console.log(e.target.checked)}}
                  name="public"
                >
                </Switch>
                
              </Box>
            </Grid>
            {/* <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Public"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Private"
                  />
                </RadioGroup> */}
            {errors && errors.welcome_image && (
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {errors.welcome_image}
              </Typography>
            )}
          </Grid>
          {/* </Box> */}
          {/* )} */}
          {/* {step === 4 && ( */}
          <Box noValidate autoComplete="off" sx={{ p: 0 }}>
            <Divider sx={{ pt: 2 }}></Divider>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {" "}
                <Box sx={{ flexGrow: 1 }}>
                  {/* <form onSubmit={handleOnSubmit}> */}
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    className="event-detail-top-head"
                  >
                    {languageData?.custom_tasks}
                  </Typography>
                  <CreatableSelect
                    isClearable
                    isMulti
                    options={tags}
                    styles={{
                      placeholder: (provided, state) => ({
                        ...provided,
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        color: "#aaaaaa",
                        fontSize: "15px",
                      }),
                    }}
                    placeholder={languageData?.custom_tasks_text}
                    onChange={(value) => handleOnSubmit(value)}
                  />
                  {errors && errors.tasks && (
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      sx={{
                        color: "red",
                        fontSize: "13px",
                        textAlign: "left",
                        paddingLeft: "10px",
                      }}
                    >
                      {errors.tasks}
                    </Typography>
                  )}
                  {/* <TextField
                    // inputRef={tagRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleOnSubmit(e.target.value);
                        e.target.value = "";
                      }
                    }}
                    fullWidth
                    size="medium"
                    sx={{ margin: "1rem 0" }}
                    margin="none"
                    placeholder={
                      tags.length < 5 ? "Enter your personal task here" : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                          {tags.map((data, index) => {
                            return (
                              <Tags
                                data={data}
                                handleDelete={handleDelete}
                                key={index}
                              />
                            );
                          })}
                        </Box>
                      ),
                    }}
                  /> */}
                  {/* </form> */}
                </Box>
                {/* <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  options={tasks}
                  getOptionLabel={(option) => option.name}
                  onChange={(_event, newTask) => {
                    handleTaskChange(newTask);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Tasks" />
                  )}
                /> */}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={category}
                  sx={{
                    maxWidth: 300,
                    // "& .MuiAutocomplete-input": { color: "#aaaaaa" },
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={languageData?.categories}
                      sx={{
                        "& .MuiInputLabel-root": {
                          zIndex: 0,
                        },
                      }}
                    />
                  )}
                  value={selectedCategory}
                  onChange={(e, value) => {
                    if (value.id === "all") {
                      AllTask();
                      setSelectedCategory(value);
                    } else {
                      filterTask(value._id);
                      setSelectedCategory(value);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  {selectedCategory.name}
                </Typography>

                <Card>
                  <Grid
                    item
                    xs={12}
                    className="pinkscroll"
                    sx={{ maxHeight: "500px", overflowY: "auto" }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgb(189 171 168)",
                        display: "flex",
                        // justifyContent: "space-between",
                        margin: "5px 8px",
                        width: "fit-content",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ padding: "5px" }}>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          style={{ backgroundColor: "white" }}
                          onClick={selectAll}
                        >
                          <AddIcon
                            sx={{
                              color: "#FF9885",
                              height: "12px",
                              width: "12px",
                            }}
                          />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          font: "normal normal 600 13px Poppins",
                          letterSpacing: "0px",
                          marginRight: "10px",
                          color: "white",
                          opacity: "1",
                        }}
                      >
                        {languageData?.select_all}
                      </div>
                    </div>
                    {/* {tasks && tasks.length>0 && tasks.map((val)=><span>{val}</span>)} */}
                    {tasks.length &&
                      tasks.map(
                        (val) =>
                          !ids.includes(val._id) && (
                            <div
                              style={{
                                backgroundColor: "#FF9885",
                                display: "flex",
                                alignItems: "center",
                                // borderRadius: "100px",
                                margin: "5px 8px",
                                // width: "fit-content",
                              }}
                            >
                              <div style={{ padding: "5px" }}>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  style={{ backgroundColor: "white" }}
                                  onClick={() => handleTaskChange2(val)}
                                >
                                  <AddIcon
                                    sx={{
                                      color: "#FF9885",
                                      height: "12px",
                                      width: "12px",
                                    }}
                                  />
                                </IconButton>
                              </div>
                              <div
                                style={{
                                  font: "normal normal 600 13px Poppins",
                                  letterSpacing: "0px",
                                  color: "white",
                                  opacity: "1",
                                  marginLeft: "5px",
                                }}
                              >
                                {val.name}
                              </div>
                            </div>
                          )
                      )}
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    className="event-detail-top-head"
                  >
                    {languageData?.selected_tasks}
                  </Typography>
                  <Card sx={{ paddingTop: "3px", paddingBottom: "3px" }}>
                    <Grid
                      container
                      className="pinkscroll"
                      sx={{ maxHeight: "400px", overflow: "auto" }}
                    >
                      {/* {tasks && tasks.length>0 && tasks.map((val)=><span>{val}</span>)} */}
                      {original.length &&
                        original.map(
                          (val) =>
                            ids.includes(val._id) &&
                            val.unCategorized == false && (
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    backgroundColor: "#FF9885",
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent: "space-between",
                                    margin: "2px 8px",
                                  }}
                                >
                                  <div style={{ padding: "6px" }}>
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      style={{ backgroundColor: "white" }}
                                      onClick={() => handleTaskDelete(val._id)}
                                    >
                                      <ClearIcon
                                        sx={{
                                          color: "#FF9885",
                                          height: "12px",
                                          width: "12px",
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                  <div
                                    style={{
                                      font: "normal normal 600 13px Poppins",
                                      letterSpacing: "0px",
                                      color: "white",
                                      marginLeft: "5px",
                                      opacity: "1",
                                    }}
                                  >
                                    {val.name}
                                  </div>
                                </div>
                              </Grid>
                            )
                        )}
                      {!inputValues?.tasks?.length && (
                        <Typography
                          variant="h4"
                          component="div"
                          gutterBottom
                          className="event-detail-top-head"
                          sx={{ p: 2, fontFamily: "PoppinsSemiBold" }}
                        >
                          {languageData?.selected_text}
                        </Typography>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              {/* <Grid item xs={1}>
              {" "}
              <button className="add-task-button">
                <AddIcon sx={{ color: "white" }} />
              </button>
            </Grid> */}
            </Grid>
          </Box>
          {/* <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <IconButton>
                <CancelIcon sx={{ color: "#FF9885", height: "15px" }} />
              </IconButton>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal bold 14px/21px Poppins",
                  letterSpacing: "0px",
                  color: "#272727",
                  opacity: "1",
                  mt: 2,
                }}
              >
                Add Profile image with family
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <IconButton>
                <CancelIcon sx={{ color: "#FF9885", height: "15px" }} />
              </IconButton>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal bold 14px/21px Poppins",
                  letterSpacing: "0px",
                  color: "#272727",
                  opacity: "1",
                  mt: 2,
                }}
              >
                Add Profile image with family
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <IconButton>
                <CancelIcon sx={{ color: "#FF9885", height: "15px" }} />
              </IconButton>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal bold 14px/21px Poppins",
                  letterSpacing: "0px",
                  color: "#272727",
                  opacity: "1",
                  mt: 2,
                }}
              >
                Add Profile image with family
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <IconButton>
                <CancelIcon sx={{ color: "#FF9885", height: "15px" }} />
              </IconButton>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal bold 14px/21px Poppins",
                  letterSpacing: "0px",
                  color: "#272727",
                  opacity: "1",
                  mt: 2,
                }}
              >
                Add Profile image with family
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <IconButton>
                <CancelIcon sx={{ color: "#FF9885", height: "15px" }} />
              </IconButton>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal bold 14px/21px Poppins",
                  letterSpacing: "0px",
                  color: "#272727",
                  opacity: "1",
                  mt: 2,
                }}
              >
                Add Profile image with family
              </Typography>
            </Stack> */}
        </Box>
        {/* )} */}
      </Box>
    </>
  );
}
