import { Signin } from "../pages/Signin";
import Dashboard from "./Dashboard";
import { Signup } from "./Signup";
import { Overview } from "./Overview";
import { Events } from "./Events";
import { CreateEvent } from "./CreateEvent";
import { EventDetails } from "./EventDetail";
import { Tasks } from "./Tasks";
import { Gallery } from "./Gallery";
import { ProfileSettings } from "./ProfileSettings";
import { TaskList } from "./TasksList";
import { HostList } from "./HostList";
import { GuestList } from "./GuestList";
import { CategoryTable } from "../components/Category/category";
import { CategoryList } from "./Category";
import { License } from "./License";
import Packages from "./Packages";
import Terms from "../pages/Terms";
import Verify from "./emailVerfiy";
import NoMatch from "./NoMatch";
import { GalleryView } from "./GalleryView";
import LicenseById from "./LicenseById";
import { EditEvent } from "./EditEvent";
import Greetings from "./Greetings";
import { GreetingsById } from "./GreetingsById";

const routes = [
  { path: "/", component: <Signin />, exact: true },
  {
    path: "/signin",
    component: <Signin />,
    exact: true,
  },

  {
    path: "/signup",
    component: <Signup />,
    exact: true,
  },
  {
    path: "/home",
    component: <Dashboard />,
    exact: true,
    protect: true,
  },
  {
    path: "/host-list",
    component: <HostList />,
    exact: true,
    protect: true,
  },

  {
    path: "/overview",
    component: <Overview />,
    exact: true,
    protect: true,
  },
  {
    path: "/events",
    component: <Events />,
    exact: true,
    protect: true,
  },
  {
    path: "/greetings-details/:id",
    component: <GreetingsById />,
    exact: true,
    protect: true,
  },
  {
    path: "/create-event",
    component: <CreateEvent />,
    exact: true,
    protect: true,
  },
  {
    path: "/event-details/:id",
    component: <EventDetails />,
    exact: true,
    protect: true,
  },
  {
    path: "/task-list",
    component: <Tasks />,
    exact: true,
    protect: true,
  },

  {
    path: "/gallery/:id",
    component: <Gallery />,
    exact: true,
    protect: true,
  },
  {
    path: "/gallery/:id/:isPrivate",
    component: <Gallery />,
    exact: true,
    protect: true,
  },
  {
    path: "/profile-settings",
    component: <ProfileSettings />,
    exact: true,
    protect: true,
  },
  {
    path: "/tasks",
    component: <TaskList isAddTaskAlowed={ false }/>,
    exact: true,
    protect: true,
  },
  {
    path: "/tasks/:id",
    component: <TaskList isAddTaskAlowed={ true }/>,
    exact: true,
    protect: true,
  },
  {
    path: "/all-guest",
    component: <GuestList />,
    exact: true,
    protect: true,
  },
  {
    path: "/package-license/:id",
    component: <LicenseById />,
    exact: true,
    protect: true,
  },
  {
    path: "/all-category",
    component: <CategoryList />,
    exact: true,
    protect: true,
  },
  {
    path: "/greetings/:id",
    component: <Greetings />,
    exact: true,
    protect: true,
  },

  {
    path: "/verify/:token",
    component: <Verify />,
    exact: true,
    protect: true,
  },
  {
    path: "/license",
    component: <License />,
    exact: true,
    protect: true,
  },
  {
    path: "/packages",
    component: <Packages />,
    exact: true,
    protect: true,
  },
  {
    path: "/terms",
    component: <Terms />,
    exact: true,
  },
  {
    path: "/gallery-view/:id",
    component: <GalleryView />,
    exact: true,
  },
  {
    path: "/edit-event/:id",
    component: <EditEvent />,
    exact: true,
  },
  {
    path: "*",
    component: <NoMatch />,
    exact: true,
  },
];

export default routes;
