import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
import {
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TaskTable } from "../components/Tasks/TaskTable";
import { useEffect, useState } from "react";
import { baseURL } from "../config/endpoint";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  getAllGuest,
  getAllHost,
  getAllTaskAdmin,
  getAllTaskUser,
} from "../redux/Tasks/TasksActions";
import { FireSwal } from "../helpers/swalNotification";
import { CurrentRole } from "../helpers/getRole";
import { useLocation, useParams } from "react-router-dom";
import { HostTable } from "../components/Hosts/HostTable";
import { GuestTable } from "../components/Guests/GuestTable";
export function GuestList() {
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const [actionType, setActionType] = useState("add");
  const [createModal, setCreateModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffest] = useState(0);
  const [search, setSearch] = useState("");
  const [type, setDataType] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const { state } = useLocation();
  const check = state?.task?.length > 0 ? true : false;
  const [loading, setLoading] = useState(true);
  const [original, setOriginal] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    label: "",
    _id: "",
    name: "",
  });
  const token = localStorage.getItem("eventAuth");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    axios
      .post(
        `${baseURL}/events/event-guests/`,
        {
          event_id: selectedCategory?._id,
        },
        config
      )
      .then((val) => {
        setTasks(val.data.guests);
        setLoading(false);
      });
  }, [selectedCategory]);

  useEffect(async () => {
    setLoading(true);
    await axios
      .get(`${baseURL}/events/host-events-list/`, config)
      .then((val) => {
        setOriginal(val.data.events);
        setSelectedCategory({
          label: val.data.events[0].general_info.event_name,
          _id: val.data.events[0]._id,
          name: val.data.events[0].general_info.event_name,
        });

        var temp = [];
        val.data.events.map((item) =>
          temp.push({ ...item, label: item.general_info.event_name })
        );
        setCategory(temp);
      });
  }, []);

  var setData = (data) => {
    // if (data.status) {
    //   setTasks(
    //     check
    //       ? data.guest.filter((val) => state.task.includes(val._id))
    //       : data.guest
    //   );
    //   setTotalRecords(data.guest.length);
    //   setLoading(false);
    // } else {
    //   FireSwal("error", "Oops..", data.message, false, false);
    //   setLoading(false);
    // }
  };
  // useEffect(() => {
  //   setLoading(true);
  //   if (CurrentRole() === "admin") {
  //     dispatch(getAllGuest({ limit, offset, search, type }, setData));
  //   } else {
  //     dispatch(getAllGuest({ limit, offset, search, type }, setData));
  //   }
  // }, [limit, offset, search]);
  var refreshList = () => {
    if (CurrentRole() === "admin") {
      dispatch(getAllGuest({ limit, offset, search, type }, setData));
    } else {
      dispatch(getAllGuest({ limit, offset, search, type }, setData));
    }
  };
  var setType = (status) => {
    setDataType(status);
    if (CurrentRole() === "admin") {
      dispatch(
        getAllGuest(
          { limit: "10", offset: "0", search: "", type: status },
          setData
        )
      );
    } else {
      dispatch(
        getAllHost(
          { limit: "10", offset: "0", search: "", type: status },
          setData
        )
      );
    }
  };
  const buttons = [
    // <Button
    //   key="all"
    //   className={type === 0 ? "active" : ""}
    //   onClick={() => setType(0)}
    // >
    //   All Tasks
    // </Button>,
  ];

  return (
    <Dashboard>
      <Container>
        <Card className="events-card" sx={{ mt: 2 }}>
          <Box sx={{ display: "block", p: 3 }}>
            <div className="d-flex justify-content-between align-items-center">
              <ButtonGroup size="small" className="event-tab-buttons">
                {buttons}
              </ButtonGroup>
            </div>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={category}
              sx={{
                maxWidth: 300,
                // "& .MuiAutocomplete-input": { color: "#aaaaaa" },
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Select Event"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      zIndex: 0,
                    },
                  }}
                />
              )}
              value={selectedCategory}
              onChange={(e, value) => {
                setSelectedCategory(value);
              }}
            />
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  sx={{ textAlign: "center", color: "#FF9885" }}
                />
              </div>
            ) : (
              <Box sx={{ mt: 2 }}>
                <GuestTable
                  tasks={tasks}
                  refreshList={refreshList}
                  createModal={createModal}
                  setCreateModal={setCreateModal}
                  setSelectedItem={setSelected}
                  selectedItem={selected}
                  actionType={actionType}
                  setActionType={setActionType}
                  limit={limit}
                  setLimit={setLimit}
                  offset={offset}
                  setOffest={setOffest}
                  setSearch={setSearch}
                  totalRecords={totalRecords}
                />
              </Box>
            )}
          </Box>
        </Card>
      </Container>
    </Dashboard>
  );
}
