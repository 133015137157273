export const baseURL = "https://backend.justparty.app/api/v1";
export const imageURL = "https://backend.justparty.app/";
export const webUrl = "https://panel.justparty.app";

//export const baseURL = "http://192.168.1.57:3001/api/v1";
// export const imageURL = "http://localhost:3001/";
//export const webUrl = "http://192.168.1.57:3010";

// export const baseURL = "http://192.168.100.26:3002/api/v1";
// export const imageURL = "http://192.168.100.26:3002/";
// export const webUrl = "http://localhost:3010";
