import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import { validateEmail } from "../../helpers/emailValidation";
import { updateUserProfile } from "../../redux/Profile/ProfileActions";
export function EditProfile({ open, handleClose, callBack, selected }) {
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    bgcolor: "white",
    boxShadow: 24,
    border: "none",
    p: 4,
    position: "relative",
  };
  useEffect(() => {
    if (selected) {
      setInputValues({
        name: selected.username,
        email: selected.email,
        id: selected._id,
      });
    }
  }, [selected]);
  var handleChange = ({ currentTarget: input }) => {
   
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var submitForm = (e) => {
    e.preventDefault();
    if (!inputValues.name || inputValues.name.length < 1) {
      var _errors = { ...errors };
      _errors.name = "Please provide user name!";
      setErrors(_errors);
      return;
    }
    if (
      !inputValues.email ||
      inputValues.email.length === 0 ||
      !validateEmail(inputValues.email)
    ) {
      var _errors = { ...errors };
      _errors.email = "Provide a valid email address";
      setErrors(_errors);
      return;
    }
    setLoader(true);

    dispatch(updateUserProfile(inputValues, stopLoader));
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      handleClose(false);
      setLoader(false);
      FireSwal("success", "Prodile updated!", "", false, false);
      callBack();
      setTimeout(() => {
        Swal.close();
      }, 2000);
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } else {
      handleClose(false);
      FireSwal("error", "Oops..", resp.message, false, true);
    }
    setLoader(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 20 }}
          onClick={() => handleClose(!open)}
        >
          <CloseIcon sx={{ color: "#black", height: "20px" }} />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "#752525" }}
        >
          Edit Profile
        </Typography>
        <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
          <InputLabel
            htmlFor="filled-adornment-email"
            className="signin-email-label"
          >
            Name
          </InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type="text"
            className="p"
            onChange={handleChange}
            value={inputValues?.name}
            name="name"
          />
          {errors && errors.name && (
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                color: "red",
                fontSize: "13px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {errors.name}
            </Typography>
          )}
        </FormControl>
        <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
          <InputLabel
            htmlFor="filled-adornment-email"
            className="signin-email-label"
          >
            Email
          </InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type="text"
            className="p"
            value={inputValues?.email}
            onChange={handleChange}
            name="email"
          />
          {errors && errors.email && (
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                color: "red",
                fontSize: "13px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {errors.email}
            </Typography>
          )}
        </FormControl>

        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            className="button-signin"
            disabled={loader}
            sx={{ px: 2 }}
            onClick={submitForm}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
