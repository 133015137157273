import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Logo from "../../assets/images/jstprty_logo.png";
import Typography from "@mui/material/Typography";
export const DashCard = ({ title, count, bg }) => {
  return (
    <Card sx={{ background: bg, height: "100%" }}>
      <CardContent
        sx={{
          height: "8rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            font: "normal normal normal 15px/23px Poppins",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1",
          }}
          component="div"
          gutterBottom
        >
          {title}
        </Typography>

        <Typography
          component="div"
          sx={{
            textAlign: "center",
            font: "normal normal bold 27px/40px PoppinsBold",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: "1",
          }}
        >
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};
