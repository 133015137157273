import React, { useState } from "react";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
// import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
// import fbIcon from "../assets/svg/facebook-icon.svg";
// import googleIcon from "../assets/svg/google-icon.svg";
import { useDispatch } from "react-redux";
import { validateEmail } from "../helpers/emailValidation";
import { signUp } from "../redux/Login/LoginActions";
import { FireSwal } from "../helpers/swalNotification";
export function Signup() {
  const [inputValues, setInputValues] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [terms, setTerms] = useState(false);
  const [GDRP, setGDRP] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var handleChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var submitForm = (e) => {
    e.preventDefault();
    if (!inputValues.username || inputValues.username.length === 0) {
      var _errors = { ...errors };
      _errors.username = "Provide a valid username";
      setErrors(_errors);
      return;
    }
    if (
      !inputValues.email ||
      inputValues.email.length === 0 ||
      !validateEmail(inputValues.email)
    ) {
      var _errors = { ...errors };
      _errors.email = "Provide a valid email address";
      setErrors(_errors);
      return;
    }
    if (!inputValues.password || inputValues.password.length < 8) {
      var _errors = { ...errors };
      _errors.password = "Please provide a password minimum 8 character long";
      setErrors(_errors);
      return;
    }
    if (inputValues.password != inputValues.confirm_password) {
      var _errors = { ...errors };
      _errors.confirm_password = "Password doesn't match!";
      setErrors(_errors);
      return;
    }
    setLoader(true);
    dispatch(signUp(inputValues, stopLoader));
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      FireSwal("success", "Registration Completed", "", false, false);
      setTimeout(() => {
        Swal.close();
        navigate("/", { replace: true });
      }, 2000);
    } else {
      FireSwal("error", "Oops..", resp.message, false, true);
    }
    setLoader(false);
  };
  var handleTerms = (e) => {
    setTerms(e.target.checked);
  };
  var handleGDRP = (e) => {
    setGDRP(e.target.checked);
  };
  return (
    <Box sx={{ flexGrow: 1, position: "relative" }}>
      <Grid container spacing={2} className="home">
        <Grid
          item
          xl={6}
          lg={6}
          md={4}
          sm={4}
          display={{ xs: "none", lg: "block", xs: "none", md: "block" }}
          className="signin-img"
        ></Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={8}
          sm={8}
          display={{ xs: "block", lg: "block", sm: "block" }}
          className="signin-form"
        >
          <Container maxWidth="sm" className="form-container">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="form-title"
            >
              JSTPRTY
            </Typography>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              className="welcome-title"
            >
              Welcome to JSTPRTY
            </Typography>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              className="welcome-title-desc"
            >
              Now you can celebrate you event with your family and share
              memories.
            </Typography>
            <Box className="form-fields" sx={{ pl: 10, pr: 10 }}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
                <InputLabel
                  htmlFor="filled-adornment-email"
                  className="signup-email-label"
                >
                  Full Name
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type="text"
                  className="signup-input"
                  name="username"
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon>
                        <PersonIcon className="signup-input-icon" />
                      </Icon>
                    </InputAdornment>
                  }
                />
                {errors && errors.username && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.username}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
                <InputLabel
                  htmlFor="filled-adornment-email"
                  className="signup-email-label"
                >
                  Email
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type="text"
                  className="signup-input"
                  name="email"
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon>
                        <EmailIcon className="signup-input-icon" />
                      </Icon>
                    </InputAdornment>
                  }
                />
                {errors && errors.email && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.email}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
                <InputLabel
                  htmlFor="filled-adornment-email"
                  className="signup-email-label"
                >
                  Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={!passwordVisible ? "password" : "text"}
                  name="password"
                  onChange={handleChange}
                  className="signup-input"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        className="cursor-password"
                      >
                        {!passwordVisible ? (
                          <VisibilityOff className="signin-input-icon" />
                        ) : (
                          <Visibility />
                        )}
                      </Icon>
                    </InputAdornment>
                  }
                />
                {errors && errors.password && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.password}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
                <InputLabel
                  htmlFor="filled-adornment-email"
                  className="signup-email-label"
                >
                  Confirm Password
                </InputLabel>
                <FilledInput
                  id="filled-adornment-password"
                  type={!confirmPasswordVisible ? "password" : "text"}
                  name="confirm_password"
                  onChange={handleChange}
                  className="signup-input"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon
                        onClick={() =>
                          setConfirmPasswordVisible(!confirmPasswordVisible)
                        }
                        className="cursor-password"
                      >
                        {!confirmPasswordVisible ? (
                          <VisibilityOff className="signin-input-icon" />
                        ) : (
                          <Visibility />
                        )}
                      </Icon>
                    </InputAdornment>
                  }
                />
                {errors && errors.confirm_password && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.confirm_password}
                  </Typography>
                )}
              </FormControl>

              <Box sx={{ pt: 1, pr: 0, pl: 1 }}>
                <div className="d-flex justify-content-between align-items-center">
                  <Grid>
                    <div
                      className="d-flex align-items-center"
                      style={{ pt: 2 }}
                    >
                      <div style={{ pt: 2 }}>
                        <label className="terms-label">
                          <input
                            type="checkbox"
                            onChange={(e) => handleTerms(e)}
                            style={{
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </label>
                      </div>
                      <div style={{ fontSize: "15px", pt: 2 }}>
                        I agree{" "}
                        <Link
                          to="/terms"
                          className="td-none"
                          style={{ color: "#ff9885" }}
                        >
                          Terms & Conditions
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <label className="terms-label">
                          <input
                            type="checkbox"
                            onChange={(e) => handleGDRP(e)}
                            style={{
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </label>
                      </div>
                      <div style={{ fontSize: "15px" }}>
                        I agree{" "}
                        <a
                          href={"https://gdpr.eu/terms-and-conditions"}
                          className="td-none"
                          target={"_blanK"}
                          style={{ color: "#ff9885" }}
                        >
                          GDRP Terms & Conditions
                        </a>
                      </div>
                    </div>
                  </Grid>

                  <div>
                    <Button
                      variant="contained"
                      className="button-signup"
                      disabled={!terms || !GDRP}
                      onClick={submitForm}
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
              </Box>

              <Box className="form-bottom-link" sx={{ pt: 3 }}>
                <div>
                  Already Have Account?{" "}
                  <span>
                    <Link to="/signin" className="signin-bottom-link">
                      Sign In
                    </Link>
                  </span>
                </div>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
      {/* <Box
        sx={{ 
          position: "absolute",
          left: "0",
          right: "0",
          marginLeft: "auto",
          marginRight: "auto",
          width: "90px",
          bottom: 0,
        }}
      >
        <IconButton>
          <img src={fbIcon} style={{ height: "60px" }} />
        </IconButton>
        <IconButton>
          <img src={googleIcon} style={{ height: "60px" }} />
        </IconButton>
      </Box> */}
    </Box>
  );
}
