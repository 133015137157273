import { Box, Card, CircularProgress, Container, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Dashboard from "./Dashboard";

import AddIcon from "@mui/icons-material/Add";
import { PackagesTable } from "../components/Packages/packages";
import axios from "axios";
import { baseURL } from "../config/endpoint";
const Packages = () => {
    const [tasks, setTasks] = useState([]);
    const [actionType, setActionType] = useState("add");
    const [createModal, setCreateModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const [limit, setLimit] = useState(10);
    const [offset, setOffest] = useState(0);
    const [search, setSearch] = useState("");
    const [type, setDataType] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const { state } = useLocation();
    const [loading,setLoading]= useState(true);
    const [selectedLanguage, setSelectedLanguage] = React.useState(
      localStorage.getItem("language")
    );
    const [languageData, seLanguageData] = React.useState(
      JSON.parse(localStorage.getItem(`${selectedLanguage}`))?.packages
    );
    const check = state?.task?.length > 0 ? true : false;
    React.useEffect(()=>{
      setLoading(true);
        axios
        .get(`${baseURL}/packages/`
        )
        .then((val) => {
            setTasks(val.data.packages);
            setLoading(false);
        });
      },[])
      var refreshList = () => {
          axios.get(`${baseURL}/packages`).then((val) => {
            setTasks(val.data.packages);
            setLoading(false);
          });
      };
  return (
    <>
      <Dashboard>
        <Container>
          <Card className="events-card" sx={{ mt: 2 }}>
          <Box sx={{ borderBottom: "2px solid #EBEBEB ", display: "block" }}>
            <div
              className="d-flex align-items-center events-add-link justify-content-center"
              style={{ maxWidth:'230px', padding: "10px 0px" }}
              onClick={() => {setCreateModal(!createModal)
              setActionType('add')
              }}
            >
              <AddIcon sx={{ color: "white" }} />
              <Typography
                component="div"
                variant="h5"
                sx={{
                  font: "normal normal normal 15px/23px Poppins",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: "1",
                }}

              >
                {languageData?.package_add}
              </Typography>
            </div>
          </Box>
          <Box sx={{ display: "block", p: 3 }}>
            <div className="d-flex justify-content-between align-items-center"></div>

            {loading?<div style={{textAlign:"center"}}><CircularProgress  sx={{textAlign:"center",color:"#FF9885"}} /></div>: <Box sx={{ mt: 2 }}>
              <PackagesTable
                tasks={tasks}
                refreshList={refreshList}
                setTasks={setTasks}
                createModal={createModal}
                setCreateModal={setCreateModal}
                setSelectedItem={setSelected}
                selectedItem={selected}
                actionType={actionType}
                setActionType={setActionType}
                limit={limit}
                setLimit={setLimit}
                offset={offset}
                setOffest={setOffest}
                setSearch={setSearch}
                totalRecords={totalRecords}
              />
            </Box>}
          </Box>
          </Card>
        </Container>
      </Dashboard>{" "}
    </>
  );
};

export default Packages;
