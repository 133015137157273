import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { CreatePackages } from "../../modals/Packages/createPackages";
import axios from "axios";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import { baseURL } from "../../config/endpoint";
import { Grid, Stack } from "@mui/material";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "600px",
  maxHeight: "70vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
export function PackagesTable({
  tasks,
  setTasks,
  refreshList,
  createModal,
  setCreateModal,
  selectedItem,
  setSelectedItem,
  actionType,
  setActionType,
  limit,
  setLimit,
  offset,
  setOffest,
  search,
  setSearch,
  totalRecords,
}) {
  const [order, setOrder] = React.useState("asc");
  const [status, setStatus] = React.useState(true);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [data, setData] = React.useState({});
  const [licences, setLicenses] = React.useState([]);
  const [showLicense, setshowLicense] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (tasks) {
      setRows(tasks);
    }
  }, [tasks]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  var stopLoader = (resp, id) => {
    if (resp.status) {
      FireSwal("success", "Deleted", false, false);
      setRows(rows.filter((row) => row._id !== id));
      setTimeout(() => {
        Swal.close();
      }, 1000);
    } else {
      FireSwal("error", "Oops..", resp.message, false, true);
    }
  };
  const handleDelete = (id) => {
    FireSwal(
      "Warning",
      "Warning",

      languageData?.delete_check,

      true,
      true,
      languageData?.delete_yes,
      languageData?.delete_cancel
    ).then(async (val) => {
      if (val.isConfirmed) {
        axios.post(`${baseURL}/packages/` + id).then((val) => {
          stopLoader(val.data, id);
        });
      }
    });
  };
  const handleView = (val) => {
    axios.get(`${baseURL}/packages/licences-by-pkg-id/` + val).then((val) => {
      handleOpenModal();
      setLicenses(val?.data);
      setshowLicense(val?.data?.licenses);
    });
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const selectedLanguage = localStorage.getItem("language");

  const languageData = JSON.parse(
    localStorage.getItem(`${selectedLanguage}`)
  )?.packages;
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const headCells = [
      {
        id: "Title",
        numeric: true,
        disablePadding: false,
        label: languageData?.title,
      },
      {
        id: "Media",
        numeric: true,
        disablePadding: false,
        label: languageData?.media_allowed,
      },
      {
        id: "max",
        numeric: true,
        disablePadding: false,
        label: languageData?.max_upload_media,
      },
      {
        id: "max Guests",
        numeric: true,
        disablePadding: false,
        label: languageData?.max_guests,
      },
      {
        id: "Download allowed",
        numeric: true,
        disablePadding: false,
        label: languageData?.download_allowed,
      },
      {
        id: "Download for unregistered guests",
        numeric: true,
        disablePadding: false,
        label: languageData?.d_unreg_guests,
      },

      {
        id: "Show Gallery",
        numeric: true,
        disablePadding: false,
        label: languageData?.show_gallery,
      },

      {
        id: "duration",
        numeric: true,
        disablePadding: false,
        label: languageData?.duration,
      },
      {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: languageData?.actions,
      },
    ];
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, overflowX: "auto" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            className="task-table"
          >
            <EnhancedTableHead
              languageData={languageData}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
           rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * 20, 20)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.email);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={index}>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="left">{row.mediaTypeallowed}</TableCell>
                      <TableCell align="left">{row.maxMedia}</TableCell>
                      <TableCell align="left">{row.maxGuest}</TableCell>
                      <TableCell align="left">{row.downloadAllowed}</TableCell>
                      <TableCell align="left">
                        {row.unregisteredGuest}
                      </TableCell>
                      <TableCell align="left">
                        {row?.accesstodownloads}
                      </TableCell>
                      <TableCell align="left">{row?.duration}</TableCell>
                      <TableCell align="left">
                        <div className="d-flex justify-content-center action-buttons">
                          {/* <button className="view">
                            <VisibilityIcon
                              sx={{ color: "white", height: "18px" }}
                              onClick={() => navigate("/event-details")}
                            />
                          </button> */}

                          <>
                            <button
                              style={{ backgroundColor: "red" }}
                              className="edit"
                              onClick={() => {
                                handleDelete(row._id);
                              }}
                            >
                              <DeleteIcon
                                sx={{ color: "white", height: "18px" }}
                              />
                            </button>
                            <button
                              className="edit"
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                setCreateModal(!createModal);
                                setActionType("edit");
                                setData(row);
                              }}
                            >
                              <EditIcon
                                sx={{ color: "white", height: "18px" }}
                              />
                            </button>
                            <button
                              style={{ marginLeft: "10px" }}
                              className="view"
                              onClick={() => {
                                handleView(row._id);
                              }}
                            >
                              <RemoveRedEyeOutlinedIcon
                                sx={{ color: "white", height: "18px" }}
                              />
                            </button>
                          </>

                          <Box sx={{ px: 2 }}></Box>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <div style={{ float: "right", marginBottom: "15px" }}>
        <Pagination
          numOfPages={
            Math.ceil(totalRecords / limit) > 1
              ? Math.ceil(totalRecords / limit)
              : 1
          }
          offset={offset}
          setOffest={setOffest}
        />
      </div> */}
      <CreatePackages
        languageData={languageData}
        rows={rows}
        setRows={setRows}
        open={createModal}
        handleClose={setCreateModal}
        callBack={refreshList}
        data={data}
        selected={selectedItem}
        actionType={actionType}
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="pinkscroll" textAlign="center">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              sx={{ color: "#000000", pb: 2 }}
            >
              {languageData?.package_licenses}
            </Typography>
            <Box sx={{ pt: 0 }}>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "white",
                  p: 0,
                  marginRight: -3,
                }}
                onClick={() => {
                  setOpenModal(false);
                  setStatus(true);
                }}
              >
                X
              </Button>
            </Box>
          </Box>
          <Stack direction="row" alignItems="center" spacing={0}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={0}
              sx={{ p: 1 }}
            >
              <Button
                variant="contained"
                className={status ? `default-button` : " default-button2"}
                sx={{ color: "white", mr: 1 }}
                onClick={() => {
                  setStatus(true);
                  setshowLicense(licences?.licenses);
                }}
              >
                Used
              </Button>
              <Button
                variant="contained"
                className={!status ? `default-button` : "default-button2 "}
                sx={{ color: "white", mr: 1 }}
                onClick={() => {
                  setStatus(false);
                  setshowLicense(licences?.unUsedLicenses);
                }}
                t
              >
                Not used
              </Button>
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderBottomWidth: "2px",
              borderBottomColor: "black",
              marginTop: 2,
            }}
          />
          {showLicense.length > 0 ? (
            showLicense.map((item) => (
              <>
                <Grid container>
                  {status ? (
                    <Grid
                      item
                      sm={6}
                      lg={5.3}
                      spacing={2}
                      // justifyContent="center"
                    >
                      <Typography
                        variant="body2"
                        textAlign="left"
                        sx={{ color: "#000000", p: 2 }}
                      >
                        {languageData?.event_name}:{" "}
                        {item?.event_info?.general_info?.event_name}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid
                    item
                    sm={6}
                    lg={5.3}
                    spacing={2}
                    // justifyContent="center"
                  >
                    <Typography
                      variant="body2"
                      textAlign="left"
                      sx={{ p: 2, color: "#ff9885" }}
                    >
                      {languageData?.license_number}: {item?.license_number}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    borderBottomWidth: "2px",
                    borderBottomColor: "black",
                  }}
                />
              </>
            ))
          ) : (
            <Typography sx={{ py: 2 }} variant="h6">
              {"No data Found"}{" "}
            </Typography>
          )}
          <Divider
            sx={{ borderBottomWidth: "2px", borderBottomColor: "black" }}
          />
        </Box>
      </Modal>
    </Box>
  );
}
