import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL, imageURL } from "../config/endpoint";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Modal, InputAdornment, Box, Tooltip, Card } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
// import Card from "@mui/material/Card";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
export function GalleryView() {
  var { id } = useParams();
  var [gallery, setGallery] = useState([]);
  var [eventName, setEventName] = useState("");
  const [open, setOpen] = useState(false);
  const style = {
    pt: 2,
    px: 4,
    pb: 3,
    maxWidth: "800px",
    maxHeight: "90vh",
    borderRadius: 10,
    objectFit: "contain",
    width: "100%",
  };
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    getGallery();
  }, []);
  var getGallery = async () => {
    var { data } = await axios.post(`${baseURL}/gallery/event-media-web`, {
      event_id: id,
    });
    setGallery(data.data);
    setEventName(data.event?.general_info.event_name);
    console.log(data.data, "gellry123");
  };
  const copyToClipboardLink = (e) => {
    navigator.clipboard.writeText(window.location.toString());
    {
    }
  };

  return (
    <>
      {" "}
      {/* <div style={{ height: "40vh" }}> */}
      <Box sx={{ px: 0 }} width="100%">
        {" "}
        <Card
          elevation={6}
          sx={{
            backgroundColor: "#ff9885",

            p: 1,
            height: "10vh",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" sx={{ color: "white" }}>
            {" "}
            JUST PARTY
          </Typography>
        </Card>
      </Box>
      {/* </div> */}
      <Container maxWidth="lg">
        <Box sx={{ p: 2 }} textAlign="center">
          <Grid container>
            <Grid item lg={3} md={3} sm={3}></Grid>
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: "center",
                  py: 2,
                  color: "#ff9885",
                  fontFamily: "poppinsSemiBold",
                }}
              >
                {eventName}
              </Typography>
            </Grid>
            <Grid item lg={3}></Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} sx={{ py: 2 }}>
          {gallery.map((item, index) =>
            item.type.includes("mp4") ? (
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                key={index}
                sx={{ position: "relative", lineHeight: 0 }}
              >
                <video
                  autoPlay
                  controls
                  src={item.image}
                  style={{
                    height: "300px",
                    width: "100%",
                    objectFit: "contain",
                    borderRadius: "9px 9px 0px 0px",
                    cursor: "pointer",
                  }}
                  play
                />
                <Card
                  sx={{
                    borderRadius: "0",
                    backgroundColor: "#FEF3F1",
                    color: "black",
                    p: "10px",
                    minWidth: "100px",
                    maxWidth: "100%",
                    textAlign: "center",
                    lineHeight: "1.5",
                    borderRadius: " 0px 0px 9px 9px",
                    minHeight: "50px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1" sx={{}}>
                    {item?.task?.name ? item?.task?.name : item?.event_name}
                  </Typography>
                </Card>
              </Grid>
            ) : (
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                key={index}
                sx={{ position: "relative", lineHeight: 0 }}
                justifyContent="center"
                textAlign="center"
              >
                <img
                  src={item.image}
                  style={{
                    // height: "400px",
                    // width: "100%"
                    height: "300px",
                    width: "100%",
                    objectFit: "contain",
                    borderRadius: "9px 9px 0px 0px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpen(!open);
                    setImageURL(item.image);
                  }}
                />
                {/* </Card> */}
                <Card
                  sx={{
                    borderRadius: "0",
                    backgroundColor: "#FEF3F1",
                    color: "black",
                    p: "10px",
                    minWidth: "100px",
                    maxWidth: "100%",
                    textAlign: "center",
                    lineHeight: "1.5",
                    borderRadius: " 0px 0px 9px 9px",
                    minHeight: "50px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1" sx={{}}>
                    {item?.task?.name ? item?.task?.name : item?.event_name}
                  </Typography>
                </Card>
              </Grid>
            )
          )}
        </Grid>
        <Modal
          open={open}
          style={{ objectFit: "unset" }}
          onClose={() => setOpen(false)}
        >
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ position: "relative", marginX: 2 }}>
              <Box
                sx={{
                  padding: -10,
                  position: "absolute",
                  color: "white",
                  right: 0,
                  margin: 2,
                  width: 30,
                  height: 30,
                  background: "#ff9885",
                  borderRadius: 1,
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(false)}
              >
                <Typography sx={{ marginTop: 0.5 }}>X</Typography>
              </Box>
              <img style={style} src={imageURL} />
            </Box>
          </Box>
        </Modal>
      </Container>
    </>
  );
}
