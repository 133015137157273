import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  createNewTask,
  createNewTaskForCategory,
  createNewTaskForCustom,
  createNewTaskForEvent,
  updateTaskStatus,
} from "../../redux/Tasks/TasksActions";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { MenuItem, Select } from "@mui/material";
import { baseURL } from "../../config/endpoint";
export function CreateTask({
  languageData,
  open,
  handleClose,
  callBack,
  selected,
  actionType,
  name,
  event_id,
  category_id,
  type,
}) {
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({ name: name });
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [category, setCategory] = React.useState([]);
  const [cat, setCate] = React.useState("");
  console.log(
    "---------------- ************************ --------------------------",
    inputValues
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    bgcolor: "white",
    boxShadow: 24,
    border: "none",
    p: 4,
    mr: 2,
    position: "relative",
  };
  useEffect(() => {
    // if (CurrentRole() === "admin") {
    //   dispatch(getAllHost({ limit, offset, search, type }, setData));
    // } else {
    //   dispatch(getAllHost({ limit, offset, search, type }, setData));
    // }
    axios.get(`${baseURL}/category`).then((val) => {
      setCategory(val.data.category);
    });
  }, []);
  useEffect(() => {
    if (selected) {
      setInputValues(selected);
    }
  }, [selected]);
  var handleChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  console.log(event_id, category_id, type);
  var submitForm = (e) => {
    e.preventDefault();

    if (actionType === "add") {
      if (!inputValues.name || inputValues.name.length < 1) {
        var _errors = { ...errors };
        _errors.name = "Please provide a task name!";
        setErrors(_errors);
        return;
      }
      setLoader(true);
      if (type === 0) {
        dispatch(
          createNewTaskForEvent(
            { ...inputValues, eventId: event_id },
            stopLoader
          )
        );
      } else if (type === 1) {
        dispatch(
          createNewTaskForCategory(
            { ...inputValues, categoryID: category_id },
            stopLoader
          )
        );
      } else if (type === 2) {
        dispatch(createNewTaskForCustom({ ...inputValues }, stopLoader));
      }
    } else {
      if (!inputValues.status || inputValues.status.length < 1) {
        var _errors = { ...errors };
        _errors.status = "Please select a status to update!";
        setErrors(_errors);
        return;
      }
      setLoader(true);

      dispatch(
        updateTaskStatus(
          { task_id: inputValues._id, categoryId: cat, name: inputValues.name },
          stopLoader
        )
      );
    }
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      handleClose(false);
      FireSwal(
        "success",
        actionType === "add"
          ? "Task Created Successfully!"
          : "Task status updated!",
        "",
        false,
        false
      );
      callBack();
      setTimeout(() => {
        Swal.close();
      }, 2000);
    } else {
      handleClose(false);
      FireSwal("error", "Oops..", resp.message, false, true);
    }
    setLoader(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 20 }}
          onClick={() => handleClose(!open)}
        >
          <CloseIcon sx={{ color: "#black", height: "20px" }} />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "#752525" }}
        >
          {actionType === "add" ? languageData?.add_task : "Edit Task"}
        </Typography>
        {actionType === "add" ? (
          <FormControl sx={{ mt: 1, width: "100%" }} variant="filled">
            <TextField
              sx={{ width: "100%" }}
              id="filled-adornment-password"
              type="text"
              className="p"
              onChange={handleChange}
              label={languageData?.task_name}
              name="name"
            />
            {errors && errors.name && (
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {errors.name}
              </Typography>
            )}
          </FormControl>
        ) : (
          <FormControl sx={{ mt: 1, width: "100%" }}>
            <FormLabel id="demo-radio-buttons-group-label">
              {languageData?.edit_button}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="status"
            >
              <Select
                sx={{ maxWidth: "230px" }}
                onChange={(e) => {
                  setCate(e.target.value);
                }}
                name="category"
                value={cat}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {category.map((val) => (
                  <MenuItem value={val._id}>
                    <em>{val.name}</em>
                  </MenuItem>
                ))}
              </Select>
              <FormControl sx={{ mt: 1, width: "100%" }} variant="filled">
                <TextField
                  sx={{ width: "100%" }}
                  className="p"
                  id="filled-adornment-password"
                  type="text"
                  onChange={handleChange}
                  value={inputValues.name}
                  label={languageData?.task_name}
                  name="name"
                />
                {errors && errors.name && (
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{
                      color: "red",
                      fontSize: "13px",
                      textAlign: "left",
                      paddingLeft: "10px",
                    }}
                  >
                    {errors.name}
                  </Typography>
                )}
              </FormControl>
              {/* <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              /> */}
            </RadioGroup>
            {errors && errors.status && (
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {errors.status}
              </Typography>
            )}
          </FormControl>
        )}

        <Box sx={{ p: 0 }}>
          <Button
            variant="contained"
            className="button-signin"
            disabled={loader}
            sx={{ my: 2, py: 0, maxWidth: "110px" }}
            onClick={submitForm}
          >
            {actionType === "add" ? languageData?.add_button : "Edit"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
