import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import { validateEmail } from "../../helpers/emailValidation";
import { updateUserPassword } from "../../redux/Profile/ProfileActions";
export function NewPassword({
  open,
  handleClose,
  callBack,
  selected,
  actionType,
}) {
  const [errors, setErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [loader, setLoader] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    bgcolor: "white",
    boxShadow: 24,
    border: "none",
    p: 4,
    position: "relative",
  };
  useEffect(() => {
    if (selected) {
      setInputValues(selected);
    }
  }, [selected]);
  var handleChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var submitForm = (e) => {
    e.preventDefault();

    if (!inputValues.old_password || inputValues.old_password.length < 8) {
      var _errors = { ...errors };
      _errors.old_password =
        "Please provide password with minimum length of 8 characters!";
      setErrors(_errors);
      return;
    }
    if (!inputValues.new_password || inputValues.new_password.length < 8) {
      var _errors = { ...errors };
      _errors.new_password =
        "Please provide password with minimum length of 8 characters!";
      setErrors(_errors);
      return;
    }
    if (
      !inputValues.confirm_password ||
      inputValues.confirm_password.length < 8
    ) {
      var _errors = { ...errors };
      _errors.confirm_password =
        "Please provide confirm password with minimum length of 8 characters!";
      setErrors(_errors);
      return;
    }
    if (inputValues.new_password !== inputValues.confirm_password) {
      var _errors = { ...errors };
      _errors.confirm_password = "Your new password doesn't match!";
      setErrors(_errors);
      return;
    }
    setLoader(true);
    dispatch(updateUserPassword(inputValues, stopLoader));
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      handleClose(false);
      FireSwal("success", "Prodile updated!", "", false, false);
      callBack();
      setTimeout(() => {
        Swal.close();
      }, 2000);
    } else {
      handleClose(false);
      FireSwal("error", "Oops..", resp.message, false, true);
    }
    setLoader(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 20 }}
          onClick={() => handleClose(!open)}
        >
          <CloseIcon sx={{ color: "#black", height: "20px" }} />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "#752525" }}
        >
          Edit Password
        </Typography>
        <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
          <InputLabel
            htmlFor="filled-adornment-email"
            className="signup-email-label"
          >
            Old Password
          </InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={!oldPassword ? "password" : "text"}
            name="old_password"
            onChange={handleChange}
            className="p"
            endAdornment={
              <InputAdornment position="end">
                <Icon
                  onClick={() => setOldPassword(!oldPassword)}
                  className="cursor-password"
                >
                  {!oldPassword ? (
                    <VisibilityOff className="signin-input-icon" />
                  ) : (
                    <Visibility />
                  )}
                </Icon>
              </InputAdornment>
            }
          />
          {errors && errors.old_password && (
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                color: "red",
                fontSize: "13px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {errors.old_password}
            </Typography>
          )}
        </FormControl>
        <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
          <InputLabel
            htmlFor="filled-adornment-email"
            className="signup-email-label"
          >
            New Password
          </InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={!passwordVisible ? "password" : "text"}
            name="new_password"
            onChange={handleChange}
            className="p"
            endAdornment={
              <InputAdornment position="end">
                <Icon
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className="cursor-password"
                >
                  {!passwordVisible ? (
                    <VisibilityOff className="signin-input-icon" />
                  ) : (
                    <Visibility />
                  )}
                </Icon>
              </InputAdornment>
            }
          />
          {errors && errors.new_password && (
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                color: "red",
                fontSize: "13px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {errors.new_password}
            </Typography>
          )}
        </FormControl>
        <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
          <InputLabel
            htmlFor="filled-adornment-email"
            className="signup-email-label"
          >
            Confirm Password
          </InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={!confirmPasswordVisible ? "password" : "text"}
            name="confirm_password"
            onChange={handleChange}
            className="p"
            endAdornment={
              <InputAdornment position="end">
                <Icon
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                  className="cursor-password"
                >
                  {!confirmPasswordVisible ? (
                    <VisibilityOff className="signin-input-icon" />
                  ) : (
                    <Visibility />
                  )}
                </Icon>
              </InputAdornment>
            }
          />
          {errors && errors.confirm_password && (
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                color: "red",
                fontSize: "13px",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {errors.confirm_password}
            </Typography>
          )}
        </FormControl>

        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            className="button-signin"
            disabled={loader}
            sx={{ px: 2 }}
            onClick={submitForm}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
