import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
import { Typography, Grid, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CsvDownloadButton from "react-json-to-csv";
import exportFromJSON from "export-from-json";
import { FireSwal } from "../helpers/swalNotification";
import { useParams } from "react-router-dom";
import { GreetingsTable } from "../components/GreetingsTable/GreetingsTable";
import { getEventGreetings } from "../redux/Tasks/TasksActions";

const Greetings = () => {
  const baseURL = "https://d1tpkvttieyepa.cloudfront.net/";
  const [greetingsData, setGreetingsData] = useState([]);
  const [greetings, setGreetings] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const muiTheme = useTheme();
  const [newGreeting, setNewGreeting] = useState([]);
  const mobilescreen = useMediaQuery(muiTheme.breakpoints.down("sm"));
  let arr = [];
  var setData = (data) => {
    if (data.status) {
      setGreetingsData(data);
      //console.log(data);
      data.greetings.map((item) => {
        const images = item.images.map((i) => {
          return baseURL + i.file;
        });
        arr.push([
          item?.createdAt.split("T")[0],
          item?.user?.username,
          item?.title,
          item.description,
          images,
        ]);
        setGreetings(arr);
      });
      setLoader(true);
    } else {
      FireSwal("error", "Oops..", data.message, false, false);
    }
  };

  useEffect(() => {
    dispatch(getEventGreetings({ event_id: params.id }, setData));
  }, []);
  console.log(greetings, "greetig");

  return (
    <Dashboard>
      <Container maxWidth="md">
        <Grid container sx={{ pt: 4 }}>
          <Card
            className="pinkscroll"
            sx={{
              p: 4,
              // textAlign: "center",
              bgcolor: "white",
              minHeight: "50vh",
              maxHeight: "100vh",
              width: "100%",
              overflowY: "scrool",
            }}
          >
            {loader ? (
              <Grid item lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: mobilescreen ? "column" : "row",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "poppinsSemiBold",
                      alignItems: "center",
                    }}
                  >
                    {greetingsData.event?.general_info?.event_name}
                  </Typography>
                  <CsvDownloadButton
                    style={{
                      maxWidth: "200px",
                      backgroundColor: "#ff9885",
                      color: "#ffffff",
                      minHeight: "35px",
                      cursor: "pointer",
                      border: "none",
                      padding: 10,
                      borderRadius: 4,
                    }}
                    data={greetings}
                    headers={[
                      "Date",
                      "User Name",
                      "Title",
                      "Description",
                      "Image",
                    ]}
                    delimiter={","}
                    filename={greetingsData.event?.general_info?.event_name}
                  >
                    Download CSV
                  </CsvDownloadButton>
                </Box>
                <GreetingsTable greetings={greetingsData?.greetings} />
              </Grid>
            ) : (
              <div
                style={{
                  // margin: "auto",
                  marginTop: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CircularProgress top={10} sx={{ color: "#FF9885" }} />
              </div>
            )}
          </Card>
        </Grid>
      </Container>
    </Dashboard>
  );
};
export default Greetings;
