import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  createNewTask,
  updateTaskStatus,
} from "../../redux/Tasks/TasksActions";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { baseURL } from "../../config/endpoint";
export function CreateCategory({
  helloName,
  open,
  handleClose,
  callBack,
  selected,
  actionType,
  languageData,
  id,
}) {
  const [errors, setErrors] = useState({});
  console.log("dfdfdf", helloName);
  const [inputValues, setInputValues] = useState({ name: "" });
  console.log("dfdf", inputValues);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    maxHeight: "70vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    mr: 2,
  };
  useEffect(() => {
    setInputValues({
      name: helloName,
    });
    // if (selected) {
    //   setInputValues(selected);
    // }
    // setInputValues({name: _name})
    console.log(inputValues);
  }, [selected, helloName]);

  var handleChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  var submitForm = (e) => {
    e.preventDefault();

    if (actionType === "add") {
      if (!inputValues.name || inputValues.name.length < 1) {
        var _errors = { ...errors };
        _errors.name = "Please provide a category name!";
        setErrors(_errors);
        return;
      }
      setLoader(true);
      axios
        .post(`${baseURL}/category`, {
          name: inputValues.name,
        })
        .then((val) => {
          stopLoader(val.data);
        });
      // dispatch(createNewTask(inputValues, stopLoader));
    } else {
      if (!inputValues.name || inputValues.name.length < 1) {
        var _errors = { ...errors };
        _errors.status = "Please enter name to update!";
        setErrors(_errors);
        return;
      }
      setLoader(true);
      axios
        .post(`${baseURL}/category/edit`, {
          category_id: id,
          name: inputValues.name,
        })
        .then((val) => {
          if (val.data.status) {
            handleClose(false);
            FireSwal("success", "Category Updated!", "", false, false);
            callBack();
            setTimeout(() => {
              Swal.close();
            }, 2000);
          } else {
            handleClose(false);
            FireSwal(
              "error",
              "Oops..",
              "Error while updating category!",
              false,
              true
            );
          }
        });
    }
  };
  var stopLoader = (resp) => {
    if (resp.status) {
      handleClose(false);
      FireSwal("success", "Category Added", "", false, false);
      callBack();
      setTimeout(() => {
        Swal.close();
      }, 2000);
    } else {
      handleClose(false);
      FireSwal("error", "Oops..", resp.message, false, true);
    }
    setLoader(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 20 }}
          onClick={() => {
            handleClose(!open);
            setInputValues({});
          }}
        >
          <CloseIcon sx={{ color: "#black", height: "20px" }} />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "Black" }}
        >
          {actionType === "add" ? languageData?.add_category : "Edit Category"}
        </Typography>
        {actionType === "add" ? (
          <FormControl sx={{ width: "100%" }} variant="filled">
            {/* <InputLabel
              htmlFor="filled-adornment-email"
              className="signin-email-label"
            >
              Name
            </InputLabel> */}
            {/* <FilledInput
              id="filled-adornment-password"
              type="text"
              fullWidth
              sx={{width:"100%"}}
              className="signin-input"
              onChange={handleChange}
              name="name"
            /> */}
            <TextField
              sx={{ width: "100%" }}
              label={languageData?.name}
              size="small"
              onChange={handleChange}
              name="name"
              type="text"
              id="filled-adornment-password"
            >
              <OutlinedInput />
            </TextField>
            {errors && errors.name && (
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {errors.name}
              </Typography>
            )}
          </FormControl>
        ) : (
          <FormControl sx={{ width: "100%" }} variant="filled">
            {/* <InputLabel
              htmlFor="filled-adornment-email"
              className="signin-email-label"
            >
              Name
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type="text"
              fullWidth
              className="signin-input"
              onChange={handleChange}
              name="name"
            /> */}
            <TextField
              sx={{ width: "100%" }}
              label="Category Name"
              value={inputValues.name}
              size="small"
              onChange={handleChange}
              name="name"
              type="text"
              id="filled-adornment-password"
            >
              <OutlinedInput />
            </TextField>
            {errors && errors.status && (
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                sx={{
                  color: "red",
                  fontSize: "13px",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {errors.status}
              </Typography>
            )}
          </FormControl>
        )}

        <Button
          variant="contained"
          className="button-signin"
          sx={{ my: 2, py: 0, maxWidth: "110px" }}
          onClick={submitForm}
        >
          {actionType === "add" ? languageData?.add_button : "Edit"}
        </Button>
      </Box>
    </Modal>
  );
}
