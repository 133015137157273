import { INSTANCE } from "../../config/axiosInstance";

export class ProfileApi {
  getUserProfile = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "GET",
        url: "/user/user",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getTasks = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/event-all-tasks",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  getTasksByCategory = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/category-all-tasks",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  cancelEvent = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/events/deleteEvent",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  cancelEventButton = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/events/cancel-event",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  deleteImage = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/gallery/delete-images",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  eventDetails = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/events/by-id",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  totalImages = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "GET",
        url: "/gallery/total-images/" + data,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  allImages = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/gallery/get-all-images",

        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  totalGuest = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "GET",
        url: "/joint-events/" + data,
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  eventScanner = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/events/qrCodeGenerate",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  deleteevent = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/events/deleteEvent",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  updateUserProfile = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/profile-update",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  updateUserPassword = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/password-update",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  changeUserImage = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/profile-update-image",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
