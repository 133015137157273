import { INSTANCE } from "../../config/axiosInstance";

export class TasksApi {
  getAllTaskAdmin = async (obj) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/admin",
        data: obj,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getAllTaskUser = async (obj) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/user",
        data: obj,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getAllTaskUser = async (obj) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/user",
        data: obj,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getAllHost = async (obj) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "user/all-hosts",
        data: obj,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getEventGreetings = async (obj) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "greetings/view-all",
        data: obj,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getAllGuest = async (obj) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "GET",
        url: "/joint-events/guest/list-of-guest",
        data: obj,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  greetingsById = async (obj) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "greetings/view-by-id",
        data: obj,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  createNewEventTask = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/create-event-task",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  createNewCustomTask = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/create-category-task",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  createNewCategoryTask = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/create-category-task",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  createNewTask = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  updateTaskStatus = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/tasks/admin/update",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  updateHostStatus = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/user/update-host-status",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getApprovedTasks = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "GET",
        url: "/tasks/approved",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  createNewEvent = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "POST",
        url: "/events",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getEvents = async () => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "Post",
        url: "/events/get-all",
      })
        .then(resolve)
        .catch(reject);
    });
  };
  getFilterEvents = async (data) => {
    return new Promise((resolve, reject) => {
      INSTANCE({
        method: "Post",
        url: "/events/eventsHandler",
        data,
      })
        .then(resolve)
        .catch(reject);
    });
  };
}
