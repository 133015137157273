import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { flexbox } from "@mui/system";
export default function SelectLanguage({ loading, setLoading }) {
  const style = {
    position: "fixed",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    zIndex: 1100,
    backgroundColor: "#fff",
    justifyContent: 'center'
  };
  return (
    <div style={{ textAlign: 'center'}}>
      <Box
        sx={style}
      >
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "Black" }}
        >
          JSTPRTY
        </Typography>
        <CircularProgress sx={{color: 'rgb(255, 152, 133)',ml:2, height: ' 20px',mt:-1}}/>
      </Box>
    </div>
  );
}
