import {
  Avatar,
  Button,
  Card,
  Divider,
  Modal,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import eventCover from "../../assets/images/event-cover.png";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { ProfileApi } from "../../redux/Profile/ProfileApi";
import moment from "moment";
import QRCode from "qrcode.react";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FireSwal } from "../../helpers/swalNotification";
import ProgressiveImage from "react-progressive-graceful-image";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { INSTANCE } from "../../config/axiosInstance";

const style = {
  position: "absolute",
  // width: "500px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  // bgcolor: "white",
  // border: "1px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
// const role = localStorage.getItem("eventRole");

const profileApi = new ProfileApi();
export function GeneralEventDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  const [tasks, setTasks] = React.useState();
  const [inputText, setInputText] = React.useState("");
  const [qrCodeText, setQRCodeText] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [totalGuest, setTotalGuest] = React.useState(0);
  const [totalImages, setTotalImages] = React.useState(0);
  const [totalVdo, setTotalVdo] = React.useState(0);
  const muiTheme = useTheme();
  const mobilescreen = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const [languageData, setLanguageData] = React.useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`))
  );

  const handleOpen = async () => {
    const { data } = await profileApi.eventScanner({ event_id: id });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [eventDetail, setEventDetail] = React.useState();
  React.useEffect(() => {
    eventDetails();
    getAllTasks();
  }, []);
  const cancelEvent = async () => {
    FireSwal(
      "Warning",
      "Warning",
      "Are you sure, you want to delete this event?",
      true,
      true
    ).then(async (val) => {
      if (val.isConfirmed) {
        const { data } = await profileApi.cancelEventButton({ event_id: id });
        FireSwal("success", "", "Event Canceled", false, false);
        setTimeout(() => Swal.close(), 2000);
        navigate(-1);
      } else {
        // FireSwal("success", "", "Event is not cancel", true, true);/
      }
    });
  };
  const eventDetails = async () => {
    const { data } = await profileApi.eventDetails({ event_id: id });
    // const { data: totalGuest } = await profileApi.totalGuest(id);
    setTotalGuest(data.totalguest);
    setTotalImages(data.totalImages);
    setTotalVdo(data.totalVideos);
    setEventDetail(data);
  };
  const getAllTasks = async () => {
    const { data } = await profileApi.getTasks({ event_id: id });
    console.log(data, "tasks");
    setTasks(data.tasks);
  };
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };
  let handleCoverImage = async (e) => {
    if (e.target.files[0]) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("event_id", id);
      var { data } = await INSTANCE({
        method: "POST",
        url: "/events/edit-cover",
        data: formData,
      });

      if (data.status) {
        FireSwal(
          "success",
          languageData?.singleEvent?.edit_cover,
          "",
          false,
          false
        );

        setTimeout(() => {
          Swal.close();
        }, 2000);
        eventDetails();
      } else {
        FireSwal(
          "error",
          "Oops..",
          "Error while uploading image!",
          false,
          true
        );
      }
    }
  };
  let handleProfileImage = async (e) => {
    if (e.target.files[0]) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("event_id", id);
      var { data } = await INSTANCE({
        method: "POST",
        url: "/events/edit-profile",
        data: formData,
      });

      if (data.status) {
        FireSwal(
          "success",
          languageData?.singleEvent?.profile_updated_msg,
          "",
          false,
          false
        );

        setTimeout(() => {
          Swal.close();
        }, 2000);
        eventDetails();
      } else {
        FireSwal(
          "error",
          "Oops..",
          "Error while uploading image!",
          false,
          true
        );
      }
    }
  };

  const greetings = () => {
    navigate("/greetings/" + id);
  };

  return (
    <>
      <input
        style={{ display: "none" }}
        id="cover_image"
        type="file"
        onChange={handleCoverImage}
      />
      <input
        style={{ display: "none" }}
        id="profile_image"
        type="file"
        onChange={handleProfileImage}
      />
      <div
        className="event-cover"
        style={{ height: "300px", position: "relative" }}
      >
        <Box
          sx={{ position: "absolute", right: "5px", top: "5px", zIndex: "1" }}
        >
          <IconButton
            sx={{ backgroundColor: "white" }}
            onClick={() => {
              document.getElementById("cover_image").click();
            }}
          >
            <ModeEditOutlineOutlinedIcon sx={{ color: "#ff9885" }} />
          </IconButton>
        </Box>
        {eventDetail?.event?.cover_image === null ? (
          <img
            src="https://www.znergycable.com/wp-content/uploads/2021/07/istockphoto-1357365823-612x612.jpg"
            style={{ height: "300px" }}
          />
        ) : (
          <ProgressiveImage src={eventDetail?.event?.cover_image}>
            {(src, loading) => {
              return loading ? (
                <Skeleton width={"100%"} height={300} variant="rectangular" />
              ) : (
                <img src={src} />
              );
            }}
          </ProgressiveImage>
        )}
      </div>
      <div>
        <Grid container spacing={2} className="event-profile">
          <Grid
            item
            xs={12}
            sm={12}
            lg={4}
            sx={{ m: "auto", position: "relative" }}
          >
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <Typography
                sx={{
                  textAlign: "center",
                  font: "normal normal normal 15px Poppins",
                  letterSpacing: "0px",
                  color: "#ff9885 ",
                  opacity: "1",
                  marginLeft: "15px",
                  mt: 2,
                  cursor: "pointer",
                }}
                component="div"
                gutterBottom
                onClick={() => {
                  document.getElementById("profile_image").click();
                }}
              >
                <ModeEditOutlineOutlinedIcon
                  sx={{ color: "#ff9885", height: "15px" }}
                />{" "}
                {languageData?.singleEvent?.edit_profile}
              </Typography>
            </Box>
            {eventDetail?.event?.profile_image === null ? (
              <Box sx={{ position: "relative" }}>
                <Avatar
                  alt="profile-img"
                  src="https://www.shoshinsha-design.com/wp-content/uploads/2020/05/noimage_%E3%83%92%E3%82%9A%E3%82%AF%E3%83%88-580x440.png"
                  sx={{
                    width: 140,
                    height: 140,
                    m: "auto",
                    position: "absolute",
                    bottom: "0px",
                    left: "30px",
                  }}
                />
              </Box>
            ) : (
              <ProgressiveImage
                src={eventDetail?.event?.profile_image}
                placeholder=""
              >
                {(src, loading) => {
                  return loading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: 140,
                        height: 140,
                        m: "auto",
                        position: "absolute",
                        bottom: "0px",
                        left: "30px",
                      }}
                    />
                  ) : (
                    <Avatar
                      alt="profile-img"
                      src={src}
                      sx={{
                        width: 140,
                        height: 140,
                        m: "auto",
                        position: "absolute",
                        bottom: "0px",
                        left: "30px",
                      }}
                    />
                  );
                }}
              </ProgressiveImage>
            )}
          </Grid>
          <Grid item xs={12} sm={12} lg={8}>
            <Grid
              container
              spacing={1}
              className="event-profile"
              sx={{ mt: 2, px: 2 }}
            >
              <Grid item xs={3}>
                {" "}
                <div className="profile-counts">
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal normal 11px/15px Poppins",
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      opacity: "1",
                    }}
                  >
                    {languageData?.singleEvent?.tasks_count}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal bold 18px/15px Poppins",
                      letterSpacing: "0px",
                      color: " #FFFFFF",
                      opacity: "1",
                      mb: 0,
                    }}
                  >
                    {eventDetail?.event?.tasks?.length}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={3}>
                {" "}
                <div className="profile-counts">
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal normal 11px/15px Poppins",
                      letterSpacing: "0px",
                      color: " #FFFFFF",
                      opacity: "1",
                    }}
                  >
                    {languageData?.singleEvent?.images_count}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal bold 18px/15px Poppins",
                      letterSpacing: "0px",
                      color: " #FFFFFF",
                      opacity: "1",
                      mb: 0,
                    }}
                  >
                    {totalImages}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={3}>
                {" "}
                <div className="profile-counts">
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal normal 11px/15px Poppins",
                      letterSpacing: "0px",
                      color: " #FFFFFF",
                      opacity: "1",
                    }}
                  >
                    {languageData?.singleEvent?.videos_count}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal bold 18px/15px Poppins",
                      letterSpacing: "0px",
                      color: " #FFFFFF",
                      opacity: "1",
                      mb: 0,
                    }}
                  >
                    {totalVdo}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={3}>
                {" "}
                <div className="profile-counts">
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal normal 11px/15px Poppins",
                      letterSpacing: "0px",
                      color: " #FFFFFF",
                      opacity: "1",
                    }}
                  >
                    {languageData?.singleEvent?.guests_count}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{
                      font: "normal normal bold 18px/15px Poppins",
                      letterSpacing: "0px",
                      color: " #FFFFFF",
                      opacity: "1",
                      mb: 0,
                    }}
                  >
                    {totalGuest}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2} className="event-profile">
        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          sx={{ m: "auto", position: "relative" }}
        ></Grid>
        <Grid item xs={12} sm={12} lg={8}>
          <Grid
            container
            spacing={1}
            className="event-profile"
            sx={{ mt: 2, px: 2, ml: 0 }}
          >
            <Button
              variant="contained"
              className="default-button"
              sx={{ color: "white", mr: 1, mb: 2 }}
              onClick={() => {
                navigate(`/tasks`, {
                  state: { type: 0, event_id: id },
                });
              }}
            >
              {languageData?.singleEvent?.view_tasks_btn}
            </Button>
            {localStorage.getItem("eventRole") === "host" ? (
              ""
            ) : (
              <Button
                variant="contained"
                className="default-button"
                sx={{ backgroundColor: "#D3D3D3", ml: 0, mb: 2 }}
                onClick={greetings}
              >
                {languageData?.singleEvent?.greetings_btn}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {mobilescreen && (
        <Grid container spacing={2} className="event-profile">
          <Grid
            item
            xs={12}
            sm={12}
            lg={4}
            sx={{ m: "auto", position: "relative" }}
          ></Grid>
          <Grid item xs={12} sm={12} lg={8} sx={{}}>
            <Grid
              container
              spacing={1}
              className="event-profile"
              sx={{ mt: 0, px: 2, ml: 0 }}
            >
              <Button
                variant="contained"
                className="default-button"
                sx={{ color: "white" }}
                onClick={() => navigate("/gallery/" + id)}
              >
                {languageData?.singleEvent?.view_gallery_btn}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Box sx={{ pl: 2, pt: 2, pr: 2 }}>
        <Card elevation={4}>
          <Grid container spacing={2} sx={{ mt: 2, p: 3, pt: 0 }}>
            <Grid item xs={12} sm={6}>
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  {languageData?.home?.event_name}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="event-detail-lower-desc"
                >
                  {eventDetail?.event?.general_info?.event_name}
                </Typography>
              </div>
              {/* <Divider variant="dark" /> */}
              <div style={{ marginTop: "25px" }}>
                <div>
                  {" "}
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    className="event-detail-top-head"
                  >
                    {languageData?.home?.end_date}
                  </Typography>
                  <div
                    className="event-detail-lower-desc"
                    style={{ fontSize: "12px" }}
                  >
                    <span style={{ fontSize: "15px", color: "#4E3D39" }}>
                      {" "}
                      {moment(eventDetail?.event?.general_info?.date_to).format(
                        "LL"
                      )}
                    </span>{" "}
                  </div>
                </div>
              </div>
              {/* <Divider variant="middle" /> */}
              <div style={{ marginTop: "25px" }}>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  Event Status
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="event-detail-lower-desc"
                >
                  Happening Now
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  {languageData?.home?.start_date}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="event-detail-lower-desc"
                >
                  {moment(eventDetail?.event?.general_info?.date_from).format(
                    "LL"
                  )}
                </Typography>
              </div>

              <div style={{ marginTop: "25px" }}>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="event-detail-top-head"
                >
                  {languageData?.home?.location}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="event-detail-lower-desc"
                >
                  {eventDetail?.event?.general_info?.event_location}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Box>
      {/* <div style={{ borderTop: "5px solid #F8F1F1" }}></div> */}
      <div className="text-center">
        <Grid container spacing={2} className="event-profile" sx={{ mb: 1 }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={0}
            sx={{ m: "auto", position: "relative" }}
          ></Grid>
          <Grid item xs={12} sm={12} lg={12}>
            <Grid
              container
              spacing={1}
              className="event-profile"
              sx={{ mt: 2, px: 2, ml: 0 }}
            >
              <Button
                variant="contained"
                className="default-button"
                sx={{ mr: 1, mt: 1 }}
                onClick={handleOpen}
              >
                {!eventDetail?.event?.qrCode
                  ? "CREATE QR CODE"
                  : languageData?.singleEvent?.view_qrcode_btn}
              </Button>
              <Button
                variant="contained"
                className="default-button2"
                sx={{ mt: 1 }}
                onClick={cancelEvent}
              >
                {languageData?.singleEvent?.cancel_event_btn}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card elevation={4} sx={{ ...style, px: 8 }}>
            {/* <Box sx={{ ...style }}> */}
            <Typography
              sx={{ fontFamily: "Roboto" }}
              variant="h5"
              textAlign="center"
            >
              {languageData?.singleEvent?.scan_qrcode}
            </Typography>
            <Box sx={{ py: 4 }}>
              <QRCode id="qrCodeEl" size={250} value={id} />
              <Grid container>
                <Grid item sm={6}>
                  <Typography
                    variant="body1"
                    sx={{ mt: 2, mb: -2, fontWeight: 600 }}
                    textAlign="right"
                  >
                    {"Event code: "}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      mt: 2,
                      mb: -2,
                      pl: 1,
                      fontWeight: 600,
                      color: "#ff9885",
                    }}
                    textAlign="left"
                  >
                    {eventDetail?.event?.event_code}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Button
              variant="contained"
              className="default-button"
              value="Download"
              onClick={downloadQRCode}
              sx={{ mr: 2 }}
            >
              {languageData?.singleEvent?.download_btn}
            </Button>
            <Button
              variant="contained"
              className="default-button2"
              onClick={handleClose}
            >
              {languageData?.singleEvent?.close_btn}
            </Button>
            {/* </Box> */}
          </Card>
        </Modal>
      </div>
    </>
  );
}
