import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import {Typography,CircularProgress} from "@mui/material";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Stack } from "@mui/material";
import moment from "moment";
export function EventDetailList({ events, languageData,loader }) {
  const navigate = useNavigate();

  return (
    <Card sx={{ mt: 2 }}>
    
      <Box sx={{ p: 1 }}>
        {" "}
        <div className="d-flex align-items-center" style={{ marginTop: "5px" }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            className="event-card-list-head"
            sx={{ m: 0, cursor: "pointer" }}
            onClick={() => navigate("/events")}
          >
            {languageData?.schedule}
          </Typography>
          <IconButton
            onClick={() => navigate("/create-event")}
            style={{
              backgroundColor: "#ff9885",
              marginLeft: "auto",
              marginRight: "5px",
            }}
            color="inherit"
            sx={{ ml: 1 }}
            // sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <AddIcon sx={{ color: "white" }} />
          </IconButton>
        </div>
      </Box>
      {loader ?
    <>
      <Box className="event-list">
        <Grid
          container
          className="pinkscroll"
          spacing={2}
          sx={{
            p: 2,
            width: "100%",
            m: 0,
            // maxHeight: "400px",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {events?.map((item) => (
            <>
              <Grid
                xs={12}
                sm={6}
                container
                sx={{
                  p: 2,
                  border: "1px solid rgb(255, 152, 133)",
                  width: "100%",
                  m: 0,
                  mt: 1,
                }}
              >
                <Grid item xs={12} sm={6} sx={{ pr: 5 }}>
                  <div>
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      className="event-detail-top-head"
                    >
                      {languageData?.event_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      className="event-detail-lower-desc"
                    >
                      {item?.general_info?.event_name}
                    </Typography>
                  </div>
                  {/* <Divider variant="dark" /> */}
                  <div style={{ marginTop: "35px", marginBottom: "35px" }}>
                    <div>
                      {" "}
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        className="event-detail-top-head"
                      >
                        {languageData?.end_date}
                      </Typography>
                      <div
                        className="event-detail-lower-desc"
                        style={{ fontSize: "12px" }}
                      >
                        <span style={{ fontSize: "15px", color: "#4E3D39" }}>
                          {" "}
                          {moment(item?.general_info?.date_to).format("LL")}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  {/* <Divider variant="middle" /> */}
                </Grid>
                <Grid item xs={12} sm={6} sx={{ pr: 5 }}>
                  <div>
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      className="event-detail-top-head"
                    >
                      {languageData?.start_date}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      className="event-detail-lower-desc"
                    >
                      {moment(item?.general_info?.date_from).format("LL")}
                    </Typography>
                  </div>

                  <div style={{ marginTop: "35px" }}>
                    <Typography
                      variant="h4"
                      component="div"
                      gutterBottom
                      className="event-detail-top-head"
                    >
                      {languageData?.location}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      gutterBottom
                      className="event-detail-lower-desc"
                    >
                      {item?.general_info?.event_location}
                    </Typography>
                  </div>
                </Grid>
                <div
                  style={{
                    textAlign: "left",
                    textAlign: "left",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1,
                      ml: 1,
                      p: 1,
                      backgroundColor: "rgb(255, 152, 133)",
                      "&:hover": {
                        backgroundColor: "rgb(255, 152, 133)",
                      },
                    }}
                    onClick={() => navigate(`/event-details/${item._id}`)}
                  >
                    {languageData?.view_button}
                  </Button>
                </div>
              </Grid>
            </>
          ))}
        </Grid>
      </Box>
      </>
     : (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress sx={{ color: "#FF9885" }} />
      </Box>
    )}
    </Card>
  );
}
