import * as constants from "./LoginConstants";
import { LoginApi } from "./LoginApi";
const loginApi = new LoginApi();

export const signIn = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await loginApi.signIn(obj);
    localStorage.setItem("eventAuth", data.token);
    localStorage.setItem("eventRole", data.role);
    dispatch({
      type: constants.USER_SIGN_IN,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const signUp = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await loginApi.signUp(obj);
    localStorage.setItem("eventAuth", data.token);
    localStorage.setItem("eventRole", data.role);
    dispatch({
      type: constants.USER_SIGN_UP,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
