import * as constants from "./ProfileConstants";
import { ProfileApi } from "./ProfileApi";
const profileApi = new ProfileApi();

export const getUserProfile = (cb) => async (dispatch) => {
  try {
    const { data } = await profileApi.getUserProfile();
    dispatch({
      type: constants.GET_USER_PROFILE,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const updateUserProfile = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await profileApi.updateUserProfile(obj);
    dispatch({
      type: constants.UPDATE_USER_PROFILE,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const updateUserPassword = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await profileApi.updateUserPassword(obj);
    dispatch({
      type: constants.UPDATE_USER_PASSWORD,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const changeUserImage = (file, cb) => async (dispatch) => {
  var obj = new FormData();
  obj.append("image", file);
  try {
    const { data } = await profileApi.changeUserImage(obj);
    dispatch({
      type: constants.UPDATE_USER_PROFILE_IMAGE,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
