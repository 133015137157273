import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import React from "react";
import { Container } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Card from "@mui/material/Card";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CopyToClipboard from "react-copy-to-clipboard";
import axios from "axios";
import { baseURL } from "../config/endpoint";

export function License() {
  const [field, setField] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [license, setLicense] = React.useState([]);
  const [packages, setPackages] = React.useState([]);
  const [select, setSelection] = React.useState("");
  const [hide, setHide] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = React.useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`))
  );
  const createLicesne = () => {
    axios
      .post(`${baseURL}/license/create-licenses`, {
        count: field,
        package: select,
      })
      .then((val) => {
        setLicense(val.data.saveLicence);
      });
  };
  const selection = (val) => {
    setSelection(val._id);
    setHide(!hide);
  };
  React.useEffect(() => {
    axios.get(`${baseURL}/packages/`).then((val) => {
      setPackages(val.data.packages);
      setLoading(true)
    });
  }, []);
  React.useEffect(() => {
    setLicense([])
    setField('')
  }, [hide]);
  return (
    <Dashboard>
      <Container style={{ margin: "10px 0px" }}>
        {loading?(   <>     <Grid container spacing={2}>
          {packages.length > 0 &&
            packages?.map((val) => (
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Paper>
                  {" "}
                  <Card>
                    <div
                      style={{ backgroundColor: "#FF9885", height: "85px",padding: "15px", display: 'flex', flexDirection: 'column', justifyContent: 'center'  }}
                    >
                      {" "}
                      <Box textAlign="center">
                        <Typography
                          variant="h5"
                          component="div"
                          style={{ textAlign: "center", color: "white" }}
                        >
                          {val.title}
                        </Typography>
                      </Box>
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        backgroundColor: "#FFF",
                        color: "black",
                        padding: "10px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: "14px",
                          textDecoration: "underline",
                          textDecorationColor: "pink",
                        }}
                      >
                        {languageData?.packages?.media_allowed}: {val.mediaTypeallowed}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: "14px",
                          textDecoration: "underline",
                          textDecorationColor: "pink",
                        }}
                      >
                        {languageData?.packages?.max_upload_media}: {val.maxMedia}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: "14px",
                          textDecoration: "underline",
                          textDecorationColor: "pink",
                        }}
                      >
                        {languageData?.packages?.max_guests}: {val.maxGuest}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: "14px",
                          textDecoration: "underline",
                          textDecorationColor: "pink",
                        }}
                      >
                        {languageData?.packages?.download_allowed}: {val.downloadAllowed}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: "14px",
                          textDecoration: "underline",
                          textDecorationColor: "pink",
                        }}
                      >
                        {languageData?.packages?.d_unreg_guests}: {val.unregisteredGuest}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: "14px",
                          textDecoration: "underline",
                          textDecorationColor: "pink",
                        }}
                      >
                        {languageData?.packages?.show_gallery}: {val.accesstodownloads}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          fontSize: "14px",
                          textDecoration: "underline",
                          textDecorationColor: "pink",
                        }}
                      >
                        {languageData?.packages?.duration}: {val.accesstodownloads}
                      </Typography>
                    </div>

                    <CardActions style={{ backgroundColor: "#FFF" }}>
                      {hide && val._id == select ? (
                        <Button
                          size="medium"
                          style={{
                            backgroundColor: "#FFD9D2",
                            width: "100%",
                            color: "#FF8D75",
                            border: '3px solid #FF9885',
                          }}
                          disabled={hide && val._id != select}
                          onClick={(e) => selection(val)}
                        >
                          {hide && val._id == select ? languageData?.licenses?.selected : languageData?.licenses?.select}
                        </Button>
                      ) : (
                        <Button
                          size="medium"
                          style={{
                            backgroundColor: "#FF9885",
                            width: "100%",
                            color: "white",
                          }}
                          disabled={hide && val._id != select}
                          onClick={(e) => {selection(val)
                          }}
                        >
                          {hide && val._id == select ? languageData?.licenses?.selected : languageData?.licenses?.select}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Paper>
              </Grid>
            ))}
        </Grid>
        <Card className="events-card" sx={{ mt: 2 }}>
          <Box sx={{ display: "block", p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  rmControl
                  id="outlined-password-input"
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setField(
                      e.target.value ? Number(e.target.value) : e.target.value
                    );
                  }}
                  value={field && Math.max(0, field)}
                  label={languageData?.licenses?.license_count}
                  type="number"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {hide && (
                  <Button
                    size="medium"
                    disabled={!hide}
                    style={{
                      backgroundColor: "#FF9885",
                      width: "100%",
                      color: "white",
                    }}
                    onClick={() => createLicesne()}
                  >
                    {languageData?.licenses?.generate_license}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <hr />
          <Box sx={{ display: "block", p: 3 }}>
            <Grid container spacing={2}>
              {license?.map((val, index) => (
                <Grid item xs={12} sm={6}>
                  <div>
                    <div style={{ color: "grey", opacity: "0.8" }}>
                    {languageData?.sidebar?.licenses} No {index + 1}
                    </div>
                    <TextField
                      id="outlined-password-input"
                      sx={{
                        // backgroundColor: "gainsboro",
                        width: "100%",
                        padding: "0px",
                      }}
                      type="text"
                      value={val.license_number}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CopyToClipboard text={val.license_number}>
                              <IconButton
                                aria-label="toggle password visibility"
                                style={{
                                  backgroundColor: "#FF9885",
                                  color: "white",
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </CopyToClipboard>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Card>
        </>):<Box sx={{textAlign: 'center'}}>              <CircularProgress sx={{ color: "#FF9885",  }} />
</Box >}

      </Container>
    </Dashboard>
  );
}
