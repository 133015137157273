import * as constants from "./TaskConstants";
import { TasksApi } from "./TasksApi";
const tasksApi = new TasksApi();

export const getAllTaskAdmin = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getAllTaskAdmin(obj);
    dispatch({
      type: constants.ADMIN_TASK_LIST,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const createNewTask = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.createNewTask(obj);
    dispatch({
      type: constants.CREATE_NEW_TASK,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const createNewTaskForEvent = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.createNewEventTask(obj);
    dispatch({
      type: constants.CREATE_NEW_EVENT_TASK,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const createNewTaskForCustom = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.createNewCustomTask(obj);
    dispatch({
      type: constants.CREATE_NEW_CUSTOM_TASK,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const createNewTaskForCategory = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.createNewCategoryTask(obj);
    dispatch({
      type: constants.CREATE_NEW_CATEGORY_TASK,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};

export const getAllTaskUser = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getAllTaskUser(obj);
    dispatch({
      type: constants.USER_TASK_LIST,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const getAllHost = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getAllHost(obj);
    dispatch({
      type: constants.USER_TASK_LIST,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const getEventGreetings = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getEventGreetings(obj);
    dispatch({
      type: constants.USER_TASK_LIST,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const greetingsById = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.greetingsById(obj);
    dispatch({
      type: constants.USER_TASK_LIST,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};

export const getAllGuest = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getAllGuest(obj);
    dispatch({
      type: constants.USER_TASK_LIST,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};

export const updateTaskStatus = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.updateTaskStatus(obj);
    dispatch({
      type: constants.UPDATE_TASK_STATUS,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const updateHostStatus = (obj, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.updateHostStatus(obj);
    dispatch({
      type: constants.UPDATE_TASK_STATUS,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const getApprovedTasks = (cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getApprovedTasks();
    dispatch({
      type: constants.GET_APPROVED_TASK,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const createNewEvent = (formData, cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.createNewEvent(formData);
    dispatch({
      type: constants.CREATE_NEW_EVENT,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
export const getEvents = (cb) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getEvents();
    dispatch({
      type: constants.GET_ALL_EVENTS,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};

export const getEventsFilters = (cb, datas) => async (dispatch) => {
  try {
    const { data } = await tasksApi.getFilterEvents(datas);
    dispatch({
      type: constants.GET_ALL_EVENTS,
      payload: data.data,
    });
    cb(data);
  } catch (error) {
    cb(error?.response?.data);
  }
};
