import Dashboard from "./Dashboard";
import { styled, useTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { GeneralEventDetail } from "../components/Event Details/GeneralEventDetail";
import useMediaQuery from "@mui/material/useMediaQuery";
import { EventGallery } from "../components/Event Details/Event Gallery";

export function EventDetails() {
  const muiTheme = useTheme();
  const mobilescreen = useMediaQuery(muiTheme.breakpoints.down("sm"));
  return (
    <Dashboard>
      <Card sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <GeneralEventDetail />
          </Grid>
          {!mobilescreen?(<Grid item xs={12} sm={6}>
            <EventGallery />
          </Grid>):''}
        </Grid>
      </Card>
    </Dashboard>
  );
}
