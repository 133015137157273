import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TaskTable } from "../components/Tasks/TaskTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  getAllHost,
  getAllTaskAdmin,
  getAllTaskUser,
} from "../redux/Tasks/TasksActions";
import { FireSwal } from "../helpers/swalNotification";
import { CurrentRole } from "../helpers/getRole";
import { useLocation, useParams } from "react-router-dom";
import { HostTable } from "../components/Hosts/HostTable";
import { greetingsById } from "../redux/Tasks/TasksActions";

export function GreetingsById() {
  const params = useParams();
  const dispatch = useDispatch();
  const [greetingsData, setGreetingsData] = useState();
  const [greetingsImages, setGreetingsImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const style = {
    pt: 2,
    px: 4,
    pb: 3,
    maxWidth: "800px",
    maxHeight: "90vh",
    borderRadius: 10,
    objectFit: "contain",
    width: "100%",
  };
  var setData = (data) => {
    if (data.status) {
      setGreetingsData(data);
      setGreetingsImages(data.greeting.images);
    } else {
      FireSwal("error", "Oops..", data.message, false, false);
      //   setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(greetingsById({ greeting_id: params.id }, setData));
  }, []);

  return (
    <Dashboard>
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <Card elevation={4} sx={{ minHeight: "80vh",  p: 4 }}>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Stack direction="row">
                <Avatar
                  alt={greetingsData?.greeting.user?.username}
                  src={greetingsData?.greeting.user?.user_image}
                  sx={{ width: 56, height: 56 }}
                />
                <Typography
                  variant="h6"
                  sx={{ p: 2, fontFamily: "poppinsSemiBold" }}
                >
                  {greetingsData?.greeting.user?.username}
                </Typography>
              </Stack>
              <Box
                sx={{
                  bgcolor: "#d3d3d345",
                  mt: 4,
                  p: 2,
                  borderRadius: 1,
                  //   minHeight: "15vh",
                }}
              >
                <Grid direction="row" container>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 13, color: "gray" }}
                    >
                      Event Name
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 16, fontFamily: "poppinsSemiBold" }}
                    >
                      {greetingsData?.greeting?.event.general_info.event_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 13, color: "gray" }}
                    >
                      Event Date
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 16, fontFamily: "poppinsSemiBold" }}
                    >
                      {moment(
                        greetingsData?.greeting?.event.general_info.date_from
                      ).format("LL")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  bgcolor: "#d3d3d345",
                  mt: 4,
                  p: 2,
                  borderRadius: 1,
                  minHeight: "30vh",
                }}
              >
                {" "}
                <Typography
                  variant="body1"
                  sx={{ fontSize: 13, color: "gray" }}
                >
                  {" "}
                  Title
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 16, fontFamily: "poppinsSemiBold" }}
                >
                  {greetingsData?.greeting?.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 13, color: "gray", pt: 2 }}
                >
                  {" "}
                  Description
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 16, fontFamily: "poppinsSemiBold" }}
                >
                  {greetingsData?.greeting?.description}
                </Typography>
              </Box>
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12}>
              <Grid container sx={{ p: 4, mt: 8 }}>
                {greetingsImages.map((image, index) => (
                  <Grid lg={4} md={6} sm={6} xs={8} key={index} >
                    {image.file_type !== "application/mp4" && (
                      <img
                        src={image.file}
                        style={{ height: "100px", width: "100px", cursor:'pointer'}}
                        onClick={() => {
                          setOpen(!open);
                          setImageURL(image.file);
                        }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid container sx={{ p: 4 }}>
                {greetingsImages.map((image, index) => (
                  <Grid xs={12} key={index}>
                    {image.file_type == "application/mp4" && (
                      <div>
                        <video
                          autoPlay
                          controls
                          style={{
                            width: "100%",
                            height: "200px",
                            borderRadius: "9px",
                            cursor: "pointer",
                          }}
                        >
                          <source src={`${image.file}`} type="video/mp4" />
                        </video>
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Modal
          open={open}
          style={{ objectFit: "contain" }}
          onClose={() => setOpen(false)}
        >
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ position: "relative",marginX: 2 }}>
              <Box
                sx={{
                  padding:-10,
                  position: "absolute",
                  color: "white",
                  right: 0,
                  margin: 2,
                  width:30,
                  height:30,
                  background: '#ff9885',
                  borderRadius: 1,
                  textAlign:'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpen(false)}
                
              >
                <Typography sx={{marginTop:.5}}>X</Typography>
              </Box>
              <img style={style} src={imageURL} />
            </Box>
          </Box>
        </Modal>
      </Container>
    </Dashboard>
  );
}
