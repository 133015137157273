import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { MenuItem, Select } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  createNewTask,
  updateTaskStatus,
} from "../../redux/Tasks/TasksActions";
import { FireSwal } from "../../helpers/swalNotification";
import Swal from "sweetalert2";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { baseURL } from "../../config/endpoint";
export function SelectLanguage({ open, handleClose, language, setLanguage,setLoading }) {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    maxHeight: "70vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    mr: 2,
  };
  React.useEffect(() => {
    if (language === null) {
      localStorage.setItem("language", "de");
      setLanguage("de");
    }
  }, []);
  var handleChange = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem("language", e.target.value);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate("/overview");
      window.location.reload()
    }, 2000);
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", right: 20 }}
          onClick={() => handleClose(!open)}
        >
          <CloseIcon sx={{ color: "#black", height: "20px" }} />
        </IconButton>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: "Black" }}
        >
          Select Language
        </Typography>
        <FormControl sx={{ width: "100%" }} variant="filled">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={language}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            <FormControlLabel value="en" control={<Radio />} label="English" />
            <FormControlLabel value="de" control={<Radio />} label="German" />
          </RadioGroup>
        </FormControl>
      </Box>
    </Modal>
  );
}
