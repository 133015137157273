import Dashboard from "./Dashboard";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useMediaQuery from "@mui/material/useMediaQuery";
import FilledInput from "@mui/material/FilledInput";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { EventTable } from "../components/Event Table/EventTable";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getEvents, getEventsFilters } from "../redux/Tasks/TasksActions";
import { Upcoming } from "@mui/icons-material";
import React from "react";
export function Events() {
  const navigate = useNavigate();
  const [type, setDataType] = useState(0);
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const muiTheme = useTheme();
  const tabletscreen = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = React.useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`))
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getEvents(stopLoader));
  }, []);
  var stopLoader = (data) => {
    setEvents(data.events);
    setLoading(false);
  };
  var setType = (status) => {
    setDataType(status);
    dispatch(
      getEventsFilters(stopLoader, {
        limit: "10",
        offset: "0",
        search: "",
        type: status,
      })
    );
  };

  const [errors, setErrors] = useState({});
  const buttons = [
    <Button
      key="all"
      className={type === 0 ? "active" : ""}
      style={{ fontWeight: "bold" }}
      onClick={() => {
        setType(0);
      }}
    >
      {languageData?.eventTableScreen?.all_events}
    </Button>,
    <Button
      key="upcoming"
      className={type === 1 ? "active" : ""}
      style={{ fontWeight: "bold" }}
      onClick={() => {
        setType(1);
      }}
    >
      {languageData?.eventTableScreen?.upcoming}
    </Button>,
    <Button
      key="happening"
      className={type === 2 ? "active" : ""}
      onClick={() => {
        setType(2);
      }}
      style={{ fontWeight: "bold" }}
    >
      {languageData?.eventTableScreen?.happening_now}
    </Button>,
    <Button
      key="completed"
      className={type === 3 ? "active" : ""}
      onClick={() => {
        setType(3);
      }}
      style={{ fontWeight: "bold" }}
    >
      {languageData?.home?.cancelled}
    </Button>,
    <Button
      key="done"
      className={type === 6 ? "active" : ""}
      onClick={() => {
        setType(6);
      }}
      style={{ fontWeight: "bold" }}
    >
      {languageData?.eventTableScreen?.completed}
    </Button>,
  ];

  return (
    <Dashboard>
      <Container>
        <Card className="events-card" sx={{ mt: 2 }}>
          <Box sx={{ borderBottom: "2px solid #EBEBEB ", display: "block" }}>
            <div
              className="d-flex align-items-center events-add-link justify-content-center"
              style={{ maxWidth: "250px", padding: "10px 0px" }}
              onClick={() => navigate("/create-event")}
            >
              <AddIcon sx={{ color: "white" }} />
              <Typography
                component="div"
                variant="h5"
                sx={{
                  font: "normal normal normal 15px/23px Poppins",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: "1",
                }}
              >
                {languageData?.eventTableScreen?.add}
              </Typography>
            </div>
          </Box>
          <Box sx={{ display: "block", p: 3 }}>
            <div className="d-flex justify-content-between align-items-center">
              <ButtonGroup
                size="small"
                className="event-tab-buttons"
                sx={{ width: "100%" }}
                orientation={tabletscreen ? "vertical" : ""}
              >
                {buttons}
              </ButtonGroup>
            </div>

            {loading ? (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  sx={{ textAlign: "center", color: "#FF9885" }}
                />
              </div>
            ) : (
              <Box sx={{ mt: 2 }}>
                <EventTable events={events} setEvents={setEvents} />
              </Box>
            )}
          </Box>
        </Card>
      </Container>
    </Dashboard>
  );
}
