import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import LoadingPage from "../pages/LoadingPage";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import SidebarIcon from "../assets/svg/sidebar-button.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Avatar, Stack } from "@mui/material";
import bell from "../assets/svg/bell.svg";
import pinkBars from "../assets/svg/pink-bars.svg";
import CategoryIcon from "../assets/svg/category-icon.svg";
import Smiley from "../assets/svg/smile.svg";
import Guest from "../assets/svg/Guest.svg";
import Events from "../assets/svg/Events.svg";
import Cross from "../assets/svg/Cross.svg";
import License from "../assets/svg/License.svg";
import Packages from "../assets/svg/Packages.svg";
import Host from "../assets/svg/Host.svg";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import Overview from "../assets/svg/overview.svg";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../redux/Profile/ProfileActions";
import { SelectLanguage } from "../modals/Language/selectLanguage";
import { CurrentRole } from "../helpers/getRole";
import { WindowSharp } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { styled as muiStyled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import Logo from "../assets/images/jstprty_logo.png";
const drawerWidth = 200;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, mobilescreen }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: mobilescreen ? 0 : `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, mobilescreen }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: mobilescreen ? "100%" : `calc(100% - ${drawerWidth}px)`,
    marginLeft: mobilescreen ? 0 : `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Dashboard({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = React.useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`))
  );
  const [loading, setLoading] = useState(false);
  const muiTheme = useTheme();
  const mobilescreen = useMediaQuery(muiTheme.breakpoints.down("md"));
  const tabletscreen = useMediaQuery(muiTheme.breakpoints.down("sm"));

  // const openMenu = anchorEl;
  const handleClickOpen = (event) => {
    setOpenMenu(event.currentTarget);
  };
  // React.useEffect(() => {
  //   const eng = axios
  //     .get("https://eventstrapi.thecbt.cyou/api/web-panels")
  //     .then((val) => {
  //       localStorage.setItem(
  //         "en",
  //         JSON.stringify(val.data.data[0].attributes)
  //       );
  //     });
  //     const ger = axios
  //     .get("https://eventstrapi.thecbt.cyou/api/web-panel-grs")
  //     .then((val) => {
  //       localStorage.setItem(
  //         "de",
  //         JSON.stringify(val.data.data[0].attributes)
  //       );
  //     });
  // }, []);
  // React.useEffect(() => {

  // }, [selectedLanguage]);
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  // const [pathName, setPathName] = useState(location.pathname);
  const [userProfile, setUserProfile] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [role, setCurrentRole] = useState(localStorage.getItem("eventRole"));
  useEffect(() => {
    dispatch(getUserProfile(stopLoader));
  }, []);
  var stopLoader = (data) => {
    setUserProfile(data.user);
  };
  const handleClose = () => {
    setOpenMenu(null);
  };
  const logOutFunction = () => {
    navigate("/");
    handleClose();
    localStorage.removeItem("eventAuth");
    localStorage.removeItem("eventRole");
  };
  const profileFunction = () => {
    navigate("/profile-settings");
    handleClose();
  };
  const selectLanguage = () => {
    setOpenModal(true);
    handleClose();
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    if (mobilescreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [mobilescreen]);
  var menuLinks = [
    {
      name: languageData?.sidebar?.home,
      navigate: "/overview",
      icon: Overview,
    },
    // {
    //   name: "Tasks",
    //   navigate: "/tasks",
    // },
    {
      name: languageData?.sidebar?.events,
      navigate: "/events",
      icon: Events,
    },
    {
      name: languageData?.sidebar?.hosts,
      navigate: "/host-list",
      icon: Host,
    },
    {
      name: languageData?.sidebar?.guests,
      navigate: "/all-guest",
      icon: Guest,
    },

    {
      name: languageData?.sidebar?.categories,
      navigate: "/all-category",
      icon: CategoryIcon,
    },
    {
      name: languageData?.sidebar?.licenses,
      navigate: "/license",
      icon: License,
    },
    {
      name: languageData?.sidebar?.packages,
      navigate: "/packages",
      icon: Packages,
    },
  ];
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        className="bg-white app-bar"
        sx={{ margin: 0, p: 0, zIndex: mobilescreen ? 2 : 1100 }}
        mobilescreen={mobilescreen}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ mr: 5 }}
        >
          <Toolbar className="toolbar">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              // sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <img
                src={open ? SidebarIcon : pinkBars}
                className={
                  tabletscreen ? "mobile-drawer-button" : "drawer-button"
                }
                style={{ left: open ? "-36px" : "0px" }}
              />
            </IconButton>
            {location.pathname === "/overview" ||
            location.pathname === "/events" ||
            // location.pathname === "/tasks" ||
            location.pathname === "/gallery" ||
            location.pathname === "/host-list" ||
            location.pathname === "/all-guest" ||
            location.pathname === "/packages" ||
            location.pathname === "/license" ||
            location.pathname === "/all-category" ? (
              ""
            ) : (
              <Box
                sx={{ ml: 2 }}
                style={mobilescreen ? { display: "none" } : {}}
              >
                <Button onClick={() => navigate(-1)}>
                  <ArrowBackIosNewOutlinedIcon sx={{ color: "#ff9885" }} />
                </Button>
              </Box>
            )}
            <Typography
              variant="h4"
              noWrap
              component="div"
              className={tabletscreen ? "mobile-appbar-title" : "appbar-title"}
              sx={open ? { ml: 3 } : { ml: 5 }}
            >
              {location.pathname === "/overview" && languageData?.sidebar?.home}
              {location.pathname === "/events" && languageData?.sidebar?.events}
              {location.pathname === "/profile-settings" &&
                languageData?.sidebar?.profile}
              {location.pathname === "/create-event" &&
                languageData?.eventForm?.create_form}
              {location.pathname.includes("/event-details") &&
                languageData?.singleEvent?.event_details}
              {location.pathname.includes("/tasks") &&
                languageData?.categories?.task}
              {location.pathname === "/task-list" && "Task List"}
              {location.pathname === "/gallery" && "Gallery"}
              {location.pathname === "/host-list" &&
                languageData?.sidebar?.hosts}
              {location.pathname === "/all-guest" &&
                languageData?.sidebar?.guests}
              {location.pathname === "/packages" &&
                languageData?.sidebar?.packages}
              {location.pathname === "/license" &&
                languageData?.sidebar?.licenses}
              {location.pathname == "/all-category" &&
                languageData?.sidebar?.categories}
              {location.pathname.includes("/gallery/") &&
                languageData?.eventGallery?.event_gallery}
              {location.pathname == "/greetings" && "Greetings"}
              {location.pathname.includes("/greetings-details") &&
                "Greeting Detail"}
              {location.pathname.includes("/edit-event") &&
                languageData?.eventTableScreen?.edit_event}
            </Typography>
          </Toolbar>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              alt="user_img"
              src={userProfile?.profile?.image_location}
              sx={{ width: 36, height: 36 }}
            />
            <div style={tabletscreen ? { display: "none" } : {}}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal 600 14px/21px Poppins",
                  letterSpacing: "0px",
                  color: " #4E3D39",
                  opacity: "1",
                }}
              >
                {userProfile?.username}
              </Typography>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  font: "normal normal 300 10px/16px Poppins",
                  letterSpacing: "0px",
                  color: " #828081",
                  opacity: "1",
                }}
              >
                {userProfile?.email}
              </Typography>
            </div>

            <IconButton
              onClick={handleClickOpen}
              size="small"
              sx={{ ml: -6 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <ArrowDropDownIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 8,
                  mr: 10,
                  width: 150,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    // transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <MenuItem onClick={profileFunction}>
                <AccountBoxIcon style={{ paddingRight: 7 }} />{" "}
                {languageData?.sidebar?.profile}
              </MenuItem>
              <Divider color="gray" />
              <MenuItem onClick={selectLanguage}>
                <LanguageIcon style={{ paddingRight: 7 }} />
                {languageData?.sidebar?.language}
              </MenuItem>
              <Divider color="gray" />
              <MenuItem onClick={logOutFunction}>
                <PowerSettingsNewOutlinedIcon style={{ paddingRight: 7 }} />
                {languageData?.sidebar?.logout}
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          zIndex: 3,
          border: "transparent",
          position: "relative",
        }}
        variant={mobilescreen ? "temporary" : "persistent"}
        anchor="left"
        className="sidebar"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerHeader className="sidebar">
          <div className="d-flex ">
            <Box sx={{ ml: -4, mb: -10, mt: -1.5 }}>
              {/* <div className="sidebar-title" style={{ width: "1%" }}> */}
              <img src={`${Logo}`} alt="JSTPRTY" style={{ width: "200px" }} />
              {/* </div> */}
            </Box>
          </div>
        </DrawerHeader>

        <Box sx={{ pt: 5 }}>
          <List>
            {menuLinks.map((link, index) =>
              role == "host" ? (
                index != 2 &&
                index != 4 &&
                index != 5 &&
                index != 6 &&
                index != 7 && (
                  <ListItem
                    selected={location.pathname === link.navigate}
                    key={link.name}
                    sx={{
                      mt: 0.2,
                      "& .Mui-selected": {
                        background: "#F3816C !important",
                      },
                    }}
                    disablePadding
                    className="sidebar-list"
                    onClick={() =>
                      link.navigate == "/"
                        ? (window.location.href = link.navigate)
                        : navigate(link.navigate)
                    }
                  >
                    <ListItemButton className="list-button">
                      <ListItemIcon sx={{ color: "white" }}>
                        <img src={link.icon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={link.name}
                        disableTypography={false}
                        component="div"
                        className="sidebar-link"
                        primaryTypographyProps={true}
                      />
                    </ListItemButton>
                    {/* <div className="triangle-right"></div> */}
                  </ListItem>
                )
              ) : (
                <ListItem
                  selected={location.pathname === link.navigate}
                  key={link.name}
                  sx={{
                    mt: 0.2,
                    "& .Mui-selected": {
                      background: "#F3816C !important",
                    },
                  }}
                  disablePadding
                  className="sidebar-list"
                  onClick={() =>
                    link.navigate == "/"
                      ? (window.location.href = link.navigate)
                      : navigate(link.navigate)
                  }
                >
                  <ListItemButton className="list-button">
                    <ListItemIcon sx={{ color: "white" }}>
                      <img src={link.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={link.name}
                      disableTypography={false}
                      component="div"
                      className="sidebar-link"
                      primaryTypographyProps={true}
                    />
                  </ListItemButton>
                  {/* <div className="triangle-right"></div> */}
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Drawer>
      <Main
        open={open}
        className="main"
        sx={{ overflowX: "hidden" }}
        mobilescreen={mobilescreen}
      >
        <DrawerHeader />
        {children}
        {/* <Route
          onUpdate={() => window.scrollTo(0, 0)}
          exact={true}
          path="/home/overview"
          element={<Overview />}
        /> */}
      </Main>
      <SelectLanguage
        open={openModal}
        handleClose={handleCloseModal}
        language={selectedLanguage}
        setLanguage={setSelectedLanguage}
        setLoading={setLoading}
      />
      {loading ? (
        <LoadingPage loading={loading} setLoading={setLoading} />
      ) : (
        <></>
      )}
    </Box>
  );
}
