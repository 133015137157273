import * as React from "react";
import Dashboard from "./Dashboard";
import { Container } from "@mui/system";
import Card from "@mui/material/Card";
// import { CreateEventSteps } from "../components/Create Event Card/CreateEventSteps";
import { useRef, useState } from "react";
import { Button, ListItemSecondaryAction, Stack } from "@mui/material";
import { createNewEvent } from "../redux/Tasks/TasksActions";
import { useDispatch } from "react-redux";
import { FireSwal } from "../helpers/swalNotification";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../config/endpoint";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { EditEventFields } from "../components/Edit Event Form/EditEventFields";
import { EDIT_EVENT_CONTEXT } from "../Contexts/EditEventContext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { INSTANCE } from "../config/axiosInstance";
import moment from "moment";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "transparent",
  p: 4,
};
export function EditEvent() {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [Loading, setLoading] = useState(false);
  const [tags, SetTags] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const tagRef = useRef();
  const { id } = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language")
  );
  const [languageData, seLanguageData] = useState(
    JSON.parse(localStorage.getItem(`${selectedLanguage}`)).eventForm
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getEventDetails();
  }, []);
  const getEventDetails = async () => {
    var { data } = await INSTANCE({
      method: "POST",
      url: "/events/event-by-id",
      data: { event_id: id },
    });
    var { event } = data;

    var obj = {
      //  license_number: event.license_number,
      event_name: event.general_info.event_name,
      event_location: event.general_info.event_location,
      description: event.general_info.description,
      date_from: moment(event.general_info.date_from).format("MM/DD/YYYY"),
      date_to: moment
        .duration(
          moment(event.general_info.date_to, "YYYY-MM-DD").diff(
            event.general_info.date_from
          )
        )
        .asDays(),
      is_public: event.is_public,
      //  cover_image: event.cover_image,
      //  welcome_image: event.welcome_image,
      //  profile_image: event.profile_image,
      tasks: event.tasks,
      event_id: event._id,
    };
    setInputValues(obj);
  };
  const handleMakeGalleryChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    _input["is_public"] = input.checked;
    setInputValues(_input);
  };
  const handleFieldChange = ({ currentTarget: input }) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input[input.name] = input.value;
    _errors[input.name] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  const handleImageChange = (e) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    if (e.target.files[0]) {
      _input[e.target.name] = e.target.files[0];
      _errors[e.target.name] = null;
      setInputValues(_input);
      setErrors(_errors);
    }
  };
  const handleDelete = (value) => {
    const newtags = tags.filter((val) => val !== value);
    SetTags(newtags);
  };
  const handleOnSubmit = (value) => {
    // e.preventDefault(e);

    SetTags(value);
    // tagRef.current.value = "";
  };
  const handleTaskChange = (val) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input["tasks"] = _input["tasks"] ? [..._input["tasks"], ...val] : [...val];
    _errors["tasks"] = null;
    setInputValues(_input);
    setErrors(_errors);
  };
  const handleTaskChange2 = (val) => {
    var _input = { ...inputValues };
    var _errors = { ...errors };
    _input["tasks"] = _input["tasks"] ? [..._input["tasks"], val] : [val];
    setInputValues(_input);
    setErrors(_errors);
  };

  const handleTaskDelete = (val) => {
    var _input = { ...inputValues };

    _input["tasks"] = _input["tasks"].filter((task) => task._id != val);

    setInputValues(_input);
  };

  var putNewError = (val, msg) => {
    var _errors = { ...errors };
    _errors[val] = msg;
    setErrors(_errors);
  };

  var stopCreateEvent = (data) => {
    if (data.status) {
      FireSwal("success", "Event updated successfully", "", false, false);
      setTimeout(() => {
        Swal.close();
        navigate("/events", { replace: true });
      }, 2000);
      setLoading(false);
    } else {
      setLoading(false);
      FireSwal("error", "Oops..", data.message, false, true);
    }
    handleClose();
  };
  var CreateNewEvent = async (e) => {
    handleOpen();
    e.preventDefault();
    //     if (!inputValues.license_number || inputValues.license_number.length < 1) {
    //       putNewError("license_number", "Please provide a license number!");
    //       handleClose();
    //       return;
    //     }

    if (!inputValues.event_name || inputValues.event_name.length < 1) {
      putNewError("event_name", "Please provide a event name!");
      handleClose();
      return;
    }
    if (!inputValues.event_location || inputValues.event_location.length < 1) {
      putNewError("event_location", "Please provide a event location!");
      handleClose();
      return;
    }
    if (!inputValues.description || inputValues.description.length < 1) {
      putNewError("description", "Please provide a description for event!");
      handleClose();
      return;
    }
    if (!inputValues.date_from || inputValues.date_from.length < 1) {
      putNewError("date_from", "Please select start date for the event!");
      handleClose();
      return;
    }
    if (!inputValues.date_to || inputValues.date_to.length < 1) {
      putNewError("date_to", "Please select ending date for the event!");
      handleClose();
      return;
    }

    if (
      (!inputValues.tasks || inputValues.tasks.length < 1) &&
      tags.length < 1
    ) {
      putNewError(
        "tasks",
        "Please select atleast one task to continue event creation!"
      );
      handleClose();
      return;
    }
    var obj = {
      general_info: {
        event_name: inputValues.event_name,
        event_location: inputValues.event_location,
        description: inputValues.description,
        date_from: inputValues.date_from,
        date_to: parseInt(inputValues.date_to),
      },
      tasks: inputValues.tasks.map((item) => item._id),
      customTask: tags.map((item) => item.value),
      public: inputValues.is_public,
      event_id: inputValues.event_id,
    };
    //     var formData = new FormData();
    //     formData.append("license_number", inputValues.license_number);
    //     formData.append("general_info[event_name]", inputValues.event_name);
    //     formData.append("general_info[event_location]", inputValues.event_location);
    //     formData.append("general_info[description]", inputValues.description);
    //     formData.append("general_info[date_from]", inputValues.date_from);
    //     formData.append("general_info[date_to]", inputValues.date_to);
    //     formData.append("general_info[time_from]", inputValues.time_from);
    //     formData.append("general_info[time_to]", inputValues.time_to);
    //     formData.append("profile_image", inputValues.profile_image);
    //     formData.append("cover_image", inputValues.cover_image);
    //     formData.append("welcome_image", inputValues.welcome_image);
    //     formData.append("public", inputValues.public);

    //     for (var i in inputValues.tasks) {
    //       formData.append(`tasks[${i}]`, inputValues.tasks[i]._id);
    //     }
    //     for (var i in tags) {
    //       formData.append(`customTask[${i}]`, tags[i].value);
    //     }
    setLoading(true);

    var { data } = await INSTANCE({
      method: "POST",
      url: "/events/edit-event",
      data: obj,
    });
    stopCreateEvent(data);
  };
  return (
    <Dashboard>
      <Container>
        <EDIT_EVENT_CONTEXT.Provider
          value={{
            errors,
            handleFieldChange,
            handleImageChange,
            inputValues,
            setInputValues,
            putNewError,
            handleTaskChange,
            handleTaskChange2,
            handleOnSubmit,
            handleTaskDelete,
            handleMakeGalleryChange,
            tags,
            tagRef,
            handleDelete,
          }}
        >
          <Card sx={{ mt: 2 }}>
            <EditEventFields step={step} />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ mb: 2 }}
            >
              {/* <Button
                className="default-secondary"
                disabled={step === 1}
                sx={{ px: 5 }}
                onClick={() => setStep((step) => step - 1)}
              >
                Back
              </Button> */}
              {/* <div className="divider" style={{ height: "30px" }}></div> */}
              {/* {step === 4 ? ( */}
              <Button
                onClick={(e) => CreateNewEvent(e)}
                className="default-button"
                type="button"
                sx={{ px: 5, color: "white" }}
                disabled={Loading}
              >
                {Loading ? (
                  <CircularProgress sx={{ color: "white", color: "#FF9885" }} />
                ) : (
                  languageData?.update_btn
                )}
              </Button>
              {/* ) : ( */}
              {/* <Button
                  onClick={() => moveToNextAndValidate()}
                  disabled={step === 4}
                  className="default-button"
                  sx={{ px: 5, color: "white" }}
                >
                  Next
                </Button>
              )} */}
            </Stack>
          </Card>
        </EDIT_EVENT_CONTEXT.Provider>
      </Container>
      <Modal
        open={open}
        hideBackdrop={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
          <Stack direction={"row"} spacing={2} alignItems="center">
            <div>
              <CircularProgress sx={{ color: "#FF9885" }} />
            </div>
            <Typography
              id="modal-modal-description"
              sx={{
                textAlign: "center",
                color: "rgb(255, 152, 133)",
                fontFamily: "PoppinsSemiBold",
              }}
            >
              Updating Event...
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </Dashboard>
  );
}
